import { useState, useEffect, useCallback } from "react";
import { getData } from "../assets/functions/apiCalls";

export const useNetworkRequest = ({ URL, organizationSelected }) => {
  const [allValues, setAllValues] = useState([]);
  const [allValuesLoading, setAllValuesLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      setAllValuesLoading(true);
      const response = await getData(URL);
      setAllValues(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setAllValuesLoading(false);
    }
  }, [URL]);

  useEffect(() => {
    fetchData();
  }, [fetchData, organizationSelected?.TeamUsername]);

  return { allValues, allValuesLoading, fetchData };
};
