import React, { useEffect, useState } from 'react'
// import './BookingDrawer.scss'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function BookingDrawer({ bookingDrawer, setbookingDrawer, changeStatusfucntion }) {
    const [list, setlist] = useState("Booking")
    const [Actions, setActions] = useState("delete")

    useEffect(() => {

        setActions("delete")
    }, [bookingDrawer])




    const actionfunction = () => {
        switch (Actions) {
            case "delete":
                return (
                    <div className='listofitem'>
                        <div className='dropdownParentSectioncutom'
                        >
                            <div className='titleseleted'
                                style={{
                                    marginBottom: "1rem"
                                }}
                            >Are you sure you want to delete this organization?</div >
                            <div

                                style={{
                                    marginTop: "0rem",
                                    textTransform: "capitalize"
                                }}
                                className='labelbuttonbokin'
                                onClick={changeStatusfucntion}
                            >
                                Yes confirmed

                            </div>

                            <div

                                className='labelbuttonbokin'


                                onClick={() => setbookingDrawer(false)}
                            >
                                Never mind

                            </div>
                        </div>
                    </div>
                )

                return (
                    <div className='listofitem'>


                        <div className='dropdownParentSectioncutom'
                        >
                            <div

                                className='labelbuttonbokin'
                            >
                                Edit booking

                            </div>

                            <div

                                className='labelbuttonbokin'

                                onClick={() => setActions("changebookingstatus")}
                            >
                                Change booking status

                            </div>
                            <div

                                className='labelbuttonbokin'

                                onClick={() => setActions("Create consultation session")}
                            >
                                Create consultation session

                            </div>
                            <div

                                className='labelbuttonbokin'
                            >
                                Delete booking
                            </div>
                        </div>
                    </div>
                )
                break;

            default:
                break;
        }
    }

    return (


        <>
            <div className="bookingDrawer">

                {actionfunction()}
            </div>
        </>
    )
}
