import React, { useState } from "react";
import classNames from "./waitinglist.module.scss";

//assets
import fullLogo from "../../assets/images/logo.svg";
import { Link, useNavigate } from "react-router-dom";

const WaitingList = () => {
  const navigate = useNavigate();
  //page values
  const [emailCredentials, setEmailCredentials] = useState("");
  const [passwordCredentials, setPasswordCredentials] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  return (
    <div className={classNames.loginPage}>
      <div className={classNames.topNav}>
        <Link to="/">Go Back Home</Link>
      </div>
      <div className={classNames.loginCard}>
        <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
          <img src={fullLogo} alt="fullLogo" onClick={() => navigate("/")} />
          <div className={classNames.title}>
            Join Our Waiting List And Get 50% Off You’re First Purchase
          </div>
          <div className={classNames.credentialsContainer}>
            <div className={classNames.sideByContainer}>
              <input
                className={classNames.inputContainer}
                placeholder="Name"
                type="text"
              />
              <input
                className={classNames.inputContainer}
                placeholder="Country"
                type="text"
              />
            </div>
            <input
              className={classNames.inputContainer}
              placeholder="Email"
              type="email"
              value={emailCredentials}
              onChange={(event) => {
                setEmailCredentials(event?.target.value);
              }}
            />
            <input
              className={classNames.inputContainer}
              placeholder="Phone Number"
              type="number"
              value={passwordCredentials}
              onChange={(event) => {
                setPasswordCredentials(event?.target.value);
              }}
            />
            <div className={classNames.buttonsContainer}>
              <button
                className={classNames.blueBtn}
                style={{
                  opacity: isLoggingIn ? "0.5" : "",
                  pointerEvents: isLoggingIn ? "none" : "",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitingList;
