import React, { useEffect, useState } from "react";
import classNames from "./pipelinesubmenu.module.scss";
import "../../../assets/Scsss/Global.scss";
import "../../Booking/Dashboard/BookingDrawer.scss";

//assets

import { ReactComponent as SettingsIcon } from "../../../assets/images/icons/settings.svg";
import KanbanBoard from "./Flow";
import {
  EachCustomDropdown,
  EachCustomTextarea,
  EachInput,
} from "../../../components/Custom";
import axios from "axios";
import Drawer from "react-modern-drawer";
import { IoIosAttach } from "react-icons/io";
import AWS from "aws-sdk";
import { toastify } from "../../../assets/functions/toastify";
import { ReactComponent as EditBtnIcon } from "../../../assets/images/icons/editbtn.svg";
import moment from "moment";
import { TiAttachment } from "react-icons/ti";

const PipelineSubmenu = ({
  stateValue,
  setState,
  localSidebar,
  setLocalSidebar,
  setLocalRefresh,
}) => {
  //constants
  // const allSubMenu = ["Profile", "Flow", "Stages", "Change History"];
  const allSubMenu = ["Flow"];

  const [selectedMenu, setSelectedMenu] = useState("Flow");
  const [selectedPipelineDetails, setSelectedPipelineDetails] = useState({});
  const [selectedDrawerItem, setSelectedDrawerItem] = useState({});

  //aws

  AWS.config.update({
    accessKeyId: process.env.AWS_accessKeyId,
    secretAccessKey: process.env.AWS_secretAccessKey,
    region: "Us-east-1",
  });

  //imported sidebar

  const allautomationItems = [
    "Send invoice",
    "Send proposal",
    "Send delivery doc",
    "Send form",
    "Create task",
    "Schedule meeting",
  ];

  const allautomationItemsNewForm = [
    { title: "Send invoice", value: "sendinvoice" },
    { title: "Send proposal", value: "sendproposal" },
    { title: "Send delivery doc", value: "senddeliverydocument" },
    { title: "Send form", value: "sendform" },
    { title: "Send agreement", value: "sendagreement" },
    { title: "Schedule meeting", value: "schedulemeeting" },
  ];

  const [formErr, setFormErr] = useState(null);
  const [allSteps, setAllSteps] = useState([]);
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [addNewManualTasks, setAddNewManualTasks] = useState({});
  const [addNewAutomaticTasks, setAddNewAutomaticTasks] = useState({});
  const [addNewStep, setAddNewStep] = useState({});
  const [kichenItem, setkichenItem] = useState({
    name: "",
    description: "",
  });
  const [allStages, setAllStages] = useState([]);
  const [newTaskForm, setNewTaskForm] = useState({});
  const [uploadedFile, setUploadedFile] = useState("");
  const [selectedRequiredAutomation, setSelectedRequiredAutomation] =
    useState(false);
  const [assignedTo, setAllAssignedTo] = useState([]);

  //functions

  const handleFileInput = (e) => {
    setUploadedFile(e.target.files[0]);
    uploadFile(e.target.files[0]);
  };

  const uploadFile = (file) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: "montagestr",
      Key: file.name,
      Body: file,
    };

    s3.upload(params, (err, data) => {
      console.log(data, "AWS file upload", file, process.env.AWS_accessKeyId);
      if (err) {
        console.error("Error", err);
      } else {
        console.log(data, "AWS file upload", file);
        setNewTaskForm((prev) => {
          return {
            ...prev,
            filelink: data?.Location,
          };
        });
      }
    });
  };

  const handleSubmitNewTaskForm = () => {
    axios
      .post(`https://finacebackend.marketsverse.com/api/createtask`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        ...newTaskForm,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          // setTimeout(() => {
          //     window.location.reload();
          // }, 1000);
          setLocalSidebar((prev) => !prev);
          setNewTaskForm({});
          setUploadedFile("");
          setFormErr(null);
          toastify(
            data?.message ? data?.message : "Created new task successfully!",
            "success"
          );
        } else {
          setFormErr(data.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Server error while adding new stage");
      });
  };

  function getAllStages() {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getstage?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        setAllStages(res?.data?.data);
      })
      .catch((error) => {
        console.log(error?.message, "Get all pipelines server error");
      });
  }

  function getAllSteps() {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getstagestep?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        setAllSteps(res?.data?.data);
      })
      .catch((error) => {
        console.log(error?.message, "Get all pipelines server error");
      });
  }

  const handleSubmitAutomatedTask = () => {
    axios
      .post(`https://finacebackend.marketsverse.com/api/createautomatetask`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        ...addNewAutomaticTasks,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          setLocalRefresh((prev) => !prev);
          setkichenItem({});
          setFormErr(null);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setFormErr(data.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Server error while adding new stage");
      });
  };

  const handleSubmitManualTasks = () => {
    axios
      .post(`https://finacebackend.marketsverse.com/api/createmanualtask`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        ...addNewManualTasks,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          setLocalRefresh((prev) => !prev);
          setkichenItem({});
          setFormErr(null);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setFormErr(data.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Server error while adding new stage");
      });
  };

  const handleStepSubmit = () => {
    axios
      .post(`https://finacebackend.marketsverse.com/api/addstagestep`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        ...addNewStep,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          setLocalRefresh((prev) => !prev);
          setFormErr(null);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setFormErr(data.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Server error while adding new step");
      });
  };

  async function getAllAssignedToForUser() {
    try {
      let response = await axios.get(
        `https://finacebackend.marketsverse.com/api/getallplayersforteam/${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      );

      if (response?.data?.data?.length > 0) {
        setAllAssignedTo(response?.data?.data);
      }

      console.log(response, "Get clients for all staff members response");
    } catch (error) {
      console.log(error, "Get clients for all staff members error");
    }
  }

  //renderings

  useEffect(() => {
    getAllStages();
    getAllSteps();
    getAllAssignedToForUser();
  }, []);

  return (
    <div className={classNames.pipelineSubmenu}>
      <div className={classNames.mainContainer}>
        <div className={classNames.headerContainer}>
          <div className="profileTop">
            <div
              className={classNames.profileName}
              style={{
                paddingBottom: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                onClick={() => setState(false)}
              >
                <circle
                  cx="16.5"
                  cy="16.5"
                  r="16"
                  fill="white"
                  stroke="#E9EBF3"
                />
                <path
                  d="M10.4497 16.8516L16.171 22.535C16.2768 22.64 16.4253 22.7 16.5808 22.7H19.0393C19.5282 22.7 19.7821 22.1619 19.4491 21.8314L14.0779 16.5L19.4491 11.1686C19.7821 10.8381 19.5282 10.3 19.0393 10.3H16.5808C16.4253 10.3 16.2768 10.3596 16.171 10.465L10.4497 16.1484C10.2502 16.3467 10.2502 16.6533 10.4497 16.8516Z"
                  fill="url(#paint0_linear_1918_382)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1918_382"
                    x1="19.6001"
                    y1="16.5"
                    x2="10.3001"
                    y2="16.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#191F41" />
                  </linearGradient>
                </defs>
              </svg>

              <div style={{ marginLeft: "15px" }}>
                <span style={{ fontSize: "1.2rem", fontWeight: 550 }}>
                  {stateValue?.data?.name}
                </span>
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                  }}
                >
                  {stateValue?.data?.pipelineid}
                </div>
              </div>
            </div>

            <div className="deletedEditingButton">
              <SettingsIcon />
              Actions
            </div>
          </div>
        </div>
        <div className={classNames.contentContainer}>
          <div className="profiletab">
            {allSubMenu?.map((eachItem, index) => {
              return (
                <div
                  className="items"
                  style={{
                    background: selectedMenu === eachItem ? "#3f506a" : "",
                  }}
                  onClick={() => {
                    setSelectedMenu(eachItem);
                  }}
                >
                  {eachItem}
                </div>
              );
            })}
          </div>
          {selectedMenu === "Flow" && (
            <KanbanBoard
              setState={setState}
              stateValue={stateValue}
              setLocalSidebar={setLocalSidebar}
              selectedPipelineDetails={selectedPipelineDetails}
              setSelectedPipelineDetails={setSelectedPipelineDetails}
              setSelectedDrawerItem={setSelectedDrawerItem}
            />
          )}
          <Drawer
            size={
              localSidebar === "eachJobDetails" ||
              localSidebar === "TaskProfileDrawer"
                ? 600
                : 450
            }
            // duration={500}
            open={localSidebar}
            onClose={() => setLocalSidebar("")}
            direction="right"
            className="bla bla bla"
            overlayColor="#FFF"
            overlayOpacity={0}
          >
            {localSidebar === "addnewstep" ? (
              <div className="drowpside-right">
                <div
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "2.5rem",
                  }}
                  className={classNames.closeBtn}
                  onClick={() => {
                    setLocalSidebar("");
                  }}
                >
                  X
                </div>
                <div
                  className={classNames.eachFilterItem}
                  style={{
                    fontWeight: "500",
                    pointerEvents: "none",
                    paddingLeft: "0",
                    fontSize: "1.2rem",
                  }}
                >
                  Add new step
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachInput
                    title="Basic Information"
                    name="name"
                    placeholder="Name..."
                    stateValue={addNewStep}
                    setState={setAddNewStep}
                    topTitle="true"
                  />
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachCustomTextarea
                    name="description"
                    placeholder="Description..."
                    stateValue={addNewStep}
                    setState={setAddNewStep}
                  />
                </div>
                <div className={classNames.selectOne}>
                  <div
                    className={
                      addNewStep?.automove ? classNames.selectedOne : ""
                    }
                    onClick={() => {
                      setAddNewStep((prev) => {
                        return { ...prev, automove: true, manual: false };
                      });
                    }}
                  >
                    Automove
                  </div>
                  <div
                    className={addNewStep?.manual ? classNames.selectedOne : ""}
                    onClick={() => {
                      setAddNewStep((prev) => {
                        return { ...prev, automove: false, manual: true };
                      });
                    }}
                  >
                    Manual Move
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachCustomDropdown
                    dropdown={allStages}
                    name="stageid"
                    title="Select stage"
                    stateValue={addNewStep}
                    setState={setAddNewStep}
                    topTitle="true"
                    type="obj"
                    mapVal={{
                      name: "name",
                      desc: "description",
                    }}
                    stateVal={"stageid"}
                  />
                  <EachInput
                    name="order"
                    placeholder="Order in stage..."
                    stateValue={addNewStep}
                    setState={setAddNewStep}
                  />
                </div>
                <button
                  className={classNames.submitBtn}
                  onClick={handleStepSubmit}
                >
                  Submit
                </button>
                {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
              </div>
            ) : localSidebar === "addnewautomatedtask" ? (
              <div className="drowpside-right">
                <div
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "2.5rem",
                  }}
                  className={classNames.closeBtn}
                  onClick={() => {
                    setLocalSidebar("");
                    setCheckBoxStage("");
                  }}
                >
                  X
                </div>
                <div
                  className={classNames.eachFilterItem}
                  style={{
                    fontWeight: "500",
                    pointerEvents: "none",
                    paddingLeft: "0",
                    fontSize: "1.2rem",
                    marginBottom: "2rem",
                  }}
                >
                  Add new automated task
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <div className={classNames.subTitle}>Basic Information</div>
                  <div className={classNames.selectOneGrid}>
                    {allautomationItems?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem + index}
                          className={
                            addNewAutomaticTasks?.automationitem === eachItem
                              ? classNames.selectedOne
                              : ""
                          }
                          onClick={() => {
                            setAddNewAutomaticTasks((prev) => {
                              return {
                                ...prev,
                                automationitem: eachItem,
                              };
                            });
                          }}
                        >
                          {eachItem}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachCustomDropdown
                    dropdown={allSteps}
                    name="stagestepid"
                    title="Step information"
                    stateValue={addNewAutomaticTasks}
                    setState={setAddNewAutomaticTasks}
                    topTitle="true"
                    type="obj"
                    mapVal={{
                      name: "name",
                      desc: "stagestepid",
                    }}
                    stateVal={"stagestepid"}
                  />
                  <EachInput
                    name="order"
                    placeholder="Order in step..."
                    stateValue={addNewAutomaticTasks}
                    setState={setAddNewAutomaticTasks}
                  />
                </div>
                <button
                  className={classNames.submitBtn}
                  onClick={handleSubmitAutomatedTask}
                >
                  Submit
                </button>
                {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
              </div>
            ) : localSidebar === "addnewmanualtasks" ? (
              <div className="drowpside-right">
                <div
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "2.5rem",
                  }}
                  className={classNames.closeBtn}
                  onClick={() => {
                    setLocalSidebar("");
                    setCheckBoxStage("");
                  }}
                >
                  X
                </div>
                <div
                  className={classNames.eachFilterItem}
                  style={{
                    fontWeight: "500",
                    pointerEvents: "none",
                    paddingLeft: "0",
                    fontSize: "1.2rem",
                    marginBottom: "2rem",
                  }}
                >
                  Add new manual task
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachInput
                    title="Basic Information"
                    name="name"
                    placeholder="Name..."
                    stateValue={addNewManualTasks}
                    setState={setAddNewManualTasks}
                    topTitle="true"
                  />
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachCustomTextarea
                    name="description"
                    placeholder="Description..."
                    stateValue={addNewManualTasks}
                    setState={setAddNewManualTasks}
                  />
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <EachCustomDropdown
                    dropdown={allSteps}
                    name="stagestepid"
                    title="Step information"
                    stateValue={addNewManualTasks}
                    setState={setAddNewManualTasks}
                    topTitle="true"
                    type="obj"
                    mapVal={{
                      name: "name",
                      desc: "stagestepid",
                    }}
                    stateVal={"stagestepid"}
                  />
                  <EachInput
                    name="assignedto"
                    placeholder="Assigned to..."
                    stateValue={addNewManualTasks}
                    setState={setAddNewManualTasks}
                  />
                </div>
                <button
                  className={classNames.submitBtn}
                  onClick={handleSubmitManualTasks}
                >
                  Submit
                </button>
                {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
              </div>
            ) : localSidebar === "addnewtaskform" ? (
              <div className="drowpside-right">
                <div
                  style={{
                    position: "absolute",
                    right: "1rem",
                  }}
                  className={classNames.closeBtn}
                  onClick={() => {
                    setLocalSidebar("");
                  }}
                >
                  X
                </div>
                <div
                  className={classNames.eachFilterItem}
                  style={{
                    fontWeight: "500",
                    pointerEvents: "none",
                    paddingLeft: "0",
                    fontSize: "1.2rem",
                    marginBottom: "2rem",
                  }}
                >
                  Add new task
                </div>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <div className={classNames.subTitle}>Basic Information</div>
                  <EachInput
                    name="name"
                    placeholder="Name..."
                    stateValue={newTaskForm}
                    setState={setNewTaskForm}
                  />
                  <EachCustomTextarea
                    name="description"
                    placeholder="Description..."
                    stateValue={newTaskForm}
                    setState={setNewTaskForm}
                  />
                  <EachCustomDropdown
                    dropdown={assignedTo}
                    title="Assigned to"
                    name="assignedto"
                    stateValue={newTaskForm}
                    setState={setNewTaskForm}
                    type="objUserDetails"
                    mapVal={{
                      name: "name",
                    }}
                    stateVal={"userid"}
                  />
                  <div style={{ marginTop: "1rem" }}>
                    <EachCustomDropdown
                      dropdown={assignedTo}
                      title="Assigned by"
                      name="assignedby"
                      stateValue={newTaskForm}
                      setState={setNewTaskForm}
                      type="objUserDetails"
                      mapVal={{
                        name: "name",
                      }}
                      stateVal={"userid"}
                    />
                  </div>
                </div>

                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <div className={classNames.subTitle}>Step information</div>
                  <EachCustomDropdown
                    dropdown={allSteps}
                    name="stagestepid"
                    title="Select steps"
                    stateValue={newTaskForm}
                    setState={setNewTaskForm}
                    // topTitle="true"
                    type="obj"
                    mapVal={{
                      name: "name",
                      desc: "stagestepid",
                    }}
                    stateVal={"stagestepid"}
                  />
                  <EachInput
                    name="order"
                    placeholder="Order in step..."
                    stateValue={newTaskForm}
                    setState={setNewTaskForm}
                  />
                </div>

                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <div className={classNames.subTitle}>Task type</div>
                  <div className={classNames.selectOne}>
                    <div
                      className={
                        newTaskForm?.tasktype === "automation"
                          ? classNames.selectedOne
                          : ""
                      }
                      onClick={() => {
                        setNewTaskForm((prev) => {
                          return { ...prev, tasktype: "automation" };
                        });
                      }}
                    >
                      Automation
                    </div>
                    <div
                      className={
                        newTaskForm?.tasktype === "manual"
                          ? classNames.selectedOne
                          : ""
                      }
                      onClick={() => {
                        setNewTaskForm((prev) => {
                          return { ...prev, tasktype: "manual" };
                        });
                      }}
                    >
                      Manual
                    </div>
                  </div>
                </div>

                {newTaskForm?.tasktype === "automation" && (
                  <div
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    <div className={classNames.subTitle}>Automation type</div>
                    <div className={classNames.selectOne}>
                      {allautomationItemsNewForm?.map((eachItem, index) => {
                        return (
                          <div
                            key={eachItem?.title + index}
                            className={
                              newTaskForm?.automationitem === eachItem?.value
                                ? classNames.selectedOne
                                : ""
                            }
                            onClick={() => {
                              if (
                                eachItem?.title === "Send invoice" ||
                                eachItem?.title === "Send proposal" ||
                                eachItem?.title === "Send delivery doc" ||
                                eachItem?.title === "Send agreement"
                              ) {
                                setSelectedRequiredAutomation(true);
                              } else {
                                setSelectedRequiredAutomation(false);
                              }
                              setNewTaskForm((prev) => {
                                return {
                                  ...prev,
                                  automationitem: eachItem?.value,
                                };
                              });
                            }}
                          >
                            {eachItem?.title}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {selectedRequiredAutomation && (
                  <div
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    <div className={classNames.subTitle}>Email structure</div>
                    <EachInput
                      name="subject"
                      placeholder="Subject..."
                      stateValue={newTaskForm}
                      setState={setNewTaskForm}
                    />
                    <EachCustomTextarea
                      name="body"
                      placeholder="Body..."
                      stateValue={newTaskForm}
                      setState={setNewTaskForm}
                    />
                  </div>
                )}

                <label
                  className={classNames.fileUpload}
                  htmlFor="createTaskFile"
                >
                  <IoIosAttach />
                  {uploadedFile ? uploadedFile?.name : "Attach File"}
                  <input
                    type="file"
                    id="createTaskFile"
                    onChange={handleFileInput}
                  />
                </label>

                <button
                  className={classNames.submitBtn}
                  onClick={handleSubmitNewTaskForm}
                >
                  Submit
                </button>
                {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
              </div>
            ) : localSidebar === "eachJobDetails" ? (
              <div className="drowpside-right">
                {/* <div
                  style={{
                    position: "absolute",
                    right: "1rem",
                  }}
                  className={classNames.closeBtn}
                  onClick={() => {
                    setLocalSidebar("");
                  }}
                >
                  X
                </div> */}
                <div className={classNames.eachJobContainer}>
                  <div className={classNames.flexDiv}>
                    <div className={classNames.eachDetailedBox}>
                      <div className={classNames.title}>Assigned By:</div>
                      <div className={classNames.contentBox}>
                        <div className={classNames.imageContainer}>
                          {selectedPipelineDetails?.AssignedByDetails?.length >
                            0 &&
                          selectedPipelineDetails?.AssignedByDetails[0]?.name
                            ? selectedPipelineDetails?.AssignedByDetails[0]?.name?.charAt(
                                0
                              )
                            : ""}
                        </div>
                        <div className={classNames.content}>
                          <div>
                            {selectedPipelineDetails?.AssignedByDetails
                              ?.length > 0 &&
                            selectedPipelineDetails?.AssignedByDetails[0]?.name
                              ? selectedPipelineDetails?.AssignedByDetails[0]
                                  ?.name
                              : "-"}
                          </div>
                          <div>
                            {selectedPipelineDetails?.AssignedByDetails
                              ?.length > 0 &&
                            selectedPipelineDetails?.AssignedByDetails[0]?.email
                              ? selectedPipelineDetails?.AssignedByDetails[0]
                                  ?.email
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classNames.eachDetailedBox}>
                      <div className={classNames.title}>Assigned To:</div>
                      <div className={classNames.contentBox}>
                        <div className={classNames.imageContainer}>
                          {selectedPipelineDetails?.AssignedByDetails?.length >
                            0 &&
                          selectedPipelineDetails?.AssignedToDetails[0]?.name
                            ? selectedPipelineDetails?.AssignedToDetails[0]?.name?.charAt(
                                0
                              )
                            : ""}
                        </div>
                        <div className={classNames.content}>
                          <div>
                            {selectedPipelineDetails?.AssignedByDetails
                              ?.length > 0 &&
                            selectedPipelineDetails?.AssignedToDetails[0]?.name
                              ? selectedPipelineDetails?.AssignedToDetails[0]
                                  ?.name
                              : "-"}
                          </div>
                          <div>
                            {selectedPipelineDetails?.AssignedByDetails
                              ?.length > 0 &&
                            selectedPipelineDetails?.AssignedToDetails[0]?.email
                              ? selectedPipelineDetails?.AssignedToDetails[0]
                                  ?.email
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames.flexDiv}>
                    <div className={classNames.eachDetailedBox}>
                      <div className={classNames.title}>Client:</div>
                      <div className={classNames.contentBox}>
                        <div className={classNames.imageContainer}>
                          {selectedPipelineDetails?.ClientDetails?.length > 0 &&
                          selectedPipelineDetails?.ClientDetails[0]?.name
                            ? selectedPipelineDetails?.ClientDetails[0]?.name?.charAt(
                                0
                              )
                            : ""}
                        </div>
                        <div className={classNames.content}>
                          <div>
                            {selectedPipelineDetails?.ClientDetails?.length >
                              0 &&
                            selectedPipelineDetails?.ClientDetails[0]?.name
                              ? selectedPipelineDetails?.ClientDetails[0]?.name
                              : "-"}
                          </div>
                          <div>
                            {selectedPipelineDetails?.ClientDetails?.length >
                              0 &&
                            selectedPipelineDetails?.ClientDetails[0]?.email
                              ? selectedPipelineDetails?.ClientDetails[0]?.email
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames.desContainer}>
                    <div className={classNames.title}>Description:</div>
                    <div className={classNames.contentBox}>
                      {selectedPipelineDetails?.JobDetails?.length > 0
                        ? selectedPipelineDetails?.JobDetails[0]?.description
                        : ""}
                    </div>
                  </div>
                  <div className={classNames.taskHistoryContainer}>
                    <div className={classNames.title}>Task history</div>
                    <div className={classNames.contentBox}>
                      {Array.isArray(selectedPipelineDetails?.tasks) &&
                      selectedPipelineDetails?.tasks?.length > 0
                        ? selectedPipelineDetails?.tasks?.map(
                            (eachTask, index) => {
                              return (
                                <div
                                  className={classNames.eachHistory}
                                  key={eachTask?.taskid}
                                >
                                  <div>
                                    <div className={classNames.title}>
                                      {eachTask?.taskName}
                                    </div>
                                    <div className={classNames.detailsBtn}>
                                      See details
                                    </div>
                                  </div>
                                  <div
                                    className={`${
                                      eachTask?.status === "completed"
                                        ? classNames.completed
                                        : eachTask?.status === "inprogress"
                                        ? classNames.inProgress
                                        : ""
                                    }`}
                                  >
                                    {eachTask?.status === "completed"
                                      ? "Completed"
                                      : eachTask?.status === "inprogress"
                                      ? "Current Step"
                                      : "-"}
                                  </div>
                                </div>
                              );
                            }
                          )
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            ) : localSidebar === "TaskProfileDrawer" ? (
              <TaskProfileDrawer selectedDrawerItem={selectedDrawerItem} />
            ) : (
              ""
            )}
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default PipelineSubmenu;

const TaskProfileDrawer = ({ selectedDrawerItem }) => {
  //const
  const allTabs = ["Details", "Automation", "History"];

  const [selectedTab, setSelectedTab] = useState("Details");
  const [taskProfileDetails, setTaskProfileDetails] = useState({});

  //functions

  async function getTaskProfileDetails() {
    try {
      let response = await axios.get(
        `https://finacebackend.marketsverse.com/api/gettask?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }&taskid=${selectedDrawerItem?.taskid}`
      );
      if (response?.data?.success && response?.data?.data?.length > 0) {
        setTaskProfileDetails(response?.data?.data[0]);
        // console.log(response?.data?.data[0], "task profile data");
      }
    } catch (error) {
      console.log(error?.message, "Get task profile details!");
    }
  }

  //renderings

  useEffect(() => {
    if (
      JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername &&
      selectedDrawerItem?.taskid
    ) {
      getTaskProfileDetails();
    }
  }, [selectedDrawerItem]);

  return (
    <div className="drowpside-right">
      <div className={classNames.eachJobContainer}>
        <div className={classNames.header}>
          <div>
            <div className={classNames.title}>{taskProfileDetails?.name}</div>
            <div className={classNames.id}>{taskProfileDetails?.taskid}</div>
          </div>
          <div className={classNames.allTabs}>
            {allTabs?.map((eachItem, index) => {
              return (
                <div
                  key={eachItem + index}
                  className={selectedTab === eachItem && classNames.selectedTab}
                  onClick={() => setSelectedTab(eachItem)}
                >
                  {eachItem}
                </div>
              );
            })}
          </div>
        </div>
        {selectedTab === "Details" ? (
          <>
            <div className={classNames.flexDiv}>
              <div className={classNames.eachDetailedBox}>
                <div className={classNames.title}>Assigned By:</div>
                <div className={classNames.contentBox}>
                  <div className={classNames.imageContainer}>
                    {taskProfileDetails?.assignedByUserDetails?.length > 0 &&
                    taskProfileDetails?.assignedByUserDetails[0]?.ProfilePic ? (
                      <img
                        src={
                          taskProfileDetails?.assignedByUserDetails[0]
                            ?.ProfilePic
                        }
                        alt={taskProfileDetails?.assignedByUserDetails[0]?.name}
                      />
                    ) : taskProfileDetails?.assignedByUserDetails?.length > 0 &&
                      taskProfileDetails?.assignedByUserDetails[0]?.name ? (
                      taskProfileDetails?.assignedByUserDetails[0]?.name?.charAt(
                        0
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={classNames.content}>
                    <div>
                      {taskProfileDetails?.assignedByUserDetails?.length > 0 &&
                      taskProfileDetails?.assignedByUserDetails[0]?.name
                        ? taskProfileDetails?.assignedByUserDetails[0]?.name
                        : "-"}
                    </div>
                    <div>
                      {taskProfileDetails?.assignedByUserDetails?.length > 0 &&
                      taskProfileDetails?.assignedByUserDetails[0]?.email
                        ? taskProfileDetails?.assignedByUserDetails[0]?.email
                        : "-"}
                    </div>
                  </div>
                  <EditBtnIcon />
                </div>
              </div>
              <div className={classNames.eachDetailedBox}>
                <div className={classNames.title}>Assigned To:</div>
                <div className={classNames.contentBox}>
                  <div className={classNames.imageContainer}>
                    {taskProfileDetails?.assignedToUserDetails?.length > 0 &&
                    taskProfileDetails?.assignedToUserDetails[0]?.name ? (
                      <img
                        src={
                          taskProfileDetails?.assignedToUserDetails[0]
                            ?.ProfilePic
                        }
                        alt={taskProfileDetails?.assignedToUserDetails[0]?.name}
                      />
                    ) : taskProfileDetails?.assignedToUserDetails?.length > 0 &&
                      taskProfileDetails?.assignedToUserDetails[0]?.name ? (
                      taskProfileDetails?.assignedToUserDetails[0]?.name?.charAt(
                        0
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={classNames.content}>
                    <div>
                      {taskProfileDetails?.assignedToUserDetails?.length > 0 &&
                      taskProfileDetails?.assignedToUserDetails[0]?.name
                        ? taskProfileDetails?.assignedToUserDetails[0]?.name
                        : "-"}
                    </div>
                    <div>
                      {taskProfileDetails?.assignedToUserDetails?.length > 0 &&
                      taskProfileDetails?.assignedToUserDetails[0]?.email
                        ? taskProfileDetails?.assignedToUserDetails[0]?.email
                        : "-"}
                    </div>
                  </div>
                  <EditBtnIcon />
                </div>
              </div>
            </div>
            <div className={classNames.desContainer}>
              <div className={classNames.title}>Description:</div>
              <div className={classNames.contentBox}>
                {taskProfileDetails?.description
                  ? taskProfileDetails.description
                  : "-"}
              </div>
            </div>
            <div className={classNames.flexDiv}>
              <div
                className={classNames.eachDetailedBox}
                style={{ width: "20%" }}
              >
                <div className={classNames.title}>Order</div>
                <div className={classNames.contentBox}>
                  <div className={classNames.content}>
                    <div>
                      {taskProfileDetails?.order
                        ? taskProfileDetails?.order
                        : "-"}
                    </div>
                  </div>
                  <EditBtnIcon />
                </div>
              </div>
              <div
                className={classNames.eachDetailedBox}
                style={{ width: "30%" }}
              >
                <div className={classNames.title}>Type</div>
                <div className={classNames.contentBox}>
                  <div className={classNames.content}>
                    <div>
                      {taskProfileDetails?.tasktype
                        ? taskProfileDetails?.tasktype
                        : "-"}
                    </div>
                  </div>
                  <EditBtnIcon />
                </div>
              </div>
              <div
                className={classNames.eachDetailedBox}
                style={{ width: "50%" }}
              >
                <div className={classNames.title}>Type</div>
                <div className={classNames.contentBox}>
                  <div className={classNames.content}>
                    <div>
                      {taskProfileDetails?.automationitem
                        ? taskProfileDetails?.automationitem
                        : "-"}
                    </div>
                  </div>
                  <EditBtnIcon />
                </div>
              </div>
            </div>
            <div className={classNames.flexDiv}>
              <div className={classNames.eachDetailedBox}>
                <div className={classNames.title}>Created At:</div>
                <div className={classNames.contentBox}>
                  <div className={classNames.content}>
                    <div>
                      {taskProfileDetails?.createdAt
                        ? moment(taskProfileDetails?.createdAt).format("h:mm A")
                        : "-"}
                    </div>
                    <div>
                      {taskProfileDetails?.createdAt
                        ? moment(taskProfileDetails?.createdAt).format(
                            "MMMM Do YYYY"
                          )
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames.eachDetailedBox}>
                <div className={classNames.title}>Updated At:</div>
                <div className={classNames.contentBox}>
                  <div className={classNames.content}>
                    <div>
                      {taskProfileDetails?.updatedAt
                        ? moment(taskProfileDetails?.updatedAt).format("h:mm A")
                        : "-"}
                    </div>
                    <div>
                      {taskProfileDetails?.updatedAt
                        ? moment(taskProfileDetails?.updatedAt).format(
                            "MMMM Do YYYY"
                          )
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : selectedTab === "Automation" ? (
          <>
            <div className={classNames.flexDiv}>
              <div className={classNames.eachDetailedBox}>
                <div className={classNames.title}>Type</div>
                <div className={classNames.contentBox}>
                  <div className={classNames.content} style={{ width: "100%" }}>
                    <div className={classNames.onlineEdit}>
                      <span>Send delivery document</span>
                      <EditBtnIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.flexDiv}>
              <div
                className={classNames.eachDetailedBox}
                style={{ width: "100%" }}
              >
                <div className={classNames.title}>
                  Subject
                  <EditBtnIcon />
                </div>
                <div className={classNames.contentBox}>
                  <div className={classNames.content} style={{ width: "100%" }}>
                    <span>
                      {taskProfileDetails?.subject
                        ? taskProfileDetails?.subject
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.desContainer}>
              <div className={classNames.title}>
                Body <EditBtnIcon />
              </div>
              <div className={classNames.contentBox}>
                {taskProfileDetails?.body ? taskProfileDetails?.body : "-"}
              </div>
            </div>
            <div className={classNames.attachmentsContainer}>
              <div className={classNames.title}>Attachments</div>
              <div className={classNames.allAttachments}>
                <div>
                  {taskProfileDetails?.filelink ? (
                    <img src={taskProfileDetails?.filelink} alt="" />
                  ) : (
                    <TiAttachment />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
