import React, { useContext, useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import { Flex, Select } from "antd";
import { RiPencilFill } from "react-icons/ri";
import { AiOutlineUser, AiOutlineCalendar } from "react-icons/ai";
import graphup from "./graphup.svg";
import emptybag from "./emptybag.svg";
import notask from "./notask.svg";
import workbreakdown from "./workbreakdown.svg";
import lock from "./lock.svg";
import { GlobalContext } from "../../context/Globalcontext";
import { getData } from "../../assets/functions/apiCalls";
import { EachCustomDropdown } from "../../components/Custom";

const Dashboard = () => {
  const pendingClinet = [
    "Invoices",
    "Organisers",
    "Signatures",
    "Proposals",
    "Approvals",
  ];
  const jobs = ["Over Due", "Approaching Deadline", "No Activity", "Total"];

  const { email } = useContext(GlobalContext);

  const [dashboard, setDashboard] = useState("");

  //functions

  async function getStats() {
    let response = await getData(
      `https://finacebackend.marketsverse.com/api/getassigndata?email=${
        email || localStorage.getItem("nvestBankLoginAccount")
      }`
    );
    console.log(response, "get stats response");
    if (response?.success && response?.data?.length > 0) {
      setDashboard(response?.data[0]);
    }
    console.log(response, "organization response");
  }

  //renderings

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>Pipelines</div>
      </div>
      <div className={styles.flexContainer}>
        <div>
          <div className={styles.titleWrapper} style={{ padding: "20px 0px" }}>
            <div style={{ fontSize: "0.875rem", fontWeight: 600 }}>Tasks</div>
          </div>
          <div className={styles.firstGrid}>
            <div className={styles.gridCard}>
              <div className={styles.gridrow1}>
                <div className={styles.title}>Tasks Assigned</div>
              </div>
              <div className={styles.gridrow2}>
                <div className={styles.gridrow2Left}>
                  {dashboard?.TotalTaskAssignedBy
                    ? dashboard?.TotalTaskAssignedBy
                    : "0"}
                </div>
                {/* <div className={styles.gridrow2Right}>
              +00.01%
              <img src={graphup} alt="" style={{ paddingLeft: "5px" }} />
            </div> */}
              </div>
            </div>
            <div className={styles.gridCard}>
              <div className={styles.gridrow1}>
                <div className={styles.title}>Tasks Received</div>
              </div>
              <div className={styles.gridrow2}>
                <div className={styles.gridrow2Left}>
                  {" "}
                  {dashboard?.TotalTaskAssignedTo
                    ? dashboard?.TotalTaskAssignedTo
                    : "0"}
                </div>
                {/* <div className={styles.gridrow2Right}>
              +00.01%
              <img src={graphup} alt="" style={{ paddingLeft: "5px" }} />
            </div> */}
              </div>
            </div>
          </div>
          <div className={styles.titleWrapper} style={{ padding: "20px 0px" }}>
            <div style={{ fontSize: "0.875rem", fontWeight: 600 }}>Jobs</div>
          </div>
          <div className={styles.secondGrid}>
            <div className={styles.gridCard}>
              <div
                className={styles.gridrow1}
                style={{ alignItems: "center", marginTop: "-2px" }}
              >
                <div className={styles.title}>Jobs Assigned</div>

                {/* <div>
                  <Select
                    placeholder="Today"
                    variant="borderless"
                    size="small"
                    dropdownStyle={{ width: "auto" }}
                    style={{
                      height: "auto",
                      border: "none",
                      // visibility: index === 1 || index === 2 ? "visible" : "hidden",
                    }}
                    options={[
                      { value: "Today", label: "Today" },
                      { value: "Tomorrow", label: "Tomorrow" },
                    ]}
                  />
                </div> */}
              </div>
              <div className={styles.gridrow2}>
                <div className={styles.gridrow2Left}>
                  {dashboard?.TotalJobsAssignedBy
                    ? dashboard?.TotalJobsAssignedBy
                    : "0"}
                </div>
                {/* <div className={styles.gridrow2Right}>
              +00.01%
              <img src={graphup} alt="" style={{ paddingLeft: "5px" }} />
            </div> */}
              </div>
            </div>
            <div className={styles.gridCard}>
              <div
                className={styles.gridrow1}
                style={{ alignItems: "center", marginTop: "-2px" }}
              >
                <div className={styles.title}>Jobs Received</div>

                {/* <div>
                  <Select
                    placeholder="Today"
                    variant="borderless"
                    size="small"
                    dropdownStyle={{ width: "auto" }}
                    style={{
                      height: "auto",
                      border: "none",
                      // visibility: index === 1 || index === 2 ? "visible" : "hidden",
                    }}
                    options={[
                      { value: "Today", label: "Today" },
                      { value: "Tomorrow", label: "Tomorrow" },
                    ]}
                  />
                </div> */}
              </div>
              <div className={styles.gridrow2}>
                <div className={styles.gridrow2Left}>
                  {dashboard?.TotalJobsAssignedTo
                    ? dashboard?.TotalJobsAssignedTo
                    : "0"}
                </div>
                {/* <div className={styles.gridrow2Right}>
              +00.01%
              <img src={graphup} alt="" style={{ paddingLeft: "5px" }} />
            </div> */}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className={styles.titleWrapper}
            style={{ justifyContent: "space-between" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "20px 0px",
              }}
            >
              <div style={{ fontSize: "0.875rem", fontWeight: 600 }}>
                Work Breakdown
              </div>
            </div>
            <div>
              <div
                className={styles.secondTitleWrapper}
                style={{ pointerEvents: "none" }}
              >
                <EachCustomDropdown
                  dropdown={["By Client"]}
                  name="byClient"
                  title="By Client"
                  // stateValue={accomdationRoom}
                  // setState={setaccomdationRoom}
                  //topTitle="true"
                />
              </div>
            </div>
          </div>
          <div className={styles.thirdGridRight}>
            <img src={workbreakdown} alt="workbreakdown" />
            <div>
              <img src={lock} alt="lock" />
            </div>
            {/* <div>No pending tasks</div> */}
          </div>
        </div>
      </div>
      <div
        className={styles.flexContainer}
        style={{ height: "calc(100% - 320px)" }}
      >
        <div>
          <div
            className={styles.titleWrapper}
            style={{ padding: "20px 0px", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: "0.875rem", fontWeight: 600 }}>Tasks</div>
            </div>
          </div>
          <div className={styles.thirdGrid}>
            <div className={styles.thirdGridLeft}>
              <img src={emptybag} alt="" />
              <div className={styles.lab1}>
                There is nothing in your to do list at the moment{" "}
              </div>
              <div className={styles.lab2}>View your task for tommorw</div>
            </div>
          </div>
        </div>
        <div>
          <div
            className={styles.titleWrapper}
            style={{ padding: "20px 0px", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: "0.875rem", fontWeight: 600 }}>Jobs</div>
            </div>
          </div>
          <div className={styles.thirdGrid}>
            <div className={styles.thirdGridLeft}>
              <img src={emptybag} alt="" />
              <div className={styles.lab1}>
                There is nothing in your to do list at the moment{" "}
              </div>
              <div className={styles.lab2}>View your task for tommorw</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
