import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./Catories.module.scss";
import "../../assets/Scsss/Global.scss";
// import './catoriesSummarypage.scss'
//assets

import Drawer from "react-modern-drawer";
import AWS from "aws-sdk";
import {
  EachCustomDropdown,
  EachCustomDropdownSearch,
  EachCustomTextarea,
  EachInput,
} from "../../components/Custom";
import { GlobalContext } from "../../context/Globalcontext";
import axios from "axios";
import { IoIosAttach } from "react-icons/io";
import Editingpage from "../EditingPage/EditingPage";
import { toastify } from "../../assets/functions/toastify";

const Tasks = () => {
  const { dropDowndownvalue, editingToggle, seteditingToggle } =
    useContext(GlobalContext);
  const allautomationItems = [
    { title: "Send invoice", value: "sendinvoice" },
    { title: "Send proposal", value: "sendproposal" },
    { title: "Send delivery doc", value: "senddeliverydocument" },
    { title: "Send form", value: "sendform" },
    { title: "Send agreement", value: "sendagreement" },
    { title: "Schedule meeting", value: "schedulemeeting" },
  ];
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [allTasks, setAllTasks] = useState([]);
  const [localRefresh, setLocalRefresh] = useState(false);
  const [kichenItem, setkichenItem] = useState({});
  const [allSteps, setAllSteps] = useState([]);
  const [loactionget, setloactionget] = useState([]);
  const [uploadedFile, setUploadedFile] = useState("");
  const [selectedRequiredAutomation, setSelectedRequiredAutomation] =
    useState(false);
  const [assignedTo, setAllAssignedTo] = useState([]);

  //aws

  AWS.config.update({
    accessKeyId: process.env.AWS_accessKeyId,
    secretAccessKey: process.env.AWS_secretAccessKey,
    region: "Us-east-1",
  });

  const handleFileInput = (e) => {
    setUploadedFile(e.target.files[0]);
    uploadFile(e.target.files[0]);
  };

  const uploadFile = (file) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: "montagestr",
      Key: file.name,
      Body: file,
    };

    s3.upload(params, (err, data) => {
      console.log(data, "AWS file upload");
      if (err) {
        console.error("Error", err);
      } else {
        setkichenItem((prev) => {
          return {
            ...prev,
            filelink: data?.Location,
          };
        });
      }
    });
  };

  //functions

  function getallTasks() {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/gettask?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        setAllTasks(res?.data?.data);
      })
      .catch((error) => {
        console.log(error?.message, "Get all automated tasks server error");
      });
  }

  function locationdropdown() {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getlocation?teamUsername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        if (res?.data?.success) {
          setloactionget(res?.data?.data);
        } else {
          setloactionget([]);
        }
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
      });
  }

  function getAllSteps() {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getstagestep?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        setAllSteps(res?.data?.data);
      })
      .catch((error) => {
        console.log(error?.message, "Get all pipelines server error");
      });
  }

  const [formErr, setFormErr] = useState(null);

  const handleSubmit = () => {
    axios
      .post(`https://finacebackend.marketsverse.com/api/createtask`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        ...kichenItem,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          // setTimeout(() => {
          //     window.location.reload();
          // }, 1000);
          setLocalRefresh((prev) => !prev);
          setkichenItem({});
          setUploadedFile("");
          setFormErr(null);
          toastify(
            data?.message ? data?.message : "Created new task successfully!",
            "success"
          );
        } else {
          setFormErr(data.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Server error while adding new stage");
      });
  };

  const localclikc = () => {
    let addlocal = localStorage?.getItem("drawershow");

    localStorage?.setItem("drawershow", Number(addlocal + 1));
  };
  let loc = window.location.pathname;
  let finallo = loc?.split("/");

  async function getAllAssignedToForUser() {
    try {
      let response = await axios.get(
        `https://finacebackend.marketsverse.com/api/getallplayersforteam/${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      );

      if (response?.data?.data?.length > 0) {
        setAllAssignedTo(response?.data?.data);
      }

      console.log(response, "Get clients for all staff members response");
    } catch (error) {
      console.log(error, "Get clients for all staff members error");
    }
  }

  //renderings

  useEffect(() => {
    getallTasks();
  }, [dropDowndownvalue, localRefresh]);

  useEffect(() => {
    getAllSteps();
    locationdropdown();
    getAllAssignedToForUser();
  }, []);

  return (
    <>
      {editingToggle ? (
        <Editingpage />
      ) : (
        <div className="global-dashboard">
          <div className="label-box-header" onClick={localclikc}>
            {finallo?.[3]}
          </div>
          <div className="top-section-golbal">
            <div className="left-side">
              <div className="label-box">
                <svg
                  style={{
                    marginRight: "10px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z"
                    fill="#5A5656"
                  />
                  <path
                    d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z"
                    fill="#5A5656"
                  />
                </svg>
                Export
              </div>

              <>
                <div className="label-box">
                  <svg
                    style={{
                      marginRight: "10px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <path
                      d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                      fill="#5A5656"
                    />
                  </svg>
                  Add Filter
                </div>
              </>
            </div>

            <div className="right-side">
              <div className="shortBy">Sort By</div>
              <div className="dropdownlist">
                Newest
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="6"
                  viewBox="0 0 7 6"
                  fill="none"
                >
                  <path
                    d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                    fill="#5A5656"
                  />
                </svg>
              </div>
              <div className="addLabel" onClick={() => setCheckBoxStage("add")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                    fill="white"
                  />
                </svg>
                Add New
              </div>
              <Drawer
                size={450}
                // duration={500}
                open={checkBoxStage}
                onClose={() => setCheckBoxStage("")}
                direction="right"
                className="bla bla bla"
                overlayColor="#FFF"
                overlayOpacity={0}
              >
                {checkBoxStage == "add" && (
                  <div className="drowpside-right">
                    <div
                      style={{
                        position: "absolute",
                        right: "1rem",
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div>
                    <div
                      className={classNames.eachFilterItem}
                      style={{
                        fontWeight: "500",
                        pointerEvents: "none",
                        paddingLeft: "0",
                        fontSize: "1.2rem",
                        marginBottom: "2rem",
                      }}
                    >
                      Add new task
                    </div>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <div className={classNames.subTitle}>
                        Basic Information
                      </div>
                      <EachInput
                        name="name"
                        placeholder="Name..."
                        stateValue={kichenItem}
                        setState={setkichenItem}
                      />
                      <EachCustomTextarea
                        name="description"
                        placeholder="Description..."
                        stateValue={kichenItem}
                        setState={setkichenItem}
                      />
                      <EachCustomDropdown
                        dropdown={assignedTo}
                        title="Assigned to"
                        name="assignedto"
                        stateValue={kichenItem}
                        setState={setkichenItem}
                        type="objUserDetails"
                        mapVal={{
                          name: "name",
                        }}
                        stateVal={"userid"}
                      />
                      <div style={{ marginTop: "1rem" }}>
                        <EachCustomDropdown
                          dropdown={assignedTo}
                          title="Assigned by"
                          name="assignedby"
                          stateValue={kichenItem}
                          setState={setkichenItem}
                          type="objUserDetails"
                          mapVal={{
                            name: "name",
                          }}
                          stateVal={"userid"}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <div className={classNames.subTitle}>
                        Step information
                      </div>
                      <EachCustomDropdown
                        dropdown={allSteps}
                        name="stagestepid"
                        title="Select steps"
                        stateValue={kichenItem}
                        setState={setkichenItem}
                        // topTitle="true"
                        type="obj"
                        mapVal={{
                          name: "name",
                          desc: "stagestepid",
                        }}
                        stateVal={"stagestepid"}
                      />
                      <EachInput
                        name="order"
                        placeholder="Order in step..."
                        stateValue={kichenItem}
                        setState={setkichenItem}
                      />
                    </div>

                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <div className={classNames.subTitle}>Task type</div>
                      <div className={classNames.selectOne}>
                        <div
                          className={
                            kichenItem?.tasktype === "automation"
                              ? classNames.selectedOne
                              : ""
                          }
                          onClick={() => {
                            setkichenItem((prev) => {
                              return { ...prev, tasktype: "automation" };
                            });
                          }}
                        >
                          Automation
                        </div>
                        <div
                          className={
                            kichenItem?.tasktype === "manual"
                              ? classNames.selectedOne
                              : ""
                          }
                          onClick={() => {
                            setkichenItem((prev) => {
                              return { ...prev, tasktype: "manual" };
                            });
                          }}
                        >
                          Manual
                        </div>
                      </div>
                    </div>

                    {kichenItem?.tasktype === "automation" && (
                      <div
                        style={{
                          marginBottom: "1rem",
                        }}
                      >
                        <div className={classNames.subTitle}>
                          Automation type
                        </div>
                        <div className={classNames.selectOne}>
                          {allautomationItems?.map((eachItem, index) => {
                            return (
                              <div
                                key={eachItem?.title + index}
                                className={
                                  kichenItem?.automationitem === eachItem?.value
                                    ? classNames.selectedOne
                                    : ""
                                }
                                onClick={() => {
                                  if (
                                    eachItem?.title === "Send invoice" ||
                                    eachItem?.title === "Send proposal" ||
                                    eachItem?.title === "Send delivery doc" ||
                                    eachItem?.title === "Send agreement"
                                  ) {
                                    setSelectedRequiredAutomation(true);
                                  } else {
                                    setSelectedRequiredAutomation(false);
                                  }
                                  setkichenItem((prev) => {
                                    return {
                                      ...prev,
                                      automationitem: eachItem?.value,
                                    };
                                  });
                                }}
                              >
                                {eachItem?.title}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {selectedRequiredAutomation && (
                      <div
                        style={{
                          marginBottom: "1rem",
                        }}
                      >
                        <div className={classNames.subTitle}>
                          Email structure
                        </div>
                        <EachInput
                          name="subject"
                          placeholder="Subject..."
                          stateValue={kichenItem}
                          setState={setkichenItem}
                        />
                        <EachCustomTextarea
                          name="body"
                          placeholder="Body..."
                          stateValue={kichenItem}
                          setState={setkichenItem}
                        />
                      </div>
                    )}

                    <label
                      className={classNames.fileUpload}
                      htmlFor="createTaskFile"
                    >
                      <IoIosAttach />
                      {uploadedFile ? uploadedFile?.name : "Attach File"}
                      <input
                        type="file"
                        id="createTaskFile"
                        onChange={handleFileInput}
                      />
                    </label>

                    <button
                      className={classNames.submitBtn}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
                  </div>
                )}
              </Drawer>
            </div>
          </div>

          <div className="handle-table">
            <div className="g-table-sec">
              <div className="table-section">
                <table style={{ minWidth: "100%" }}>
                  <thead>
                    <tr
                      style={{
                        gridTemplateColumns: "min-content repeat(6, 1fr)",
                      }}
                    >
                      <th>Name</th>
                      <th>ID</th>
                      <th>Jobs</th>
                      <th>Type</th>
                      <th>Stage</th>
                      <th>Pipeline</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allTasks?.map((row, index) => {
                      return (
                        <tr
                          key={row?.name + index}
                          style={{
                            gridTemplateColumns: "min-content repeat(6, 1fr)",
                            pointerEvents: sidebarOpen ? "none" : "",
                            cursor: "pointer",
                          }}
                          onClick={() => seteditingToggle(true)}
                        >
                          <td>{row?.name}</td>
                          <td>{row?.taskid}</td>
                          <td>0</td>
                          <td>{row?.tasktype}</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tasks;
