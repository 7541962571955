import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./Catories.module.scss";
import "../../assets/Scsss/Global.scss";
// import './catoriesSummarypage.scss'
//assets
import { ReactComponent as FilterIcon } from "../../assets/images/filter.svg";
import Drawer from "react-modern-drawer";

import {
  CopyBtn,
  CustomCheckbox,
  EachCustomDropdown,
  EachCustomImageUpload,
  EachCustomImageUploadcustom,
  EachCustomSelectMultiple,
  EachCustomSelectMultiples,
  EachInputCustom,
  EachInputsepfic,
  EachCustomTextarea,
  EachCustomSelectMultiplelist,
  OwnEachCustomDropdown,
  EachInput,
} from "../../components/Custom";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ReactComponent as SortArrows } from "../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";
import { useNetworkRequest } from "../../hooks/networkRequest";
import { deleteData, getData } from "../../assets/functions/apiCalls";
import axios from "axios";
import { toastify } from "../../assets/functions/toastify";
import { filterDetails } from "../../assets/functions";
import { APP_CODE } from "../../assets/data/constant";
import Editingpage from "../EditingPage/EditingPage";
import PipelineSubmenu from "./Submenu";

const Organization = () => {
  const {
    email,
    actionBtns,
    setActionBtns,
    globalSearchQuery,
    dropDowndownvalue,
    editingToggle,
    seteditingToggle,
  } = useContext(GlobalContext);
  const allautomationItems = [
    "Send invoice",
    "Send proposal",
    "Send delivery doc",
    "Send form",
    "Create task",
    "Schedule meeting",
  ];
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [allPipelines, setAllPipelines] = useState([]);
  const [localRefresh, setLocalRefresh] = useState(false);
  const [kichenItem, setkichenItem] = useState({
    name: "",
    description: "",
  });

  const [allStages, setAllStages] = useState([]);
  const [allSteps, setAllSteps] = useState([]);
  const [localSidebar, setLocalSidebar] = useState(false);
  const [addNewStep, setAddNewStep] = useState({});
  const [addNewAutomaticTasks, setAddNewAutomaticTasks] = useState({});
  const [addNewManualTasks, setAddNewManualTasks] = useState({});

  const [loactionget, setloactionget] = useState([]);

  //functions

  function getAllPipelines() {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getpipeline?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        setAllPipelines(res?.data?.data);
      })
      .catch((error) => {
        console.log(error?.message, "Get all pipelines server error");
      });
  }

  function locationdropdown() {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getlocation?teamUsername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        if (res?.data?.success) {
          setloactionget(res?.data?.data);
        } else {
          setloactionget([]);
        }
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
      });
  }

  function getAllStages() {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getstage?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        setAllStages(res?.data?.data);
      })
      .catch((error) => {
        console.log(error?.message, "Get all pipelines server error");
      });
  }

  function getAllSteps() {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getstagestep?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        setAllSteps(res?.data?.data);
      })
      .catch((error) => {
        console.log(error?.message, "Get all pipelines server error");
      });
  }

  const [formErr, setFormErr] = useState(null);

  const handleSubmit = () => {
    axios
      .post(`https://finacebackend.marketsverse.com/api/addpipeline`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        name: kichenItem?.name,
        description: kichenItem?.description,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          // setTimeout(() => {
          //     window.location.reload();
          // }, 1000);
          setLocalRefresh((prev) => !prev);

          setFormErr(null);
        } else {
          setFormErr(data.message);
        }
      });
  };

  const handleStepSubmit = () => {
    axios
      .post(`https://finacebackend.marketsverse.com/api/addstagestep`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        ...kichenItem,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          // setTimeout(() => {
          //     window.location.reload();
          // }, 1000);
          setLocalRefresh((prev) => !prev);

          setFormErr(null);
        } else {
          setFormErr(data.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Server error while adding new step");
      });
  };

  const handleSubmitAutomatedTask = () => {
    axios
      .post(`https://finacebackend.marketsverse.com/api/createautomatetask`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        ...kichenItem,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          // setTimeout(() => {
          //     window.location.reload();
          // }, 1000);
          setLocalRefresh((prev) => !prev);
          setkichenItem({});
          setFormErr(null);
        } else {
          setFormErr(data.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Server error while adding new stage");
      });
  };

  const handleSubmitManualTasks = () => {
    axios
      .post(`https://finacebackend.marketsverse.com/api/createmanualtask`, {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        ...kichenItem,
      })

      .then(({ data }) => {
        if (data.status) {
          setCheckBoxStage("");
          // setTimeout(() => {
          //     window.location.reload();
          // }, 1000);
          setLocalRefresh((prev) => !prev);
          setkichenItem({});
          setFormErr(null);
        } else {
          setFormErr(data.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Server error while adding new stage");
      });
  };

  const localclikc = () => {
    let addlocal = localStorage?.getItem("drawershow");

    localStorage?.setItem("drawershow", Number(addlocal + 1));
  };
  let loc = window.location.pathname;
  let finallo = loc?.split("/");

  //renderings

  useEffect(() => {
    if (JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername) {
      getAllPipelines();
    }
  }, [dropDowndownvalue, localRefresh]);

  useEffect(() => {
    getAllStages();
    getAllSteps();
    locationdropdown();
  }, []);

  return (
    <>
      {editingToggle ? (
        // <Editingpage />
        <PipelineSubmenu
          stateValue={editingToggle}
          setState={seteditingToggle}
          localSidebar={localSidebar}
          setLocalSidebar={setLocalSidebar}
          setLocalRefresh={setLocalRefresh}
        />
      ) : (
        <div className="global-dashboard">
          <div className="label-box-header" onClick={localclikc}>
            {finallo?.[3]}
          </div>
          <div className="top-section-golbal">
            <div className="left-side">
              <div className="label-box">
                <svg
                  style={{
                    marginRight: "10px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z"
                    fill="#5A5656"
                  />
                  <path
                    d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z"
                    fill="#5A5656"
                  />
                </svg>
                Export
              </div>

              <>
                <div className="label-box">
                  <svg
                    style={{
                      marginRight: "10px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <path
                      d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                      fill="#5A5656"
                    />
                  </svg>
                  Add Filter
                </div>
              </>
            </div>

            <div className="right-side">
              <div className="shortBy">Sort By</div>
              <div className="dropdownlist">
                Newest
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="6"
                  viewBox="0 0 7 6"
                  fill="none"
                >
                  <path
                    d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                    fill="#5A5656"
                  />
                </svg>
              </div>
              <div className="addLabel" onClick={() => setCheckBoxStage("add")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                    fill="white"
                  />
                </svg>
                Add New
              </div>

              {/* <Drawer
                size={450}
                // duration={500}
                open={localSidebar}
                onClose={() => setLocalSidebar("")}
                direction="right"
                className="bla bla bla"
                overlayColor="#FFF"
                overlayOpacity={0}
              >
                {localSidebar === "addnewstep" ? (
                  <div className="drowpside-right">
                    <div
                      style={{
                        position: "absolute",
                        right: "1rem",
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setLocalSidebar("");
                      }}
                    >
                      X
                    </div>
                    <div
                      className={classNames.eachFilterItem}
                      style={{
                        fontWeight: "500",
                        pointerEvents: "none",
                        paddingLeft: "0",
                        fontSize: "1.2rem",
                      }}
                    >
                      Add new step
                    </div>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachInput
                        title="Basic Information"
                        name="name"
                        placeholder="Name..."
                        stateValue={addNewStep}
                        setState={setAddNewStep}
                        topTitle="true"
                      />
                    </div>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachCustomTextarea
                        name="description"
                        placeholder="Description..."
                        stateValue={addNewStep}
                        setState={setAddNewStep}
                      />
                    </div>
                    <div className={classNames.selectOne}>
                      <div
                        className={
                          addNewStep?.automove ? classNames.selectedOne : ""
                        }
                        onClick={() => {
                          setAddNewStep((prev) => {
                            return { ...prev, automove: true, manual: false };
                          });
                        }}
                      >
                        Automove
                      </div>
                      <div
                        className={
                          addNewStep?.manual ? classNames.selectedOne : ""
                        }
                        onClick={() => {
                          setAddNewStep((prev) => {
                            return { ...prev, automove: false, manual: true };
                          });
                        }}
                      >
                        Manual Move
                      </div>
                    </div>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachCustomDropdown
                        dropdown={allStages}
                        name="stageid"
                        title="Select stage"
                        stateValue={addNewStep}
                        setState={setAddNewStep}
                        topTitle="true"
                        type="obj"
                        mapVal={{
                          name: "name",
                          desc: "description",
                        }}
                        stateVal={"stageid"}
                      />
                      <EachInput
                        name="order"
                        placeholder="Order in stage..."
                        stateValue={addNewStep}
                        setState={setAddNewStep}
                      />
                    </div>
                    <button
                      className={classNames.submitBtn}
                      onClick={handleStepSubmit}
                    >
                      Submit
                    </button>
                    {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
                  </div>
                ) : localSidebar === "addnewautomatedtask" ? (
                  <div className="drowpside-right">
                    <div
                      style={{
                        position: "absolute",
                        right: "1rem",
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div>
                    <div
                      className={classNames.eachFilterItem}
                      style={{
                        fontWeight: "500",
                        pointerEvents: "none",
                        paddingLeft: "0",
                        fontSize: "1.2rem",
                        marginBottom: "2rem",
                      }}
                    >
                      Add new automated task
                    </div>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <div className={classNames.subTitle}>
                        Basic Information
                      </div>
                      <div className={classNames.selectOneGrid}>
                        {allautomationItems?.map((eachItem, index) => {
                          return (
                            <div
                              key={eachItem + index}
                              className={
                                addNewAutomaticTasks?.automationitem ===
                                eachItem
                                  ? classNames.selectedOne
                                  : ""
                              }
                              onClick={() => {
                                setAddNewAutomaticTasks((prev) => {
                                  return {
                                    ...prev,
                                    automationitem: eachItem,
                                  };
                                });
                              }}
                            >
                              {eachItem}
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachCustomDropdown
                        dropdown={allSteps}
                        name="stagestepid"
                        title="Step information"
                        stateValue={addNewAutomaticTasks}
                        setState={setAddNewAutomaticTasks}
                        topTitle="true"
                        type="obj"
                        mapVal={{
                          name: "name",
                          desc: "stagestepid",
                        }}
                        stateVal={"stagestepid"}
                      />
                      <EachInput
                        name="order"
                        placeholder="Order in step..."
                        stateValue={addNewAutomaticTasks}
                        setState={setAddNewAutomaticTasks}
                      />
                    </div>
                    <button
                      className={classNames.submitBtn}
                      onClick={handleSubmitAutomatedTask}
                    >
                      Submit
                    </button>
                    {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
                  </div>
                ) : localSidebar === "addnewmanualtasks" ? (
                  <div className="drowpside-right">
                    <div
                      style={{
                        position: "absolute",
                        right: "1rem",
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div>
                    <div
                      className={classNames.eachFilterItem}
                      style={{
                        fontWeight: "500",
                        pointerEvents: "none",
                        paddingLeft: "0",
                        fontSize: "1.2rem",
                        marginBottom: "2rem",
                      }}
                    >
                      Add new manual task
                    </div>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachInput
                        title="Basic Information"
                        name="name"
                        placeholder="Name..."
                        stateValue={addNewManualTasks}
                        setState={setAddNewManualTasks}
                        topTitle="true"
                      />
                    </div>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachCustomTextarea
                        name="description"
                        placeholder="Description..."
                        stateValue={addNewManualTasks}
                        setState={setAddNewManualTasks}
                      />
                    </div>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachCustomDropdown
                        dropdown={allSteps}
                        name="stagestepid"
                        title="Step information"
                        stateValue={addNewManualTasks}
                        setState={setAddNewManualTasks}
                        topTitle="true"
                        type="obj"
                        mapVal={{
                          name: "name",
                          desc: "stagestepid",
                        }}
                        stateVal={"stagestepid"}
                      />
                      <EachInput
                        name="assignedto"
                        placeholder="Assigned to..."
                        stateValue={addNewManualTasks}
                        setState={setAddNewManualTasks}
                      />
                    </div>
                    <button
                      className={classNames.submitBtn}
                      onClick={handleSubmitManualTasks}
                    >
                      Submit
                    </button>
                    {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
                  </div>
                ) : (
                  ""
                )}
              </Drawer> */}

              <Drawer
                size={450}
                // duration={500}
                open={checkBoxStage}
                onClose={() => setCheckBoxStage("")}
                direction="right"
                className="bla bla bla"
                overlayColor="#FFF"
                overlayOpacity={0}
              >
                {checkBoxStage == "add" && (
                  <div className="drowpside-right">
                    <div
                      style={{
                        position: "absolute",
                        right: "1rem",
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div>
                    <div
                      className={classNames.eachFilterItem}
                      style={{
                        fontWeight: "500",
                        pointerEvents: "none",
                        paddingLeft: "0",
                        fontSize: "1.2rem",
                      }}
                    >
                      Add new pipeline
                    </div>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachInput
                        title="Basic Information"
                        name="name"
                        placeholder="Name..."
                        stateValue={kichenItem}
                        setState={setkichenItem}
                        topTitle="true"
                      />
                    </div>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <EachCustomTextarea
                        name="description"
                        placeholder="Description..."
                        stateValue={kichenItem}
                        setState={setkichenItem}
                      />
                    </div>

                    <button
                      className={classNames.submitBtn}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
                  </div>
                )}
              </Drawer>
            </div>
          </div>

          <div className="handle-table">
            <div className="g-table-sec">
              <div className="table-section">
                <table style={{ minWidth: "100%" }}>
                  <thead>
                    <tr
                      style={{
                        gridTemplateColumns: "min-content repeat(6, 1fr)",
                      }}
                    >
                      <th>Name</th>
                      <th>ID</th>
                      <th>Stages</th>
                      <th>Staff</th>
                      <th>Jobs</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPipelines?.map((row, index) => {
                      return (
                        <tr
                          key={row?.brokerage_name + index}
                          style={{
                            gridTemplateColumns: "min-content repeat(6, 1fr)",
                            pointerEvents: sidebarOpen ? "none" : "",
                            cursor: "pointer",
                          }}
                          onClick={() => seteditingToggle({ data: row })}
                        >
                          <td>{row?.name}</td>
                          <td>{row?.pipelineid}</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>{row?.description}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Organization;
