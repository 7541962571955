import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./staffing.module.scss";
import "../../../assets/Scsss/Global.scss";
import ClickOutside from "react-click-outside";
import Editingpage from "../../EditingPage/EditingPage";
import StaffRoleEing from "./EditingPage/EditingPage";
//assets
import { ReactComponent as FilterIcon } from "../../../assets/images/filter.svg";
import {
  CopyBtn,
  CustomCheckbox,
  EachInputDummy,
  MarignEachInput,
  EachCustomDropdownCountry,
  MRsEachCustomDropdown,
  CustomCheckboxMultiple,
  MyEachCustomImageUpload,
  EachCustomDropdown,
  EachCustomDropdownSearch,
  EachCustomDropdownDate,
  EachCustomImageUpload,
  EachCustomSelectMultiple,
  EachCustomSelectMultiples,
  EachCustomSelectMultiplelist,
  EachCustomTextarea,
  EachInput,
  StaffEachInputDatePicker,
  MultiDropdownNestedService,
  EachInputArray,
  EachInputDatePicker,
  EachInputsepfic,
} from "../../../components/Custom";
import { IoMdArrowDropdown } from "react-icons/io";
import { ReactComponent as SortArrows } from "../../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../../context/Globalcontext";
import { useNetworkRequest } from "../../../hooks/networkRequest";
import ClanderEditing from "./ClendarEditing/EditingPage";
import {
  deleteData,
  getData,
  updateData,
} from "../../../assets/functions/apiCalls";

import axios from "axios";
import { toastify } from "../../../assets/functions/toastify";
import { filterDetails, getCurrentDate } from "../../../assets/functions";
import {
  staffGroupFormGroupType,
  staffGroupFormModules,
} from "../../../assets/data/mapItems";
import {
  amArray,
  datesArray,
  daysOfWeek,
  monthsArray,
  pmArray,
} from "../../../assets/data/constant";
import { FaAlignJustify } from "react-icons/fa";
import { useClickAway } from "use-click-away";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Loading from "../../../assets/images/loadingicon.svg";
import StafGroupEditing from "./StaffGroupEditing/EditingPage";
import StaffTable from "./StaffTable";
import { CSVLink } from "react-csv";

const Staffing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { roleType } = useParams();
  const {
    email,
    actionBtns,
    setActionBtns,
    globalSearchQuery,
    editingToggle,
    seteditingToggle,
  } = useContext(GlobalContext);
  const [allOrganization, setAllOrganization] = useState([]);
  const [allTimezones, setAllTimezones] = useState([]);
  const [allStaffRoles, setAllStaffRoles] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [calendarinfo, setcalendarinfo] = useState({});
  const [organizationSelected, setOrganizationSelected] = useState("");

  const { allValues: allLeads, allValuesLoading: allLeadsLoading } =
    useNetworkRequest({
      URL:
        roleType === "staffgroups" &&
        JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
          ? `https://finacebackend.marketsverse.com/api/getStaff?teamUsername=${
              JSON.parse(localStorage?.getItem("orginzationdetail"))
                ?.TeamUsername
                ? JSON.parse(localStorage?.getItem("orginzationdetail"))
                    ?.TeamUsername
                : "Montage"
            }`
          : roleType === "staffroles" &&
            JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
          ? `https://finacebackend.marketsverse.com/api/getStaffRole?teamUsername=${
              JSON.parse(localStorage?.getItem("orginzationdetail"))
                ?.TeamUsername
                ? JSON.parse(localStorage?.getItem("orginzationdetail"))
                    ?.TeamUsername
                : "Montage"
            }`
          : roleType === "staff" &&
            JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
          ? `https://finacebackend.marketsverse.com/api/getteamplayers/${
              JSON.parse(localStorage?.getItem("orginzationdetail"))
                ?.TeamUsername
                ? JSON.parse(localStorage?.getItem("orginzationdetail"))
                    ?.TeamUsername
                : "Montage"
            }`
          : roleType === "users"
          ? `https://finacebackend.marketsverse.com/api/getlist?key=${
              roleType === "users" ? "user" : ""
            }`
          : roleType === "calendar" &&
            JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
          ? `https://finacebackend.marketsverse.com/api/getcalendarperteam?TeamUsername=${
              JSON.parse(localStorage?.getItem("orginzationdetail"))
                ?.TeamUsername
                ? JSON.parse(localStorage?.getItem("orginzationdetail"))
                    ?.TeamUsername
                : "Montage"
            }`
          : "",
    });

  //page values
  let history = useNavigate();
  const [loadingdata, setloadingdata] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [allLeadsLoading, setAllLeadsLoading] = useState(false);
  // const [allLeads, setAllLeads] = useState(tempLeads);
  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [drawerObject, setDrawerObject] = useState("");
  const [tempArray, setTempArray] = useState([]);
  const [calendardrawer, setcalendardrawer] = useState(false);
  const [staffGroupForm, setStaffGroupForm] = useState({
    teamUsername: JSON.parse(localStorage?.getItem("orginzationdetail"))
      ?.TeamUsername,
    groupType: "",
    groupName: "",
    groupDesc: "",
    modules: [],
  });
  const [staffProfileForm, setStaffProfileForm] = useState({
    TeamPlayerID: "",
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    employeeId: "",
    employeeType: "",
    employeeStatus: "",
    location: "",
    title: "",
    role: "",
    city: "",
    dateOfJoining: "",
    dateOfRelieving: "",
    birthday: "",
    age: "",
    gender: "",
    resume: "",
    NationalId: "",
  });

  const [staffdata, setstaffdata] = useState({
    name: "",
    email: "",
    gendertype: "",
    gendersignature: "Mr",
    dob: "",
    phoneNumber: "",
    username: "",
    CalendarNameId: "",
    city: "",
    employeeId: "",
    gender: "",
    affilatemail: "",
    affilateId: "",
    locations: "",
    locationsid: "",
    address: "",
    picode: "",
    employeeType: "",
    employeeStatus: "",
    Birthday: "",
    TeamPlayerID: "",
    state: "",
    country: "",
    photo: "",
    govid: "",
    staffrole: "",
    staffroleId: "",
    calendartemplate: "",
    Dateofjoining: "",
    specialistToggle: "",
    specialistIds: [{ name: "", id: "" }],
    // employeeId: "",
    // employeeType: "",
    // employeeStatus: "",
    // location: "",
    // title: "",
    // role: "",
    // dateOfJoining: "",
    // dateOfRelieving: "",
    // birthday: "",
    // age: "",

    // resume: "",
    // NationalId: "",
  });

  // setDaysInMonth(days);

  const [staffRolesForm, setStaffRolesForm] = useState({
    teamUsername: JSON.parse(localStorage?.getItem("orginzationdetail"))
      ?.TeamUsername,
    name: "",
    description: "",
    staffGroupIds: [],
  });

  const [calendarForm, setCalendarForm] = useState({
    CalendarName: "",
    CalendarDescription: "",
    TeamUsername: "",
    AnnualHolidays: [
      {
        month: "",
        date: "",
      },
    ],
    WorkingDays: [
      {
        Day: "Monday",
        StartTime: "",
        EndTime: "",
      },
      {
        Day: "Tuesday",
        StartTime: "",
        EndTime: "",
      },
      {
        Day: "Wednesday",
        StartTime: "",
        EndTime: "",
      },
      {
        Day: "Thursday",
        StartTime: "",
        EndTime: "",
      },
      {
        Day: "Friday",
        StartTime: "",
        EndTime: "",
      },
      {
        Day: "Saturday",
        StartTime: "",
        EndTime: "",
      },
      {
        Day: "Sunday",
        StartTime: "",
        EndTime: "",
      },
    ],
    TimeZone: "",
    TimeZoneAbbrevation: "",
  });

  console.log("calendarForm", calendarForm);

  const [selectedindex, setselectedindex] = useState(0);

  const [datesArraylist, setdatesArraylist] = useState([]);

  useEffect(() => {
    cal();
    // alert()
  }, [calendarForm, selectedindex]);

  const addmore = () => {
    // return {
    //   ...stateValue,
    //   [name]:
    //     (calendarObjField === "date" ||
    //       calendarObjField === "month") &&
    //       areAllFieldsFilled(updatedAnnualHolidays[indexx])
    //       ? [
    //         ...updatedAnnualHolidays,
    //         { month: "", date: "" },
    //       ]
    //       : updatedAnnualHolidays,
    // };

    if (
      calendarForm?.AnnualHolidays?.[calendarForm?.AnnualHolidays?.length - 1]
        ?.month != ""
    ) {
      setCalendarForm({
        ...calendarForm,
        AnnualHolidays: [
          ...calendarForm?.AnnualHolidays,
          { month: "", date: "" },
        ],
      });
    }
    // calendarForm?.AnnualHolidays?.map((item, i) => {
    //   console.log(item?.month, "knxcvnxjvncx")
    //   if (item?.month == "") {
    //     alert("empty")
    //     return;
    //   }
    //   else {

    //     return
    //   }

    // })

    // let newArray = [calendarForm]?.map(item => ({ ...item, [...item?.AnnualHolidays] }));

    // let aiodaisd = [...calendarForm?.AnnualHolidays, { month: "", date: "" }]
    // console.log("aiodaisd", newArray)
    // setCalendarForm(aiodaisd)
  };

  const cal = () => {
    const monthsArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let s = 1;

    s =
      monthsArray?.indexOf(
        calendarForm?.AnnualHolidays?.[selectedindex]?.month
      ) + 1;

    // s = calendarForm?.AnnualHolidays[selectedindex]
    // calendarForm?.AnnualHolidays?.map(item => {

    //   s = monthsArray?.indexOf(item?.month) + 1

    // })
    const days = new Date(2024, s, 0).getDate();

    // const randomNumber = Math?.random() * 100;

    console.log("ssss", days, s);
    let dates = Array?.from({ length: days }, (_, index) =>
      (index + 1)?.toString()
    );
    setdatesArraylist(dates);

    return;

    // datesArraylist = Array?.from({ length: 9 }, (_, index) =>
    //   (index + 1).toString()
    // )
  };
  console.log("selectedindex", calendarForm);

  const filterDropdownRef = useRef(null);
  const selectedItemTable = useRef(null);
  const navRightBox = useRef(null);

  const filterItems = [];

  const filterItemsUsers = [
    { name: "Add to staff", to: "confirmAddStaff" },
    { name: "Edit user profile", to: "" },
    { name: "Delete user", to: "" },
  ];

  const filterItemsUsersCnfm = [
    { name: "Yes", to: makeUserToPlayer, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];

  const filterItemsDeleteStaffGroup = [
    {
      name: "Edit staff roles in this staff group",
      to: "editStaffRoleStaffGroup",
    },
    { name: "See staff group details", to: "staffGroupDrawer" },
    { name: "Delete staff group", to: "confirmDeleteStaffGroup" },
  ];

  const filterItemsDeleteStaffGroupCnfm = [
    { name: "Yes", to: deleteStaffGroup, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];

  const filterItemsDeleteStaffRoles = [
    { name: "Edit staff roles", to: "" },
    { name: "Delete staff roles", to: "confirmDeleteStaffRoles" },
  ];

  const filterItemsDeleteStaffRolesCnfm = [
    { name: "Yes", to: deleteStaffRoles, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];

  const filterItemsStaff = [
    { name: "See staff profile" },
    { name: "Add to staff" },
    { name: "Edit staff profile", to: checkUserStaffProfile, type: "func" },
    { name: "Delete staff", to: "deleteStaff" },
  ];

  const filterItemsDeleteCalendar = [
    { name: "Edit calendar", to: "" },
    { name: "Delete calendar", to: "confirmDeleteCalendar" },
  ];

  const filterItemsDeleteCalendarCnfm = [
    { name: "Yes", to: deleteCalendar, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];

  //functions

  async function deleteStaff() {
    // setCheckBoxStage("");
    if (actionBtns?.length > 0) {
      let response = await deleteData(
        `https://finacebackend.marketsverse.com/api/removeplayerfromteam?TeamPlayerID=${
          actionBtns?.length > 0 ? actionBtns[0] : ""
        }`
      );
      if (response?.success) {
        toastify("Successfully deleted staff ", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastify("Error while deleting staff ", "error");
      }
      console.log(response, "Delete staff response");
    }
  }

  const [sepcialdata, setsepcialdata] = useState([]);

  useEffect(() => {
    hetconstultant();
  }, []);

  const hetconstultant = () => {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getconsultationservice?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        if (res.data.success) {
          setsepcialdata(res?.data?.data);
          // setconstitid(res?.data?.data?.[0]?.consultationserviceid)
          // getservice(res?.data?.data?.[0]?.consultationserviceid)
        } else {
          setsepcialdata([]);
        }
        //
      });
  };

  async function checkUserStaffProfile() {
    let response = await getData(
      `https://finacebackend.marketsverse.com/api/getTeamPlayerProfile?TeamPlayerID=${
        actionBtns?.length > 0 ? actionBtns[0] : ""
      }`
    );
    if (response?.success) {
      // setAllOrganization([...response?.data]);
      setCheckBoxStage("editStaffProfile");
    } else {
      setCheckBoxStage("createStaffProfile");
    }
    console.log(response, "checkUserStaffProfile response");
  }

  async function editStaffrolesStaffgroups() {
    const uniqueArray = [...new Set(tempArray)];

    if (actionBtns?.length > 0) {
      let response = await updateData(
        `https://finacebackend.marketsverse.com/api/editStaff/${
          actionBtns?.length > 0 ? actionBtns[0] : ""
        }`,
        {
          staffRoleIds: uniqueArray,
        }
      );
      if (response?.success) {
        toastify(
          "Successfully edited staff roles in this staff group ",
          "success"
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastify(
          "Error while editing staff roles in this staff group ",
          "error"
        );
      }
      console.log(response, "Edit staff roles in this staff group response");
    }
  }

  async function deleteCalendar() {
    if (actionBtns?.length > 0) {
      let response = await deleteData(
        `https://finacebackend.marketsverse.com/api/deletecalendar/${
          actionBtns?.length > 0 ? actionBtns[0] : ""
        }`
      );
      if (response?.success) {
        toastify("Successfully deleted calendar", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastify("Error while deleting calendar", "error");
      }
      console.log(response, "calendar deleted response");
    }
  }

  async function deleteStaffGroup() {
    // setCheckBoxStage("");
    if (actionBtns?.length > 0) {
      let response = await deleteData(
        `https://finacebackend.marketsverse.com/api/deleteStaff/${
          actionBtns?.length > 0 ? actionBtns[0] : ""
        }`
      );
      if (response?.success) {
        toastify("Successfully deleted staff group", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastify("Error while deleting staff group", "error");
      }
      console.log(response, "organization response");
    }
  }

  async function deleteStaffRoles() {
    // setCheckBoxStage("");
    if (actionBtns?.length > 0) {
      let response = await deleteData(
        `https://finacebackend.marketsverse.com/api/deleteStaffRole/${
          actionBtns?.length > 0 ? actionBtns[0] : ""
        }`
      );
      if (response?.success) {
        toastify("Successfully deleted staff roles", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastify("Error while deleting staff roles", "error");
      }
      console.log(response, "organization response");
    }
  }

  function neverMindd() {
    setCheckBoxStage("");
    setActionBtns([]);
  }

  async function addStaffProfileForm() {
    let obj = {
      ...staffProfileForm,
      TeamPlayerID: actionBtns?.length > 0 ? actionBtns[0] : "",
    };
    let response = await axios.post(
      "https://finacebackend.marketsverse.com/api/addTeamPlayerProfile",
      obj
    );
    if (response?.data?.status) {
      toastify("Added staff profile succesfully!", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toastify("Error while trying to add staff profile", "error");
    }
    console.log(response, "response from Add staff profile");
    // console.log(obj, "staffProfileForm filled");
  }

  async function addStaffGroupForm() {
    let response = await axios.post(
      "https://finacebackend.marketsverse.com/api/addStaff",
      staffGroupForm
    );
    if (
      response?.data?.status ||
      response?.data?.message === "user staff added"
    ) {
      toastify("Added staff group succesfully!", "success");
      setCheckBoxStage("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Error while trying to add staff group", "error");
    }
    console.log(response, "response from Add staff group");
  }

  async function addCalendarForm() {
    calendarForm.TeamUsername = JSON.parse(
      localStorage?.getItem("orginzationdetail")
    )?.TeamUsername;
    // console.log(calendarForm, "form value is ");
    let response = await axios.post(
      "https://finacebackend.marketsverse.com/api/createcalendar",
      calendarForm
    );
    if (response?.data?.success) {
      toastify("Calendar created succesfully!", "success");
      setCheckBoxStage("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Error while trying create calendar", "error");
    }
    console.log(response, "response from create calendar");
  }

  async function addStaffRolesForm() {
    let response = await axios.post(
      "https://finacebackend.marketsverse.com/api/addStaffRole",
      staffRolesForm
    );
    if (
      response?.data?.status ||
      response?.data?.message === "staff role added"
    ) {
      toastify("Added staff roles succesfully!", "success");
      setCheckBoxStage("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Error while trying to add staff roles", "error");
    }
    console.log(response, "response from Add staff roles");
  }

  async function playerToTeam(teamUsername) {
    let result = await filterDetails(allLeads?.data, actionBtns[0]);

    let response = await axios.post(
      "https://finacebackend.marketsverse.com/api/addplayerstoteam",
      {
        TeamOwnerEmail: email,
        PlayerUsername: result[0]?.username,
        StartDate: "",
        TeamUsername: teamUsername,
      }
    );
    if (response?.data?.success) {
      toastify("Added player to the selected team", "success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Player data not found", "error");
    }
    console.log(response, "response from make user to player");
  }

  async function getOrganizations() {
    let response = await getData(
      `https://finacebackend.marketsverse.com/api/getteamsbyowner?TeamCreatedBy=${email}`
    );
    if (response?.success) {
      setAllOrganization([...response?.data]);
    }
    console.log(response, "organization response");
  }

  async function getTimezones() {
    let response = await getData(
      `https://finacebackend.marketsverse.com/api/timezones`
    );
    if (response?.success) {
      setAllTimezones([...response?.data]);
    }
    console.log(response, "timezones response");
  }

  async function getStaffRoles() {
    if (JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername) {
      let response = await getData(
        `https://finacebackend.marketsverse.com/api/getStaffRole?teamUsername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
        }`
      );
      if (response?.success) {
        setAllStaffRoles(response?.data);
      }
      console.log(response, "all staff roles response");
    }
  }

  async function getallLocations() {
    if (JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername) {
      let response = await getData(
        `https://finacebackend.marketsverse.com/api/getlocation?teamUsername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
        }`
      );
      if (response?.success) {
        setAllLocations(response?.data);
      }
      console.log(response, "location response");
    }
  }

  async function makeUserToPlayer() {
    let result = await filterDetails(allLeads?.data, actionBtns[0]);
    // console.log(actionBtns, "playerEmail", result);
    let response = await axios.put(
      "https://finacebackend.marketsverse.com/api/addplayer",
      {
        email: result[0]?.email,
      }
    );
    if (response?.data?.success) {
      toastify("Updated user to player", "success");
      setTimeout(() => {
        setCheckBoxStage("playerToSpecificTeam");
      }, 500);
    } else {
      toastify("Error while converting user to player", "error");
    }
    console.log(response, "response from make user to player");
  }

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
      if (
        selectedItemTable.current &&
        !selectedItemTable.current.contains(event.target)
      ) {
        setCheckBoxStage("");
        setActionBtns([]);
      }
      // if (navRightBox.current && !navRightBox.current.contains(event.target)) {
      //   setCheckBoxStage("");
      // }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getOrganizations();
    getStaffRoles();
    getallLocations();
    getTimezones();
  }, [JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername]);
  const ref = useRef(null);
  useEffect(() => {
    console.log(checkBoxStage, "21312312312");
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        // if (checkBoxStage != "") {
        //   setCheckBoxStage("")
        // }
      } else {
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
  const [checkName, setcheckName] = useState(false);
  useEffect(() => {
    if (staffdata?.username != "") {
      const timer = setTimeout(() => {
        cmailName();
      }, 2000);

      // Clean up the timer to avoid memory leaks
      return () => clearTimeout(timer);
    } else {
      setcheckName(false);
    }
  }, [staffdata?.username]);

  const [calndertemp, setcalndertemp] = useState([]);
  useEffect(() => {
    checkcledner();
  }, []);
  const checkcledner = () => {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getcalendarperteam?TeamUsername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
        }`
      )
      .then((res) => {
        if (res?.data.success) {
          setcalndertemp(res.data?.data);
        } else {
          setcalndertemp([]);
        }
      });
  };

  const cmailName = () => {
    axios
      .get(
        `https://comms.globalxchange.io/user/profile/data/get?username=${staffdata?.username}`
      )
      .then((res) => {
        if (res?.data.count > 0) {
          // setrefmail(res?.data?.usersData?.[0])

          setcheckName(false);
          // setshowrefbox(false)
          // setanswerref(true)
          // toastify("Updated user to client", "success");
        } else {
          // setrefmail({})
          setcheckName(true);
          // toastify("error", "error");
          // setshowrefbox(true)
        }
      });
  };

  const [checkemail, setcheckemail] = useState(false);
  useEffect(() => {
    if (staffdata?.affilatemail != "") {
      const timer = setTimeout(() => {
        cmailcheck();
      }, 2000);

      // Clean up the timer to avoid memory leaks
      return () => clearTimeout(timer);
    } else {
      setcheckemail(false);
    }
  }, [staffdata?.affilatemail]);

  const cmailcheck = () => {
    axios
      .get(
        `https://comms.globalxchange.io/user/profile/data/get?email=${staffdata?.affilatemail}`
      )
      .then((res) => {
        if (res?.data.count > 0) {
          // setrefmail(res?.data?.usersData?.[0])
          setcheckemail(false);
          // setshowrefbox(false)
          // setanswerref(true)
          //   toastify("Updated user to client", "success");
        } else {
          setcheckemail(true);
          // setrefmail({})
          // toastify("error", "error");
          // setshowrefbox(true)
        }
      });
  };
  const addmorespicalid = (e) => {
    e.stopPropagation();
    if (
      sepcialdata.filter(function (cv) {
        return !staffdata?.specialistIds.find(function (e) {
          return e.id == cv.consultationserviceid;
        });
      })?.length > 0
    ) {
      if (
        staffdata?.specialistIds[staffdata?.specialistIds?.length - 1]?.name !=
        ""
      ) {
        setstaffdata({
          ...staffdata,
          specialistIds: [...staffdata?.specialistIds, { id: "", name: "" }],
        });
        // }
        return;
      }
    }
  };
  console.log("2342342fgdfgdfgfg", staffdata);
  const submitstaff = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    let specialistIds = staffdata?.specialistIds?.map((item) => {
      return item?.id;
    });
    setloadingdata(true);
    if (staffdata?.specialistToggle?.[0] == "Yes") {
      axios
        .post("https://finacebackend.marketsverse.com/api/registerusertoteam", {
          username: staffdata?.username,
          name: staffdata?.name,
          icon: staffdata?.photo,
          email: staffdata?.affilatemail,

          YearOfBirth: staffdata?.Birthday?.split("/")?.[2],
          ProfilePic: staffdata?.photo,
          Country: staffdata?.country,
          Address: staffdata?.address,
          employeeId: staffdata?.employeeId,
          employeeType: staffdata?.employeeType,
          employeeStatus: staffdata?.employeeStatus,
          location: staffdata?.locationsid,
          title: staffdata?.gendersignature,
          role: staffdata?.staffroleId,
          city: staffdata?.City,
          dateOfJoining: staffdata?.Dateofjoining,
          age: today?.split("/")?.[2] - staffdata?.Birthday?.split("/")?.[2],
          NationalId: staffdata?.govid,
          pincode: staffdata?.picode,
          state: staffdata?.state,
          birthday: staffdata?.Birthday,
          Gender: staffdata?.gendertype?.[0],
          PhoneNumber: staffdata?.phoneNumber,
          TeamCalendarID: staffdata?.CalendarNameId,
          TeamUsername: JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername,
          ref_affiliate: "6535e2f65958ffca65b865ef21fde26f0b8406ca",

          specialist: staffdata?.specialistToggle?.[0] == "Yes" && true,

          serviceids: [...specialistIds],
        })
        .then(({ data }) => {
          if (data.status) {
            toastify("Added succesfully!", "success");
            setCheckBoxStage("");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            setloadingdata(false);
            toastify(data?.message, "error");
          }
        });
    } else {
      axios
        .post("https://finacebackend.marketsverse.com/api/registerusertoteam", {
          username: staffdata?.username,
          name: staffdata?.name,
          icon: staffdata?.photo,
          email: staffdata?.affilatemail,

          YearOfBirth: staffdata?.Birthday?.split("/")?.[2],
          ProfilePic: staffdata?.photo,
          Country: staffdata?.country,
          Address: staffdata?.address,
          employeeId: staffdata?.employeeId,
          employeeType: staffdata?.employeeType,
          employeeStatus: staffdata?.employeeStatus,
          location: staffdata?.locationsid,
          title: staffdata?.gendersignature,
          role: staffdata?.staffroleId,
          city: staffdata?.City,
          dateOfJoining: staffdata?.Dateofjoining,
          age: today?.split("/")?.[2] - staffdata?.Birthday?.split("/")?.[2],
          NationalId: staffdata?.govid,
          pincode: staffdata?.picode,
          state: staffdata?.state,
          birthday: staffdata?.Birthday,
          Gender: staffdata?.gendertype?.[0],
          PhoneNumber: staffdata?.phoneNumber,
          TeamCalendarID: staffdata?.CalendarNameId,
          TeamUsername: JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername,
          ref_affiliate: "6535e2f65958ffca65b865ef21fde26f0b8406ca",
        })
        .then(({ data }) => {
          if (data.status) {
            toastify("Added succesfully!", "success");
            setCheckBoxStage("");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            setloadingdata(false);
            toastify(data?.message, "error");
          }
        });
    }
  };

  console.log("plplp", staffdata);
  // const handleClickInside = () => {
  //   setCheckBoxStage("");
  // };
  // const handleClickOutside1 = () => {
  //   setCheckBoxStage("");
  // };
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside1 = () => {
    setIsOpen(false); // Close the div when clicked outside
  };

  const handleClickInside1 = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state when clicked inside
  };
  const clickRef = React.useRef("");

  useClickAway(clickRef, () => {
    setCheckBoxStage("");
    setActionBtns([]);
  });

  useEffect(() => {
    if (actionBtns?.length > 0) {
      setCheckBoxStage("");
    }
  }, [actionBtns]);

  useEffect(() => {
    setActionBtns([]);
  }, [location]);

  useEffect(() => {
    constultandDatafunction();
    getrole();
  }, []);

  const [stafdropdown, setstafdropdown] = useState([]);
  const [roledrop, setroledrop] = useState([]);
  const getrole = () => {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getStaffRole?teamUsername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        if (res?.data?.success) {
          setroledrop(res?.data?.data);
        } else {
          setroledrop([]);
        }
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
      });
  };

  const constultandDatafunction = () => {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getStaff?teamUsername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        if (res?.data?.success) {
          setstafdropdown(res?.data?.data);
        } else {
          setstafdropdown([]);
        }
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
      });
  };
  const [itemsend, setitemsend] = useState({});
  console.log("roledrop", roledrop);
  const [swtchtoggle, setswtchtoggle] = useState("Data");
  useEffect(() => {
    setswtchtoggle("Data");
  }, []);

  let loc = window.location.pathname;
  let finallo = loc?.split("/");
  const [coundata, setcoundata] = useState([]);
  useEffect(() => {
    countryfunct();
  }, []);

  const countryfunct = () => {
    axios
      .get(`https://comms.globalxchange.io/coin/vault/countries/data/get`)
      .then((res) => {
        if (res.data.status) {
          setcoundata(res?.data?.countries);
        } else {
          setcoundata([]);
        }
        //
      });
  };
  console.log("coundata", coundata);
  const localclikc = () => {
    let addlocal = localStorage?.getItem("drawershow");

    localStorage?.setItem("drawershow", Number(addlocal + 1));
  };
  const [staffitem, setstaffitem] = useState({});
  const [sendcalender, setsendcalender] = useState({});

  function convertDataArray(dataArray) {
    return dataArray.map((item) => {
      const userDetail = item.UserDetails?.[0]?.UserDetails?.[0] || {}; // Extract first UserDetail inside UserDetails array

      return {
        TeamPlayerID: item.TeamPlayerID,
        TeamOwnerEmail: item.TeamOwnerEmail,
        TeamUsername: item.TeamUsername,
        PlayerUsername: item.PlayerUsername,
        StartDate: item.StartDate,
        IsActive: item.IsActive,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        ProfilePic: userDetail.ProfilePic || "",
        UserName: userDetail.name || "",
        Email: userDetail.email || "",
        Address: userDetail.Address || "",
        Country: userDetail.Country || "",
        PhoneNumber: userDetail.PhoneNumber || "",
        Gender: userDetail.Gender || "",
      };
    });
  }

  return (
    <>
      {loadingdata && (
        <div className={classNames.loadingdiv}>
          <img className={classNames.loadingAnimation} src={Loading} />
        </div>
      )}
      {editingToggle ? (
        <>
          {roleType == "staffgroups" && (
            <StafGroupEditing itemsend={itemsend} />
          )}
          {roleType == "staffroles" && <StaffRoleEing itemsend={itemsend} />}
          {roleType == "staff" && (
            <Editingpage staffitem={staffitem} setstaffitem={setstaffitem} />
          )}
          {roleType == "calendar" && (
            <ClanderEditing staffitem={sendcalender} />
          )}
        </>
      ) : (
        <div className="global-dashboard">
          {/* <div className="label-box-header">{finallo?.[3]}</div> */}

          <div className="totalCount">
            <div>
              <div className="title">Human Staff</div>
              <div className="value">0</div>
            </div>
            <div>
              <div className="title">Fin(ai)d Staff</div>
              <div className="value">0</div>
            </div>
            <div>
              <div className="title">Total Workforce</div>
              <div className="value">0</div>
            </div>
            <div>
              <div className="title">Total Hours</div>
              <div className="value">0</div>
            </div>
          </div>

          <div className="top-section-golbal">
            <div className="left-side">
              <div className="shortBy">
                {finallo?.[3].charAt(0).toUpperCase() + finallo?.[3].slice(1)}
              </div>
              <div className="dropdownlist">
                Marketing
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="6"
                  viewBox="0 0 7 6"
                  fill="none"
                >
                  <path
                    d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                    fill="#5A5656"
                  />
                </svg>
              </div>
              {/* <div className="label-box">
                <svg
                  style={{
                    marginRight: "10px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z"
                    fill="#5A5656"
                  />
                  <path
                    d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z"
                    fill="#5A5656"
                  />
                </svg>
                Export
              </div>

              <>
                <div className="label-box">
                  <svg
                    style={{
                      marginRight: "10px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <path
                      d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                      fill="#5A5656"
                    />
                  </svg>
                  Add Filter
                </div>
              </> */}
            </div>

            <div className="right-side">
              {/* <div className="shortBy">Sort By</div>
              <div className="dropdownlist">
                Newest
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="6"
                  viewBox="0 0 7 6"
                  fill="none"
                >
                  <path
                    d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                    fill="#5A5656"
                  />
                </svg>
              </div> */}
              <div className="blueBtn">
                <CSVLink
                  data={
                    roleType === "staff" && allLeads?.data?.length > 0
                      ? convertDataArray(allLeads?.data)
                      : allLeads?.data?.length > 0
                      ? allLeads?.data
                      : []
                  }
                  headers={
                    roleType === "staff"
                      ? [
                          {
                            label: "Name",
                            key: "UserName",
                          },
                          {
                            label: "Email",
                            key: "Email",
                          },
                          {
                            label: "Phone Number",
                            key: "PhoneNumber",
                          },
                          {
                            label: "Gender",
                            key: "Gender",
                          },
                          {
                            label: "Address",
                            key: "Address",
                          },
                        ]
                      : roleType === "staffgroups"
                      ? [
                          { label: "Name", key: "groupName" },
                          { label: "Description", key: "groupDesc" },
                          { label: "Staff Roles", key: "StaffRoleDetails" },
                          { label: "Modules", key: "modules" },
                        ]
                      : roleType === "staffroles"
                      ? [
                          { label: "Name", key: "name" },
                          { label: "Description", key: "description" },
                          { label: "Staff Groups", key: "staffGroupCount" },
                          { label: "# Of Staff", key: "staffCount" },
                        ]
                      : roleType === "calendar"
                      ? [
                          { label: "Name", key: "CalendarName" },
                          { label: "Description", key: "CalendarDescription" },
                          { label: "Time Zone", key: "TimeZone" },
                          { label: "# Of Employees", key: "staffCount" },
                        ]
                      : []
                  }
                  filename={`${roleType}.csv`}
                >
                  Export
                </CSVLink>
              </div>
              <div
                className="addLabel"
                onClick={() => {
                  if (roleType === "staffroles") {
                    setCheckBoxStage("staffRolesForm");
                  } else if (roleType === "staffgroups") {
                    setCheckBoxStage("staffGroupsForm");
                  } else if (roleType === "calendar") {
                    setCheckBoxStage("calendarTemplateForm");
                  } else if (roleType === "staff") {
                    setCheckBoxStage("staff");
                  }
                }}
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                    fill="white"
                  />
                </svg> */}
                Add
              </div>

              <Drawer
                size={450}
                // duration={500}
                open={checkBoxStage}
                onClose={() => setCheckBoxStage("")}
                direction="right"
                className="bla bla bla"
                overlayColor="#FFF"
                overlayOpacity={0}
              >
                <div className={classNames.navRightBox}>
                  {loadingdata ? (
                    <img
                      className={classNames.loadingAnimation}
                      src={Loading}
                    />
                  ) : (
                    <>
                      {/*  */}
                      {checkBoxStage === "staff" && roleType === "staff" && (
                        <div className="drowpside-right">
                          {/* <div
                          style={{
                            position: "absolute",
                            right: "1rem"
                          }}
                          onClick={() => {
                            setActionBtns([]);
                            setCheckBoxStage("");
                          }}
                        >
                          X
                        </div> */}
                          <div
                            className={classNames.eachFilterItem}
                            style={{
                              fontWeight: "700",
                              pointerEvents: "none",

                              fontSize: "20px",
                            }}
                          >
                            Add new staff
                          </div>
                          <div className={classNames.formFields}>
                            <div
                              style={{
                                marginTop: "2rem",
                                fontSize: "0.9rem",
                                marginBottom: "0.8rem",
                                fontWeight: "700",
                              }}
                            >
                              Basic Information
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "0.8rem",
                              }}
                            >
                              <div>
                                <MRsEachCustomDropdown
                                  dropdown={["Mr", "Mrs", "Ms", "Dr", "Prof"]}
                                  name="gendersignature"
                                  title="Employment typekl"
                                  stateValue={staffdata}
                                  setState={setstaffdata}
                                  // topTitle="true"
                                  type="single"
                                />
                              </div>
                              <MarignEachInput
                                title="cutom?"
                                name="name"
                                placeholder="Name..."
                                stateValue={staffdata}
                                setState={setstaffdata}
                                //  topTitle="true"
                              />
                            </div>
                            <div
                              style={{
                                marginBottom: "0.8rem",
                              }}
                            >
                              <StaffEachInputDatePicker
                                title="Select birthday"
                                placeholder="Birthday"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                name="Birthday"
                                // topTitle="true"
                              />
                            </div>

                            <div
                              style={{
                                position: "relative",
                                marginBottom: "0.8rem",
                                width: "100%",
                              }}
                            >
                              <MarignEachInput
                                title="Enter the affiliate’s email"
                                name="username"
                                placeholder="Username..."
                                stateValue={staffdata}
                                setState={setstaffdata}
                                // topTitle="true"
                              />
                              <div
                                //onClick={cmailName}
                                style={{
                                  width: "109px",
                                  height: "32px",
                                  top: "7px",
                                  borderRadius: "5px",
                                  //  border: "0.5px solid #C57E50",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "absolute",
                                  right: "10px",
                                  color: "#ffff",
                                  background: checkName
                                    ? "linear-gradient(270deg, #F04E8C 0%, #F8BA4E 103.98%)"
                                    : "linear-gradient(270deg, #F04E8C 0%, #F8BA4E 103.98%)",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                }}
                              >
                                {checkName ? "Available" : "Not Available"}
                              </div>
                            </div>

                            <div
                              style={{
                                // marginTop: "2rem",
                                marginBottom: "2rem",
                              }}
                            >
                              <EachCustomSelectMultiples
                                title="What is the injection method?"
                                name="gendertype"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                //   topTitle="true"
                                mapItems={["Male", "Female"]}
                              />
                            </div>

                            <div
                              style={{
                                position: "relative",
                                marginBottom: "0.8rem",
                                width: "100%",
                              }}
                            >
                              <MarignEachInput
                                title="Contact information"
                                name="affilatemail"
                                placeholder="Email..."
                                stateValue={staffdata}
                                setState={setstaffdata}
                                topTitle="true"
                              />
                              <div
                                //onClick={cmailName}
                                style={{
                                  width: "109px",
                                  height: "32px",
                                  top: "40px",
                                  borderRadius: "5px",
                                  //  border: "0.5px solid #C57E50",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "absolute",
                                  right: "10px",
                                  color: "#ffff",
                                  background: checkemail
                                    ? "linear-gradient(270deg, #F04E8C 0%, #F8BA4E 103.98%)"
                                    : "linear-gradient(270deg, #F04E8C 0%, #F8BA4E 103.98%)",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                }}
                              >
                                {checkemail ? "Available" : "Not Available"}
                              </div>
                            </div>
                            <div
                              style={{
                                marginBottom: "2rem",
                              }}
                            >
                              <MarignEachInput
                                title="What is the employee’s phone number?"
                                name="phoneNumber"
                                placeholder="Number..."
                                stateValue={staffdata}
                                setState={setstaffdata}
                                //topTitle="true"
                              />
                            </div>

                            <div
                              style={{
                                marginBottom: "0.8rem",
                                marginTop: "2rem",
                              }}
                            >
                              <MarignEachInput
                                title="Address"
                                name="address"
                                placeholder="Street"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                topTitle="true"
                              />
                            </div>
                            <div
                              style={{
                                marginBottom: "0.8rem",
                              }}
                            >
                              <MarignEachInput
                                title="What is the employees pincode?"
                                name="City"
                                placeholder="City"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                //  topTitle="true"
                              />
                            </div>
                            <div
                              style={{
                                marginBottom: "0.8rem",
                              }}
                            >
                              <MarignEachInput
                                title="What is the employees state?"
                                name="state"
                                placeholder="State"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                // topTitle="true"
                              />
                            </div>
                            <div
                              style={{
                                marginBottom: "0.8rem",
                              }}
                            >
                              <MarignEachInput
                                title="What is the employees pincode?"
                                name="picode"
                                placeholder="Pincode"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                //  topTitle="true"
                              />
                            </div>
                            <div
                              style={{
                                marginBottom: "2rem",
                              }}
                            >
                              <EachCustomDropdownSearch
                                dropdown={coundata}
                                title="Country"
                                name="country"
                                placeholder="Country"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                // topTitle="true"

                                type="obj"
                              />
                            </div>

                            <div
                              style={{
                                marginBottom: "0.8rem",
                                marginTop: "2rem",
                              }}
                            >
                              <MarignEachInput
                                title="Employment information"
                                name="employeeId"
                                placeholder="Employee id"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                topTitle="true"
                              />
                            </div>

                            <div
                              style={{
                                marginBottom: "0.8rem",
                              }}
                            >
                              <EachCustomDropdown
                                dropdown={[
                                  "Permanent",
                                  "On Contract",
                                  "Temporary",
                                  "Trainee",
                                ]}
                                name="employeeType"
                                title="Employment type"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                // topTitle="true"
                                type="single"
                              />
                            </div>
                            <div
                              style={{
                                marginBottom: "0.8rem",
                              }}
                            >
                              <EachCustomDropdown
                                dropdown={[
                                  "Active",
                                  "Terminated",
                                  "Resigned",
                                  "Deceased",
                                  "Long term leave",
                                ]}
                                name="employeeStatus"
                                title="Employment status"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                ///  topTitle="true"
                                type="single"
                              />
                            </div>

                            <div
                              style={{
                                marginBottom: "0.8rem",
                              }}
                            >
                              <EachCustomDropdownSearch
                                dropdown={roledrop}
                                name="staffrole"
                                title="Staff role"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                ///  topTitle="true"
                                type="single"
                              />
                            </div>

                            <div
                              style={{
                                marginBottom: "0.8rem",
                              }}
                            >
                              <EachCustomDropdownSearch
                                dropdown={allLocations}
                                name="locations"
                                title="Location"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                ///  topTitle="true"
                                type="single"
                              />
                            </div>

                            <div
                              style={{
                                marginBottom: "0.8rem",
                              }}
                            >
                              <EachCustomDropdownSearch
                                dropdown={calndertemp}
                                name="calendartemplate"
                                title="Calendar template"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                ///  topTitle="true"
                                type="single"
                              />
                            </div>

                            <div
                              style={{
                                marginBottom: "2rem",
                              }}
                            >
                              <StaffEachInputDatePicker
                                title="Select birthday"
                                placeholder="Date of joining"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                name="Dateofjoining"
                                // topTitle="true"
                              />
                            </div>

                            <div
                              className={classNames.flexContainer}
                              style={{
                                width: "150px",
                                marginBottom: "0.8rem",
                              }}
                            >
                              <MyEachCustomImageUpload
                                title="Upload"
                                name="photo"
                                placeholder="Profile picture "
                                stateValue={staffdata}
                                setState={setstaffdata}
                                topTitle="true"
                              />
                            </div>
                            <div
                              className={classNames.flexContainer}
                              style={{
                                width: "150px",
                              }}
                            >
                              <MyEachCustomImageUpload
                                title="Upload"
                                name="govid"
                                placeholder="Gov ID"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                //topTitle="true"
                              />
                            </div>
                            <div
                              style={{
                                marginTop: "2rem",
                                marginBottom: "0.8rem",
                              }}
                            >
                              <EachCustomSelectMultiples
                                title="Register For Accountants.io?"
                                name="specialistToggle"
                                stateValue={staffdata}
                                setState={setstaffdata}
                                topTitle="true"
                                mapItems={["Yes", "No"]}
                              />
                            </div>

                            {staffdata?.specialistToggle?.[0] == "Yes" && (
                              <>
                                <div
                                  style={{
                                    marginBottom: "0rem",
                                  }}
                                >
                                  {staffdata?.specialistIds?.map((item, i) => {
                                    return (
                                      <MultiDropdownNestedService
                                        // dropdown={sepcialdata }

                                        dropdown={sepcialdata.filter(function (
                                          cv
                                        ) {
                                          return !staffdata?.specialistIds.find(
                                            function (e) {
                                              return (
                                                e.id == cv.consultationserviceid
                                              );
                                            }
                                          );
                                        })}
                                        //   {sepcialdata.filter((item,i) => item?.specialistid !== offeringItem?.specialistIds[i]?.id)}
                                        key={i}
                                        indexx={i}
                                        name="specialistIds"
                                        title="Which accountants will offer this therapy?"
                                        stateValue={staffdata}
                                        setState={setstaffdata}
                                        // stateValue={services}
                                        // setState={setservices}
                                        ///  topTitle="true"
                                      />
                                    );
                                  })}
                                </div>

                                <div
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    textAlign: "left",
                                    textDecoration: "underline",
                                    marginBottom: "0rem",
                                  }}
                                  onClick={addmorespicalid}
                                >
                                  Add Another Service
                                </div>
                              </>
                            )}

                            <button
                              className={classNames.submitBtn}
                              onClick={submitstaff}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                      {/*  */}

                      {checkBoxStage === "calendar" &&
                      roleType === "calendar" ? (
                        <div className="drowpside-right">
                          <div
                            style={{
                              position: "absolute",
                              right: "1rem",
                            }}
                            className={classNames.closeBtn}
                            onClick={() => {
                              setActionBtns([]);
                              setCheckBoxStage("");
                            }}
                          >
                            X
                          </div>
                          <div
                            className={classNames.eachFilterItem}
                            style={{ fontWeight: "500", pointerEvents: "none" }}
                          >
                            Add New
                          </div>
                          <div
                            className={classNames.eachFilterItem}
                            key={"Calendar template"}
                            onClick={() => {
                              setCheckBoxStage("calendarTemplateForm");
                            }}
                          >
                            Calendar template
                          </div>
                        </div>
                      ) : checkBoxStage === "calendarTemplateForm" &&
                        roleType === "calendar" ? (
                        <div className="drowpside-right">
                          {/* <div
                      style={{
                        position: "absolute",
                        right: "1rem"
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setActionBtns([]);
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div> */}
                          <div
                            className={classNames.eachFilterItem}
                            style={{
                              fontWeight: "500",
                              pointerEvents: "none",
                              paddingLeft: "0",
                              marginBottom: "2rem",
                            }}
                          >
                            Add new calendar
                          </div>
                          <div className={classNames.formFields}>
                            <EachCustomDropdownSearch
                              dropdown={allTimezones}
                              name="TimeZone"
                              title="Select the timezone"
                              stateValue={calendarForm}
                              setState={setCalendarForm}
                              topTitle="true"
                              type="obj"
                              mapVal={{
                                name: "StandardTimeZoneName",
                                name1: "Abbreviation",
                              }}
                            />
                            <EachInputsepfic
                              title="What do you want to name this calendar?"
                              name="CalendarName"
                              placeholder="Name..."
                              stateValue={calendarForm}
                              setState={setCalendarForm}
                              topTitle="true"
                            />
                            <EachCustomTextarea
                              title="Describe the calendar"
                              name="CalendarDescription"
                              placeholder="Description..."
                              stateValue={calendarForm}
                              setState={setCalendarForm}
                              topTitle="true"
                            />

                            <div
                              className={classNames.customSeperationBox}
                              style={{
                                marginBottom: "0px",
                              }}
                            >
                              <div className={classNames.title}>
                                Add annual holidays
                              </div>
                              {calendarForm?.AnnualHolidays?.map(
                                (eachitem, index) => {
                                  return (
                                    <div
                                      className={classNames.flexBoxouter}
                                      key={"annualHoliday" + index}
                                    >
                                      <div className={classNames.longDiv}>
                                        <EachCustomDropdown
                                          dropdown={monthsArray}
                                          style={{
                                            marginBottom: "0rem",
                                          }}
                                          name="AnnualHolidays"
                                          title="Month"
                                          stateValue={calendarForm}
                                          setState={setCalendarForm}
                                          type="twoFields"
                                          indexx={index}
                                          calendarObjField="month"
                                          eachValuee={eachitem}
                                        />
                                      </div>
                                      <div className={classNames.normalDiv}>
                                        <EachCustomDropdownDate
                                          dropdown={datesArraylist}
                                          name="AnnualHolidays"
                                          title="Date"
                                          stateValue={calendarForm}
                                          setState={setCalendarForm}
                                          type="twoFields"
                                          indexx={index}
                                          calendarObjField="date"
                                          eachValuee={eachitem}
                                          setselectedindex={setselectedindex}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>

                            <div className={classNames.customSeperationBox}>
                              <div
                                className={classNames.title}
                                onClick={addmore}
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  marginTop: "10px",
                                }}
                              >
                                Add another holidays
                              </div>
                            </div>

                            <div className={classNames.customSeperationBox}>
                              <div className={classNames.title}>
                                Define weekly working hours
                              </div>
                              {calendarForm?.WorkingDays?.map(
                                (eachDay, index) => {
                                  return (
                                    <div
                                      className={classNames.flexBoxouter}
                                      key={eachDay + index}
                                    >
                                      <div className={classNames.titleDiv}>
                                        {eachDay?.Day}
                                      </div>
                                      <div className={classNames.normalDiv}>
                                        <EachCustomDropdown
                                          dropdown={amArray}
                                          name="WorkingDays"
                                          title="Start"
                                          stateValue={calendarForm}
                                          setState={setCalendarForm}
                                          type="twoFields"
                                          indexx={index}
                                          calendarObjField="StartTime"
                                          eachValuee={eachDay}
                                        />
                                      </div>
                                      <div className={classNames.normalDiv}>
                                        <EachCustomDropdown
                                          dropdown={pmArray}
                                          name="WorkingDays"
                                          title="End"
                                          stateValue={calendarForm}
                                          setState={setCalendarForm}
                                          type="twoFields"
                                          indexx={index}
                                          calendarObjField="EndTime"
                                          eachValuee={eachDay}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <button
                              className={classNames.submitBtn}
                              onClick={addCalendarForm}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      ) : checkBoxStage === "staffRoles" &&
                        roleType === "staffroles" ? (
                        <div className="drowpside-right">
                          <div
                            style={{
                              position: "absolute",
                              right: "1rem",
                            }}
                            className={classNames.closeBtn}
                            onClick={() => {
                              setActionBtns([]);
                              setCheckBoxStage("");
                            }}
                          >
                            X
                          </div>
                          <div
                            className={classNames.eachFilterItem}
                            style={{ fontWeight: "500", pointerEvents: "none" }}
                          >
                            Add New
                          </div>
                          <div
                            className={classNames.eachFilterItem}
                            key={"Staff Roles"}
                            onClick={() => {
                              setCheckBoxStage("staffRolesForm");
                            }}
                          >
                            Staff Role
                          </div>
                        </div>
                      ) : checkBoxStage === "staffRolesForm" &&
                        roleType === "staffroles" ? (
                        <div className="drowpside-right">
                          {/* <div
                      style={{
                        position: "absolute",
                        right: "1rem"
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setActionBtns([]);
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div> */}
                          <div
                            className={classNames.eachFilterItem}
                            style={{
                              fontWeight: "500",
                              pointerEvents: "none",
                              paddingLeft: "0",
                              marginBottom: "2rem",
                            }}
                          >
                            Add New Staff Role
                          </div>
                          <div className={classNames.formFields}>
                            {/* <EachCustomDropdown
                      dropdown={allOrganization}
                      name="teamUsername"
                      title="Select the organization"
                      stateValue={staffRolesForm}
                      setState={setStaffRolesForm}
                      topTitle="true"
                    /> */}
                            <EachInputsepfic
                              title="What do you want to name this role"
                              name="name"
                              placeholder="Name..."
                              stateValue={staffRolesForm}
                              setState={setStaffRolesForm}
                              topTitle="true"
                            />
                            <EachCustomTextarea
                              title="Describe the role"
                              name="description"
                              placeholder="Description..."
                              stateValue={staffRolesForm}
                              setState={setStaffRolesForm}
                              topTitle="true"
                            />

                            <EachCustomSelectMultiplelist
                              title="Add staff groups"
                              name="staffGroupIds"
                              stateValue={staffRolesForm}
                              setState={setStaffRolesForm}
                              topTitle="true"
                              mapItems={stafdropdown}
                            />
                            <button
                              className={classNames.submitBtn}
                              onClick={addStaffRolesForm}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      ) : checkBoxStage === "staffGroups" &&
                        roleType === "staffgroups" ? (
                        <div className="drowpside-right">
                          <div
                            style={{
                              position: "absolute",
                              right: "1rem",
                            }}
                            className={classNames.closeBtn}
                            onClick={() => {
                              setActionBtns([]);
                              setCheckBoxStage("");
                            }}
                          >
                            X
                          </div>
                          <div
                            className={classNames.eachFilterItem}
                            style={{ fontWeight: "500", pointerEvents: "none" }}
                          >
                            Add New
                          </div>
                          <div
                            className={classNames.eachFilterItem}
                            key={"Staff Group"}
                            onClick={() => {
                              setCheckBoxStage("staffGroupsForm");
                            }}
                          >
                            Staff Group
                          </div>
                        </div>
                      ) : checkBoxStage === "staffGroupsForm" &&
                        roleType === "staffgroups" ? (
                        <div className="drowpside-right">
                          {/* <div
                      style={{
                        position: "absolute",
                        right: "1rem"
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setActionBtns([]);
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div> */}
                          <div
                            className={classNames.eachFilterItem}
                            style={{
                              fontWeight: "500",
                              pointerEvents: "none",
                              paddingLeft: "0",
                              marginBottom: "2rem",
                            }}
                          >
                            Add New Staff Group
                          </div>
                          <div className={classNames.formFields}>
                            {/* <EachCustomDropdown
                      dropdown={allOrganization}
                      name="teamUsername"
                      title="Select the organization"
                      stateValue={staffGroupForm}
                      setState={setStaffGroupForm}
                      topTitle="true"
                    /> */}
                            {/* <EachCustomDropdown
                            dropdown={staffGroupFormGroupType}
                            name="groupType"
                            title="Select the group type"
                            stateValue={staffGroupForm}
                            setState={setStaffGroupForm}
                            topTitle="true"
                          /> */}
                            <EachInputsepfic
                              title="What do you want to name this group"
                              name="groupName"
                              placeholder="Name..."
                              stateValue={staffGroupForm}
                              setState={setStaffGroupForm}
                              topTitle="true"
                            />
                            <EachCustomTextarea
                              title="Describe the group"
                              name="groupDesc"
                              placeholder="Description..."
                              stateValue={staffGroupForm}
                              setState={setStaffGroupForm}
                              topTitle="true"
                            />
                            <EachCustomSelectMultiples
                              title="Add modules"
                              name="modules"
                              stateValue={staffGroupForm}
                              setState={setStaffGroupForm}
                              topTitle="true"
                              mapItems={staffGroupFormModules}
                            />
                            <button
                              className={classNames.submitBtn}
                              onClick={addStaffGroupForm}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </Drawer>
            </div>
          </div>
          <StaffTable
            globalSearchQuery={globalSearchQuery}
            roleType={roleType}
            setcalendarinfo={setcalendarinfo}
            setsendcalender={setsendcalender}
            seteditingToggle={seteditingToggle}
            setitemsend={setitemsend}
            sidebarOpen={sidebarOpen}
            allLeadsLoading={allLeadsLoading}
            allStaff={allLeads?.data?.length > 0 ? allLeads?.data : []}
            allHeading={
              roleType === "staff"
                ? ["Name", "Email", "Phone Number", "Gender", "Address"]
                : roleType === "staffgroups"
                ? ["Name", "Description", "Staff Roles", "Modules"]
                : roleType === "staffroles"
                ? ["Name", "Description", "Staff Groups", "# Of Staff"]
                : roleType === "calendar"
                ? ["Name", "Description", "Time Zone", "# Of Employees"]
                : []
            }
          />
        </div>
      )}

      {checkBoxStage === "staffGroupDrawer" && (
        <DrawerStaffing
          passingObj={drawerObject}
          handleClickOutside1={handleClickOutside1}
          clickRef={clickRef}
        />
      )}

      <Drawer
        size={450}
        // duration={500}
        open={calendardrawer}
        onClose={() => setcalendardrawer(false)}
        direction="right"
        className="bla bla bla"
        overlayColor="#FFF"
        overlayOpacity={0}
      >
        <div className="drowpside-right"></div>
        <div
          className="drowpside-right"
          style={{
            width: " 100%",
            height: "calc(100vh - 2rem)",
            overflowX: "hidden",
            overflowY: "scroll",
            padding: "2rem",
          }}
        >
          <div className={classNames.togglebuttonSection}>
            <div
              className={classNames.toggledata}
              onClick={() => setswtchtoggle("Data")}
              style={{
                background: swtchtoggle == "Data" ? "#C57E50" : "#fff",
                color: swtchtoggle == "Data" ? "#fff" : "#5F6163",
              }}
            >
              Data
            </div>
            <div
              className={classNames.toggleaction}
              onClick={() => setswtchtoggle("Actions")}
              style={{
                background: swtchtoggle == "Actions" ? "#C57E50" : "#fff",
                color: swtchtoggle == "Actions" ? "#fff" : "#5F6163",
              }}
            >
              Actions
            </div>
          </div>
          {swtchtoggle == "Actions" && (
            <>
              <div className={classNames.deleteCalendarbox}>
                Delete calendar
              </div>
            </>
          )}

          {swtchtoggle == "Data" && (
            <>
              <div
                className={classNames.eachFilterItem}
                style={{
                  fontWeight: "500",
                  pointerEvents: "none",
                  paddingLeft: "0",
                  marginBottom: "2rem",

                  fontSize: "20px",
                }}
              >
                {calendarinfo?.CalendarName}
              </div>
              <div
                className={classNames.eachFilterItem}
                style={{
                  // fontWeight: "500",
                  pointerEvents: "none",
                  paddingLeft: "0",
                  marginBottom: "2rem",

                  fontSize: "12px",
                }}
              >
                {calendarinfo?._id}
              </div>
              <div className={classNames.formFields}>
                {/* <EachInputsepfic
                      title="What do you want to name this calendar?"
                      name="CalendarName"
                      placeholder="Name..."
                      stateValue={calendarForm}
                      setState={setCalendarForm}
                      topTitle="true"
                    /> */}
                <EachCustomTextarea
                  title="Description"
                  name="CalendarDescription"
                  placeholder="Description..."
                  stateValue={calendarinfo}
                  // setState={setCalendarForm}
                  topTitle="true"
                />
                <div
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  <EachInput
                    title="Time zone"
                    name="TimeZone"
                    placeholder=""
                    stateValue={calendarinfo}
                    // setState={setStaffRolesForm}
                    topTitle="true"
                  />
                </div>

                <div className={classNames.customSeperationBox}>
                  <div
                    className={classNames.flexBoxouter}
                    style={{
                      marginTop: "2rem",
                    }}
                  >
                    <div className={classNames.titleDiv}>Holidays</div>

                    {calendarinfo?.AnnualHolidays?.map((item) => {
                      return (
                        <EachInput
                          title=""
                          // name={calendarinfo?.WorkingDays[index]}
                          placeholder=""
                          stateValue={`${item?.month} ${item?.date}`}
                          // setState={setStaffRolesForm}
                          topTitle="true"
                        />
                      );
                    })}
                  </div>
                </div>

                <div className={classNames.customSeperationBox}>
                  <div
                    className={classNames.flexBoxouter}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "self-start",
                      gap: "1rem",
                      marginTop: "2rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className={classNames.titleDiv}
                      style={{
                        width: "7.5rem",
                        display: "flex",
                        alignItems: "self-start",
                        gap: "1rem",
                      }}
                    >
                      Day
                    </div>
                    <div
                      className={classNames.titleDiv}
                      style={{
                        width: "7.5rem",
                        display: "flex",
                        alignItems: "self-start",
                        gap: "1rem",
                      }}
                    >
                      Start Time
                    </div>
                    <div
                      className={classNames.titleDiv}
                      style={{
                        width: "7.5rem",
                        display: "flex",
                        alignItems: "self-start",
                        gap: "1rem",
                      }}
                    >
                      End Time
                    </div>
                  </div>
                  {calendarinfo?.WorkingDays?.map((eachDay, index) => {
                    return (
                      <div
                        className={classNames.flexBoxouter}
                        key={eachDay + index}
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "self-start",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <div
                          className={classNames.normalDiv}
                          style={{
                            width: "7rem",
                          }}
                        >
                          <EachInputDummy
                            title=""
                            // name={calendarinfo?.WorkingDays[index]}
                            placeholder=""
                            stateValue={eachDay?.Day}
                            // setState={setStaffRolesForm}
                            topTitle="true"
                          />
                          {/* <EachInput
                        
                        name="WorkingDays"
                        title="Start"
                        stateValue={calendarForm}
                        // setState={setCalendarForm}
                        type="twoFields"
                        indexx={index}
                        calendarObjField="StartTime"
                        eachValuee={eachDay}
                      /> */}
                        </div>
                        <div
                          className={classNames.normalDiv}
                          style={{
                            width: "7rem",
                          }}
                        >
                          <EachInputDummy
                            title=""
                            // name={calendarinfo?.WorkingDays[index]}
                            placeholder=""
                            stateValue={
                              eachDay?.StartTime ? eachDay?.StartTime : "NA"
                            }
                            // setState={setStaffRolesForm}
                            topTitle="true"
                          />
                          {/* <EachInput
                        dropdown={pmArray}
                        name="WorkingDays"
                        title="End"
                        stateValue={calendarForm}
                        // setState={setCalendarForm}
                        type="twoFields"
                        indexx={index}
                        calendarObjField="EndTime"
                        eachValuee={eachDay}
                      /> */}
                        </div>

                        <div
                          className={classNames.normalDiv}
                          style={{
                            width: "7rem",
                          }}
                        >
                          <EachInputDummy
                            title=""
                            // name={calendarinfo?.WorkingDays[index]}
                            placeholder=""
                            stateValue={
                              eachDay?.EndTime ? eachDay?.EndTime : "NA"
                            }
                            // setState={setStaffRolesForm}
                            topTitle="true"
                          />
                          {/* <EachInput
                        dropdown={pmArray}
                        name="WorkingDays"
                        title="End"
                        stateValue={calendarForm}
                        // setState={setCalendarForm}
                        type="twoFields"
                        indexx={index}
                        calendarObjField="EndTime"
                        eachValuee={eachDay}
                      /> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>

        {/* <BookingDrawer
          setchangestatus={setchangestatus}
          changeStatusfucntion={changeStatusfucntion}
          bookingItem={bookingItem}
          bookingDrawer={bookingDrawer}

        /> */}
      </Drawer>
    </>
  );
};

export default Staffing;

const DrawerStaffing = ({ passingObj, clickRef, handleClickOutside1 }) => {
  console.log("passingObj", passingObj);
  return (
    <div className={classNames.drawerStaffing} ref={clickRef}>
      <div
        className={classNames.title}
        style={{
          marginBottom: "0px",
        }}
      >
        {passingObj?.groupName}
      </div>
      <div
        className={classNames.subTitle}
        style={{
          marginBottom: "12px",
        }}
      >
        {passingObj?._id}
      </div>
      <div className={classNames.formFields}>
        <EachCustomTextarea
          title="Description"
          name="groupDesc"
          placeholder="Description..."
          stateValue={passingObj}
          // setState={setStaffGroupForm}
          topTitle="true"
        />
        <EachInput
          title="Group type"
          name="groupType"
          placeholder="Group Type..."
          stateValue={passingObj}
          // setState={setStaffRolesForm}
          topTitle="true"
        />
        <EachInputArray
          title="Modules"
          name="modules"
          placeholder="Modules..."
          stateValue={passingObj}
          // setState={setStaffRolesForm}
          topTitle="true"
        />
        <EachInputArray
          title="Staff roles"
          name="StaffRoleDetails"
          placeholder="Modules..."
          stateValue={passingObj}
          // setState={setStaffRolesForm}
          topTitle="true"
          type="obj"
          objName="name"
        />
      </div>
    </div>
  );
};
