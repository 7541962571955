import React, { useEffect, useState, useContext } from "react";
import classNames from "./pipelinesubmenu.module.scss";
import "./Custom.scss";
//assets
import { FaCaretDown } from "react-icons/fa";
import perscribeIcon from "../../../assets/images/icons/perscribe.svg";
import axios from "axios";
import { toastify } from "../../../assets/functions/toastify";
import { faVolumeControlPhone } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../../context/Globalcontext";
import { useNavigate, useParams } from "react-router-dom";
const PipelineSubmenu = ({ stateValue, setState }) => {
  //constants
  const allSubMenu = ["Pre-Assessment Form", "Client Profile Forms", "Widgets"];
  const { id } = useParams();
  const history = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState("Pre-Assessment Form");
  const [sessionValue, setSessionValue] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [localRefresh, setLocalRefresh] = useState(false);

  //functions
  const [dop, setdop] = useState(null);
  const [widgenumber, setwidgenumber] = useState(null);
  const [showdrop, setshowdrop] = useState(false);
  const [isToggleOn, setisToggleOn] = useState(false);
  const [wedgedata, setwedgedata] = useState([]);
  const [wedgetoggle, setwedgetoggle] = useState([
    {
      toggle: false,
    },
  ]);

  const [questionslist, setquestionslist] = useState([]);
  const {
    email,
    actionBtns,
    setActionBtns,
    setGlobalSearchQuery,
    dropDowndownvalue,
    globalSearchQuery,
    editingToggle,
    seteditingToggle,
  } = useContext(GlobalContext);
  const [datalist, setdatalist] = useState([
    {
      name: "",
      questionslist: "",
      anstype: "",
      requried: true,
      toggle: false,
      questionlist: "",
    },
  ]);
  console.log("datalist", datalist);
  // const dropdownaddedpreform = (index, item, tyep) => {
  //   let data = [...preform];
  //   data[index].anstype = tyep;
  //   data[index].name = item;

  //   setpreform(data);
  //   setshowdrop(false);
  // };
  const dropdownadded = (index, item, tyep) => {
    let data = [...datalist];
    data[index].anstype = tyep;
    data[index].name = item;

    setdatalist(data);
    setshowdrop(false);
  };

  const handleFormChange = (index, event, g, s) => {
    let data = [...datalist];
    data[index].anstype = g;
    data[index].questionlist = s;
    data[index][event.target.name] = event.target.value;
    setdatalist(data);
  };
  const selected = (e, g) => {
    addFields(e?.questions);
    setwidgenumber(g);
  };
  const addFields = (e) => {
    let newfield = { name: "", anstype: "", requried: true };

    let pop = e?.map((item, i) => {
      return {
        name: "",
        anstype: "",
        questionslist: "",
        requried: item?.isMandatory,
        toggle: false,
      };
    });
    console.log("pop", pop);
    setdatalist(pop);
  };
  useEffect(() => {}, []);
  const tablefucntion1 = (e) => {
    console.log("edfsdsf", e);
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getwidget?specialistid=${e}`
      )
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        if (res.data?.success) {
          setwedgedata(res?.data?.data);
          let pop = res?.data?.data?.map((item, i) => {
            return {
              toggle: false,
            };
          });
        } else {
          setwedgetoggle([
            {
              toggle: false,
            },
          ]);
          setwedgedata([]);
        }
      });
  };
  console.log("stateValue234234", stateValue);

  async function getDetailsOneSession() {
    try {
      let response = await axios.get(
        `https://finacebackend.marketsverse.com/api/getconsultsessions?consultationsessionid=${id}`
      );
      let res = response?.data?.data;
      if (res?.length > 0) {
        setSessionValue(res[0]);
        tablefucntion1(res[0]?.specialistid);
      }
      console.log(res, "sessionValue");
    } catch (error) {
      console.log(error?.message, "Get details one session error");
    }
  }

  async function updateStatusConsultationSession(updateStatus) {
    try {
      let response = await axios.put(
        `https://finacebackend.marketsverse.com/api/updateconsultsession/${sessionValue?._id}`,
        {
          status: updateStatus,
        }
      );
      if (response?.data?.success) {
        toastify(response?.data?.message, "success");
      } else {
        toastify(response?.data?.message, "error");
      }
      setShowDropdown(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.log(error?.message, "updateStatusConsultationSession error");
    }
  }

  //renderings
  const wedgetogglefunction = (index) => {
    let data = [...wedgetoggle];
    data[index].toggle = !data[index].toggle;

    setwedgetoggle(data);
  };
  useEffect(() => {
    getDetailsOneSession();
  }, []);

  return (
    <div className={classNames.pipelineSubmenu}>
      <div className={classNames.mainContainer}>
        <div className={classNames.headerContainer}>
          <div className="profileTop">
            <div
              className={classNames.profileName}
              style={{
                paddingBottom: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                onClick={() => history("/owner/services/sessions")}
              >
                <circle
                  cx="16.5"
                  cy="16.5"
                  r="16"
                  fill="white"
                  stroke="#E9EBF3"
                />
                <path
                  d="M10.4497 16.8516L16.171 22.535C16.2768 22.64 16.4253 22.7 16.5808 22.7H19.0393C19.5282 22.7 19.7821 22.1619 19.4491 21.8314L14.0779 16.5L19.4491 11.1686C19.7821 10.8381 19.5282 10.3 19.0393 10.3H16.5808C16.4253 10.3 16.2768 10.3596 16.171 10.465L10.4497 16.1484C10.2502 16.3467 10.2502 16.6533 10.4497 16.8516Z"
                  fill="url(#paint0_linear_1918_382)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1918_382"
                    x1="19.6001"
                    y1="16.5"
                    x2="10.3001"
                    y2="16.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#191F41 " />
                  </linearGradient>
                </defs>
              </svg>

              <div style={{ marginLeft: "15px" }}>
                <span style={{ fontSize: "1.35rem", fontWeight: 550 }}>
                  {sessionValue?.clientdetails?.length > 0 &&
                  sessionValue?.clientdetails[0]?.name
                    ? sessionValue?.clientdetails[0]?.name
                    : ""}
                </span>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "6px",
                  }}
                >
                  {sessionValue?.consultationsessionid}
                </div>
              </div>
            </div>
            <div className={classNames.actionBtns}>
              <div className={classNames.statusBtn}>
                <div>Session Status</div>
                <div>|</div>
                <div>
                  <div onClick={() => setShowDropdown((prev) => !prev)}>
                    <span>
                      {sessionValue?.status?.charAt(0).toUpperCase() +
                        sessionValue?.status?.slice(1)}
                    </span>
                    <FaCaretDown />
                  </div>
                  {showDropdown && (
                    <div className={classNames.dropdownList}>
                      <div
                        className={classNames.dropdownItems}
                        onClick={() => {
                          updateStatusConsultationSession(
                            sessionValue?.status?.toLowerCase() === "open"
                              ? "close"
                              : "open"
                          );
                        }}
                      >
                        {sessionValue?.status?.toLowerCase() === "open"
                          ? "Close"
                          : "Open"}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="deletedEditingButton"
                style={{ gap: "0.5rem" }}
                onClick={() => setSelectedMenu("Perscribe")}
              >
                <img
                  src={perscribeIcon}
                  alt="perscribeIcon"
                  style={{ height: "1.2rem" }}
                />
                Perscribe
              </div>
            </div>
          </div>
        </div>
        <div className={classNames.contentContainer}>
          <div className="profiletab">
            {allSubMenu?.map((eachItem, index) => {
              return (
                <div
                  key={eachItem + index}
                  className="items"
                  style={{
                    background:
                      selectedMenu === "Client Profile Forms Questions" &&
                      eachItem === "Client Profile Forms"
                        ? "#3f506a"
                        : selectedMenu === eachItem
                        ? "#3f506a"
                        : "",
                  }}
                  onClick={() => {
                    if (selectedMenu === "Client Profile Forms Questions") {
                      setSelectedMenu("Client Profile Forms");
                    } else {
                      setSelectedMenu(eachItem);
                    }
                  }}
                >
                  {eachItem}
                </div>
              );
            })}
          </div>
          {selectedMenu === "Perscribe" ? (
            <Perscribe
              sessionValue={sessionValue}
              globalSearchQuery={globalSearchQuery}
              setGlobalSearchQuery={setGlobalSearchQuery}
            />
          ) : selectedMenu === "Pre-Assessment Form" ? (
            <PreEnrollmentForm
              localRefresh={localRefresh}
              sessionValue={sessionValue}
            />
          ) : selectedMenu === "Client Profile Forms" ||
            selectedMenu === "Client Profile Forms Questions" ? (
            <PatientProfileForm
              localRefresh={localRefresh}
              sessionValue={sessionValue}
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
            />
          ) : (
            <div className="innerSectionSession">
              {/* <div className="buttonsave">
                            <div>Save</div>
                        </div> */}
              {wedgedata?.map((such, i) => {
                // console.log("iten34535", item)

                return (
                  <>
                    {widgenumber == i ? (
                      <div className="widgetparent">
                        <div
                          className="namewidget"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => setwidgenumber(null)}
                        >
                          {such?.name}

                          <svg
                            onClick={() => setwidgenumber(null)}
                            xmlns="http://www.w3.org/2000/svg"
                            width="7"
                            height="6"
                            viewBox="0 0 7 6"
                            fill="none"
                          >
                            <path
                              d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                              fill="#5A5656"
                            />
                          </svg>
                        </div>

                        {wedgetoggle[i]?.toggle ? (
                          ""
                        ) : (
                          <>
                            {such?.questions?.map((item, j) => {
                              if (
                                item?.answerType?.toLowerCase() == "dropdown"
                              ) {
                                return (
                                  <div className="questionsection">
                                    <div className="queTitle">{item?.name}</div>
                                    <div
                                      className="queTitledrop"
                                      onClick={() => {
                                        setshowdrop(!showdrop);
                                        setdop(j);
                                      }}
                                    >
                                      {datalist?.[j]?.name}
                                      {dop == j && showdrop ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="7"
                                          height="6"
                                          viewBox="0 0 7 6"
                                          fill="none"
                                        >
                                          <path
                                            d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                                            fill="#5A5656"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="7"
                                          height="6"
                                          viewBox="0 0 7 6"
                                          fill="none"
                                        >
                                          <path
                                            d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                                            fill="#5A5656"
                                          />
                                        </svg>
                                      )}
                                    </div>
                                    {dop == j && showdrop && (
                                      <div className="dropdonwlistdata">
                                        {item?.dropdownOptions?.map((item) => {
                                          return (
                                            <div
                                              onClick={() =>
                                                dropdownadded(
                                                  j,
                                                  item,
                                                  "dropdown"
                                                )
                                              }
                                            >
                                              {item}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="questionsection">
                                    <div className="queTitle">{item?.name}</div>
                                    <input
                                      className="queAnswerinput"
                                      name="name"
                                      value={datalist.name}
                                      onChange={(event) =>
                                        handleFormChange(
                                          j,
                                          event,
                                          "String",
                                          item?.name
                                        )
                                      }
                                    />
                                  </div>
                                );
                              }
                            })}
                          </>
                        )}

                        {/* {
                          wedgedata?.length > 0 &&

                          <div className="ToggleSwitch"
                           onClick={() => wedgetogglefunction(i)}
                          >
                            <div className={wedgetoggle[i]?.toggle ? 'knob active' : 'knob'} />
                            {wedgetoggle[i]?.toggle ? 'Existing Form' : 'New Form'}
                          </div>
                        } */}
                        <div className="saveSection">
                          <div>Save</div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="widgetparent"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => selected(such, i)}
                      >
                        <div className="namewidget">
                          {such?.name}

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7"
                            height="6"
                            viewBox="0 0 7 6"
                            fill="none"
                          >
                            <path
                              d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                              fill="#5A5656"
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PipelineSubmenu;

const PreEnrollmentForm = ({ localRefresh, sessionValue }) => {
  const [preAssessment, setPreAssessment] = useState("");

  //functions
  const { id } = useParams();
  async function getPreAssesment() {
    try {
      let response = await axios.get(
        `https://finacebackend.marketsverse.com/api/getuserassessment?consultationsessionid=${id}`
      );
      if (response?.data?.data?.length > 0) {
        setPreAssessment(response?.data?.data?.[0]);
      }
    } catch (error) {
      console.log(error, "Get pre dsadasd response");
    }
  }
  console.log("Get pre assesment response", id);
  //rendering
  useEffect(() => {
    getPreAssesment();
  }, [id]);

  return (
    <div className={classNames.preEnrollmentForm}>
      {preAssessment?.questions?.length > 0
        ? preAssessment?.questions?.map((eachQuestion, index) => {
            return (
              <div className={classNames.eachQuestion}>
                <div className={classNames.title}>{eachQuestion?.name}</div>
                <div className={classNames.answer}>{eachQuestion?.answer}</div>
              </div>
            );
          })
        : ""}
    </div>
  );
};

const Perscribe = ({
  sessionValue,
  globalSearchQuery,
  setGlobalSearchQuery,
}) => {
  const allPerscribeItems = [
    "Prescribe diet plan",
    "Prescribe treatment",
    "Prescribe medication",
    "Prescribe investigation",
    "Prescribe life style advice",
    "Prescribe note",
  ];
  const [storetrement, setstoretrement] = useState([]);
  const [option, setoption] = useState("main");
  const [catoriesSummary, setcatoriesSummary] = useState([]);

  useEffect(() => {
    setoption("main");
  }, []);

  const backfunction = () => {
    //setstoretrement([])
    setoption("main");
    setGlobalSearchQuery("");
  };

  const callinerlist = (e) => {
    if (e === "Prescribe treatment") {
      //  setstoretrement([])

      tablefucntion();
      setoption("Prescribe treatment");
      setrightsidedname("Prescribe treatment");
    } else if (e == "Prescribe note") {
      setrightsidedname("Prescribe note");
      setoption("Prescribe note");
    } else if (e === "Prescribe diet plan") {
      //  setstoretrement([])
      deitplanfunction();
      closeexpadingfunction();
      setrightsidedname("Prescribe diet plan");
      setoption("Prescribe diet plan");
    } else if (e === "Prescribe medication") {
      //setstoretrement([])
      setrightsidedname("Prescribe medication");
      // deitplanfunction()
      // closeexpadingfunction()
      setoption("Prescribe medication");
      setmedicationSelet({
        name: "",
        dosage: "",
        frequency: "",
        lengthOfTime: "",
      });
      //setstoreinfoMedicance([])
    } else if (e === "Prescribe investigation") {
      //  setstoretrement([])
      setrightsidedname("Prescribe investigation");
      // deitplanfunction()
      // closeexpadingfunction()
      setinvestingaData({
        name: "",
        lab: "",
        description: "",
      });
      //setstoreinfoMedicance([])
      setoption("Prescribe investigation");
    } else if (e === "Prescribe life style advice") {
      //   setstoretrement([])
      setrightsidedname("Prescribe life style advice");
      // deitplanfunction()
      // closeexpadingfunction()
      setmedicationSelet({
        dNname: "",
        dosage: "",
        Divtitle: "",
      });
      setoption("Prescribe life style advice");
      setstoreinfoMedicance([]);
    }
  };

  const deitplanfunction = () => {
    axios

      .get(
        `https://finacebackend.marketsverse.com/api/getdietplan?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        if (res?.data?.success) {
          setcatoriesSummary(res?.data?.data);
        } else {
          setcatoriesSummary([]);
        }
      });
  };
  const tablefucntion = () => {
    axios

      .get(
        `https://finacebackend.marketsverse.com/api/gettheraphy?teamusername=${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      )
      .then((res) => {
        if (res?.data?.success) {
          setcatoriesSummary(res?.data?.data);
        } else {
          setcatoriesSummary([]);
        }
      });
  };

  const chnagetreatment = (e, i) => {
    const newData = storetrement.map((group, z) => {
      if (z === i) {
        return {
          ...group,

          des: e.target.value,
        };
      }
      return group;
    });

    // let arr = [...storetrement]
    // arr[i] = {
    //   des: e.target.value
    // }
    setstoretrement(newData);
  };
  const [dietplan, setdietplan] = useState([]);
  const adddietpla = (e) => {
    console.log("zxczxczxc", e);
    let filteredArray = catoriesSummary.filter(
      (item) => item?.name !== e?.name
    );
    setcatoriesSummary(filteredArray);
    setdietplan([e]);
  };

  const additembackdiet = (e) => {
    setdietplan([...dietplan, e]);
    setcatoriesSummary([...catoriesSummary, e]);
    let filteredArray = dietplan.filter((item) => item?.name !== e?.name);
    // // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    // console.log("storetrement", filteredArray)
    setdietplan(filteredArray);
    // setcatoriesSummary(filteredArray)
  };

  console.log("dietplan", dietplan);

  const additem = (e) => {
    setstoretrement([...storetrement, e]);

    let filteredArray = catoriesSummary.filter(
      (item) => item?.name !== e?.name
    );

    setcatoriesSummary(filteredArray);
  };
  console.log("storetrement", storetrement);

  const additemback = (e) => {
    setcatoriesSummary([...catoriesSummary, e]);

    let filteredArray = storetrement.filter((item) => item?.name !== e?.name);
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    console.log("storetrement", filteredArray);
    setstoretrement(filteredArray);
  };

  const [rightsidedname, setrightsidedname] = useState("");

  const [expandable, setexpandable] = useState(false);
  const [samevalue, setsamevalue] = useState("");
  const expadingfunction = (e) => {
    setsamevalue(e?._id);
    setexpandable(true);
  };

  const closeexpadingfunction = () => {
    setsamevalue("");
    setexpandable(false);
  };

  //addnote
  const [addnote, setaddnote] = useState({
    description: "",
  });
  const [addnoteseleted, setaddnoteseleted] = useState([]);

  const addNotefucntion = async (e) => {
    await setaddnoteseleted([addnote]);
    //await setstoreMedience([...storeinfoMedicance, medicationSelet])
    await setaddnote({
      description: "",
    });
  };
  console.log("2342342342", addnoteseleted);
  const removenote = (e) => {
    setaddnoteseleted([...addnoteseleted, e]);

    let filteredArray = addnoteseleted.filter(
      (item) => item?.description !== e?.description
    );
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)

    setaddnoteseleted(filteredArray);
  };

  //lifestyle
  const [lifestyle, setlifestyle] = useState({
    name: "",
    description: "",
  });
  const [lifestyleseleted, setlifestyleseleted] = useState([]);
  const addlifestyle = async (e) => {
    await setlifestyleseleted([lifestyle, ...lifestyleseleted]);
    //await setstoreMedience([...storeinfoMedicance, medicationSelet])
    await setlifestyle({
      name: "",
      description: "",
    });
  };

  const removelifestyle = (e) => {
    setlifestyleseleted([...investingaSelected, e]);

    let filteredArray = investingaSelected.filter(
      (item) => item?.name !== e?.name
    );
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    console.log("storetrement", filteredArray);
    setlifestyleseleted(filteredArray);
  };

  // investigation

  const [investingaData, setinvestingaData] = useState({
    name: "",
    lab: "",
    description: "",
  });

  const [investingaSelected, setinvestingaSelected] = useState([]);

  const addinvest = async (e) => {
    await setinvestingaSelected([investingaData, ...investingaSelected]);
    //await setstoreMedience([...storeinfoMedicance, medicationSelet])
    await setinvestingaData({
      name: "",
      lab: "",
      description: "",
    });
  };

  const removeInvesting = (e) => {
    setinvestingaSelected([...investingaSelected, e]);

    let filteredArray = investingaSelected.filter(
      (item) => item?.name !== e?.name
    );
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    console.log("storetrement", filteredArray);
    setinvestingaSelected(filteredArray);
  };

  console.log("investingaSelected", investingaSelected);

  // medicance
  const [medicationSelet, setmedicationSelet] = useState({
    name: "",
    dosage: "",
    frequency: "",
    lengthOfTime: "",
  });

  const [storeinfoMedicance, setstoreinfoMedicance] = useState([]);

  const [storeMedience, setstoreMedience] = useState([]);

  const additempop = async (e) => {
    await setstoreinfoMedicance([medicationSelet, ...storeinfoMedicance]);
    await setmedicationSelet({
      name: "",
      dosage: "",
      frequency: "",
      lengthOfTime: "",
    });
  };

  const addMediance = async (e) => {
    await setstoreinfoMedicance([...storeinfoMedicance, medicationSelet]);
    //await setstoreMedience([...storeinfoMedicance, medicationSelet])
    await setmedicationSelet({
      name: "",
      dosage: "",
      frequency: "",
      lengthOfTime: "",
    });
  };

  const removemediance = (e) => {
    setstoreinfoMedicance([...storeinfoMedicance, e]);

    let filteredArray = storeinfoMedicance.filter(
      (item) => item?.name !== e?.name
    );
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    console.log("storetrement", filteredArray);
    setstoreinfoMedicance(filteredArray);
  };

  const additembackmedican = (e) => {
    setstoreinfoMedicance([...storeinfoMedicance, e]);

    let filteredArray = storeinfoMedicance.filter(
      (item) => item?.name !== e?.name
    );
    // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
    console.log("storetrement", filteredArray);
    setstoreinfoMedicance(filteredArray);
  };

  const savdmediciance = () => {
    let deitplan = dietplan?.map((item) => {
      return {
        name: item?.name,
        description: item?.description,
        numofdays: item?.numofdays,
        startingday: item?.startingday,
        plandetails: item?.plandetails,
      };
    });

    let lifystyle = lifestyleseleted?.map((item) => {
      return {
        name: item?.name,
        description: item?.description,
      };
    });

    let investingaSelecteddata = investingaSelected?.map((item) => {
      return {
        name: item?.name,

        lab: item?.lab,
        description: item?.description,
      };
    });

    let storeinfoMedicancedata = storeinfoMedicance?.map((item) => {
      return {
        name: item?.name,
        dosage: item?.dosage,
        frequency: item?.frequency,
        lengthOfTime: item?.lengthOfTime,
      };
    });
    let treatment = storetrement?.map((item) => {
      return {
        theraphyid: item?.theraphyId,
        details: item?.des,
      };
    });

    let deitplandata = deitplan?.map((item) => {
      return {
        name: item?.name,
        description: item?.description,
        numofdays: item?.numofdays,
        startingday: item?.startingday,
        plandetails: item?.plandetails?.map((such) => {
          return {
            day: such?.day,
            dayplan: such?.dayplan?.map((eaxh) => {
              return {
                kitchenitemid: eaxh?.kitchenitemid,
                unitname: eaxh?.unitname,
                unitamount: eaxh?.unitamount,
                time: eaxh?.time,
              };
            }),
          };
        }),
      };
    });
    let resut = {
      consultationsessionid: sessionValue?.consultationsessionid,
      clientid: sessionValue?.clientid,
      items: [...storeinfoMedicancedata],
      theraphyids: [...treatment],
      investigationList: [...investingaSelecteddata],
      lifestyleList: [...lifystyle],
      notes: addnoteseleted?.[0]?.description,
      dietplandetails: { ...deitplandata?.[0] },
    };

    axios
      .post(`https://finacebackend.marketsverse.com/api/addprescription`, {
        ...resut,
      })

      .then(({ data }) => {
        if (data.status) {
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        } else {
        }
      });

    console.log("medicationSelet", deitplandata);
  };

  // console.log("medicationSelet", storeinfoMedicance)

  const [dplandropdown, setdplandropdown] = useState(false);
  const [dietid, setdietid] = useState(false);
  return (
    <div className={classNames.perscribe}>
      <div className={classNames.leftside}>
        {option == "Prescribe medication" ? (
          <>
            <div className={classNames.title} onClick={backfunction}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12"
                  fill="white"
                  stroke="#E9EBF3"
                />
                <path
                  d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z"
                  fill="url(#paint0_linear_102_3785)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_3785"
                    x1="14.8001"
                    y1="12.4999"
                    x2="7.90003"
                    y2="12.4999"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#19376D" />
                  </linearGradient>
                </defs>
              </svg>
              Choose medication
            </div>

            <input
              className={classNames.draginput}
              placeholder="Drug name"
              value={medicationSelet.name}
              onChange={(e) =>
                setmedicationSelet({
                  name: e.target.value,
                  dosage: medicationSelet?.dosage,
                  frequency: medicationSelet.frequency,
                  lengthOfTime: medicationSelet.lengthOfTime,
                })
              }
            />
            <div className={classNames.selectdiv}>
              Or select from your organizations pharmacy
            </div>
            <input
              className={classNames.draginput}
              placeholder="Dosage"
              value={medicationSelet.dosage}
              onChange={(e) =>
                setmedicationSelet({
                  name: medicationSelet.name,
                  dosage: e.target.value,
                  frequency: medicationSelet.frequency,
                  lengthOfTime: medicationSelet.lengthOfTime,
                })
              }
            />

            <div className={classNames.tablesection}>
              <input
                style={{
                  width: "48%",
                }}
                className={classNames.draginput}
                placeholder="Frequency"
                value={medicationSelet.frequency}
                onChange={(e) =>
                  setmedicationSelet({
                    name: medicationSelet.name,
                    dosage: medicationSelet.dosage,
                    frequency: e.target.value,
                    lengthOfTime: medicationSelet.lengthOfTime,
                  })
                }
              />

              <input
                style={{
                  width: "48%",
                }}
                className={classNames.draginput}
                placeholder="Repetitions"
                value={medicationSelet.lengthOfTime}
                onChange={(e) =>
                  setmedicationSelet({
                    name: medicationSelet.name,
                    dosage: medicationSelet.dosage,
                    frequency: medicationSelet.frequency,
                    lengthOfTime: e.target.value,
                  })
                }
              />

              {/* <div
                  style={{
                    border: medicationSelet.Divtitle == "Frequency" ? "1px solid #0B2447" : "1px solid #E5E5E5"
                  }}
                  onClick={() => setmedicationSelet({
                    dNname: medicationSelet.dNname,
                    dosage: medicationSelet.dosage,
                    Divtitle: "Frequency"
                  })}
                >Frequency</div> */}
              {/* <div
                  style={{
                    border: medicationSelet.Divtitle == "Repetitions" ? "1px solid #0B2447" : "1px solid #E5E5E5"
                  }}
                  onClick={() => setmedicationSelet({
                    dNname: medicationSelet.dNname,
                    dosage: medicationSelet.dosage,
                    Divtitle: "Repetitions"
                  })}
                >Repetitions </div> */}
            </div>
            <div className={classNames.Addto} onClick={addMediance}>
              Add to perscription
            </div>
          </>
        ) : option == "Prescribe life style advice" ? (
          <>
            <div className={classNames.title} onClick={backfunction}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12"
                  fill="white"
                  stroke="#E9EBF3"
                />
                <path
                  d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z"
                  fill="url(#paint0_linear_102_3785)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_3785"
                    x1="14.8001"
                    y1="12.4999"
                    x2="7.90003"
                    y2="12.4999"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#19376D" />
                  </linearGradient>
                </defs>
              </svg>
              Choose lifestyle advice
            </div>

            <input
              className={classNames.draginput}
              placeholder="Name"
              style={{
                marginBottom: "1rem",
              }}
              value={lifestyle.name}
              onChange={(e) =>
                setlifestyle({
                  name: e.target.value,
                  description: lifestyle.Divtitle,
                })
              }
            />

            <textarea
              className={classNames.draginput}
              placeholder="Description"
              style={{
                height: "90px",
                paddingTop: "1.3rem",
              }}
              value={lifestyle.description}
              onChange={(e) =>
                setlifestyle({
                  name: lifestyle.name,
                  description: e.target.value,
                })
              }
            />

            <div className={classNames.Addto} onClick={addlifestyle}>
              Add to perscription
            </div>
          </>
        ) : option == "Prescribe note" ? (
          <>
            <div className={classNames.title} onClick={backfunction}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12"
                  fill="white"
                  stroke="#E9EBF3"
                />
                <path
                  d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z"
                  fill="url(#paint0_linear_102_3785)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_3785"
                    x1="14.8001"
                    y1="12.4999"
                    x2="7.90003"
                    y2="12.4999"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#19376D" />
                  </linearGradient>
                </defs>
              </svg>
              Add Note
            </div>

            <input
              className={classNames.draginput}
              placeholder="Investigation name"
              style={{
                marginBottom: "1rem",
              }}
              value={addnote.description}
              onChange={(e) =>
                setaddnote({
                  description: e.target.value,
                })
              }
            />

            <div className={classNames.Addto} onClick={addNotefucntion}>
              Add to perscription
            </div>
          </>
        ) : option == "Prescribe investigation" ? (
          <>
            <div className={classNames.title} onClick={backfunction}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12"
                  fill="white"
                  stroke="#E9EBF3"
                />
                <path
                  d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z"
                  fill="url(#paint0_linear_102_3785)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_3785"
                    x1="14.8001"
                    y1="12.4999"
                    x2="7.90003"
                    y2="12.4999"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#19376D" />
                  </linearGradient>
                </defs>
              </svg>
              Choose investigation
            </div>

            <input
              className={classNames.draginput}
              placeholder="Investigation name"
              style={{
                marginBottom: "1rem",
              }}
              value={investingaData.name}
              onChange={(e) =>
                setinvestingaData({
                  name: e.target.value,
                  lab: investingaData.lab,
                  description: investingaData.description,
                })
              }
            />

            <input
              className={classNames.draginput}
              placeholder="Lab name"
              style={{
                marginBottom: "1rem",
              }}
              value={investingaData.lab}
              onChange={(e) =>
                setinvestingaData({
                  name: investingaData.name,
                  lab: e.target.value,
                  description: investingaData.description,
                })
              }
            />

            <textarea
              className={classNames.draginput}
              placeholder="Description"
              style={{
                height: "90px",
                paddingTop: "1.3rem",
              }}
              value={investingaData.description}
              onChange={(e) =>
                setinvestingaData({
                  name: investingaData.name,
                  lab: investingaData?.lab,
                  description: e.target.value,
                })
              }
            />

            <div className={classNames.Addto} onClick={addinvest}>
              Add to perscription
            </div>
          </>
        ) : option == "main" ? (
          <>
            <div className={classNames.title}>Options</div>
            {allPerscribeItems?.length > 0 &&
              allPerscribeItems?.map((eachPerscribe, index) => (
                <div
                  key={eachPerscribe + index}
                  className={classNames.eachOption}
                  onClick={() => callinerlist(eachPerscribe)}
                >
                  {eachPerscribe}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="11.5"
                      fill="white"
                      stroke="#E9EBF3"
                    />
                    <path
                      d="M15.3003 12.2473L11.24 8.21392C11.1649 8.13939 11.0595 8.0968 10.9492 8.0968H9.20443C8.85747 8.0968 8.67729 8.47867 8.91358 8.7132L12.7254 12.4968L8.91358 16.2804C8.67729 16.5149 8.85747 16.8968 9.20443 16.8968H10.9492C11.0595 16.8968 11.1649 16.8545 11.24 16.7797L15.3003 12.7463C15.4418 12.6056 15.4418 12.388 15.3003 12.2473Z"
                      fill="url(#paint0_linear_61_2789)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_61_2789"
                        x1="8.80642"
                        y1="12.4968"
                        x2="15.4064"
                        y2="12.4968"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#0B2447" />
                        <stop offset="0.311" stop-color="#0D264C" />
                        <stop offset="0.6768" stop-color="#122E5A" />
                        <stop offset="1" stop-color="#19376D" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              ))}
          </>
        ) : option == "Prescribe treatment" ? (
          <>
            <div
              onClick={() => {
                setrightsidedname("Prescribe medication");
                backfunction();
              }}
              className={classNames.title}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12"
                  fill="white"
                  stroke="#E9EBF3"
                />
                <path
                  d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z"
                  fill="url(#paint0_linear_102_3785)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_3785"
                    x1="14.8001"
                    y1="12.4999"
                    x2="7.90003"
                    y2="12.4999"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#19376D" />
                  </linearGradient>
                </defs>
              </svg>
              Choose treatment
            </div>
            {catoriesSummary?.length > 0 &&
              catoriesSummary
                ?.filter((qitem) => qitem?.name.includes(globalSearchQuery))
                ?.map((eachPerscribe, index) => (
                  <div
                    key={eachPerscribe + index}
                    className={classNames.eachOption}
                    onClick={() => additem(eachPerscribe)}
                  >
                    {eachPerscribe?.name}

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z"
                        fill="url(#paint0_linear_102_3794)"
                      />
                      <g opacity="0.2">
                        <path
                          opacity="0.2"
                          d="M9.04433 12.379C6.57106 14.3463 3.81674 15.3581 1.23106 15.4143C-0.680116 12.1541 -0.286625 4.22844 1.9618 1.92381C3.98538 -0.155971 10.3372 -0.549462 13.9909 0.743376C14.8902 4.45328 13.0353 9.17494 9.04433 12.379Z"
                          fill="white"
                        />
                      </g>
                      <path
                        d="M14.3281 9.00631C14.3281 9.5684 13.8784 10.0181 13.3163 10.0181H9.94372V13.3907C9.94372 13.9528 9.49402 14.4025 8.93193 14.4025C8.36983 14.4025 7.92014 13.9528 7.92014 13.3907V10.0181H4.54748C3.98538 10.0181 3.53569 9.5684 3.53569 9.00631C3.53569 8.44421 3.98538 7.99452 4.54748 7.99452H7.9201V4.6219C7.9201 4.0598 8.36979 3.61011 8.93189 3.61011C9.49398 3.61011 9.94368 4.0598 9.94368 4.6219V7.99452H13.3163C13.8784 7.99452 14.3281 8.44417 14.3281 9.00631Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_102_3794"
                          x1="8.98844"
                          y1="17.9997"
                          x2="8.98844"
                          y2="0.025855"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="2.6639e-08" stop-color="#191F41 " />
                          <stop offset="1" stop-color="#F79540" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                ))}
          </>
        ) : option == "Prescribe diet plan" ? (
          <>
            <div className={classNames.title}>
              <svg
                onClick={backfunction}
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12"
                  fill="white"
                  stroke="#E9EBF3"
                />
                <path
                  d="M8.01099 12.7607L12.2558 16.9774C12.3344 17.0553 12.4445 17.0999 12.5599 17.0999H14.384C14.7467 17.0999 14.9351 16.7006 14.688 16.4554L10.7029 12.4999L14.688 8.54427C14.9351 8.29908 14.7467 7.89985 14.384 7.89985H12.5599C12.4445 7.89985 12.3344 7.94408 12.2558 8.0223L8.01099 12.239C7.86303 12.3861 7.86303 12.6136 8.01099 12.7607Z"
                  fill="url(#paint0_linear_102_3785)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_3785"
                    x1="14.8001"
                    y1="12.4999"
                    x2="7.90003"
                    y2="12.4999"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#19376D" />
                  </linearGradient>
                </defs>
              </svg>
              Choose diet plan
            </div>
            {catoriesSummary?.length > 0 &&
              catoriesSummary
                ?.filter((qitem) => qitem?.name.includes(globalSearchQuery))
                ?.map((eachPerscribe, index) => (
                  <div
                    key={eachPerscribe + index}
                    className={classNames.eachOption}
                    //

                    style={{
                      height:
                        expandable && samevalue == eachPerscribe?._id && "auto",
                      display: "block",
                    }}
                  >
                    <div className={classNames.closesection}>
                      {eachPerscribe?.name}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {expandable && samevalue == eachPerscribe?._id ? (
                          <div
                            className={classNames.close}
                            onClick={closeexpadingfunction}
                          >
                            Close
                          </div>
                        ) : (
                          <div
                            className={classNames.close}
                            onClick={() => expadingfunction(eachPerscribe)}
                          >
                            Expand
                          </div>
                        )}

                        <svg
                          onClick={() => adddietpla(eachPerscribe)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z"
                            fill="url(#paint0_linear_102_3794)"
                          />
                          <g opacity="0.2">
                            <path
                              opacity="0.2"
                              d="M9.04433 12.379C6.57106 14.3463 3.81674 15.3581 1.23106 15.4143C-0.680116 12.1541 -0.286625 4.22844 1.9618 1.92381C3.98538 -0.155971 10.3372 -0.549462 13.9909 0.743376C14.8902 4.45328 13.0353 9.17494 9.04433 12.379Z"
                              fill="white"
                            />
                          </g>
                          <path
                            d="M14.3281 9.00631C14.3281 9.5684 13.8784 10.0181 13.3163 10.0181H9.94372V13.3907C9.94372 13.9528 9.49402 14.4025 8.93193 14.4025C8.36983 14.4025 7.92014 13.9528 7.92014 13.3907V10.0181H4.54748C3.98538 10.0181 3.53569 9.5684 3.53569 9.00631C3.53569 8.44421 3.98538 7.99452 4.54748 7.99452H7.9201V4.6219C7.9201 4.0598 8.36979 3.61011 8.93189 3.61011C9.49398 3.61011 9.94368 4.0598 9.94368 4.6219V7.99452H13.3163C13.8784 7.99452 14.3281 8.44417 14.3281 9.00631Z"
                            fill="white"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_102_3794"
                              x1="8.98844"
                              y1="17.9997"
                              x2="8.98844"
                              y2="0.025855"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop offset="2.6639e-08" stop-color="#191F41 " />
                              <stop offset="1" stop-color="#F79540" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>

                    {expandable && samevalue == eachPerscribe?._id && (
                      <>
                        <div className={classNames.listItem}>
                          <div>Amount of days:</div>
                          <div>{eachPerscribe?.numofdays}</div>
                        </div>
                        <div className={classNames.listItem}>
                          <div>Starts on:</div>
                          <div>{eachPerscribe?.startingday}</div>
                        </div>

                        <div
                          className={classNames.listItem}
                          style={{
                            lineHeight: "25px",
                          }}
                        >
                          {eachPerscribe?.description}
                        </div>
                        {eachPerscribe?.plandetails?.map((item) => {
                          return (
                            <>
                              <div className={classNames.questionsection}>
                                <div className={classNames.questionsectionName}>
                                  {item?.day}
                                </div>
                                {item?.dayplan?.map((such) => {
                                  return (
                                    <>
                                      <div
                                        className={classNames.questionsectBox}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                          }}
                                          onClick={() => {
                                            setdplandropdown(true);
                                            setdietid(such?._id);
                                          }}
                                        >
                                          <div>{such?.time}</div>
                                          <div>
                                            {such?.unitamount} {such?.unitname}{" "}
                                            {such?.kitchenItemName}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="7"
                                              height="5"
                                              viewBox="0 0 7 5"
                                              fill="none"
                                            >
                                              <path
                                                d="M3.69846 4.91959L6.90683 1.84362C6.96612 1.7867 7 1.70688 7 1.62328V0.301496C7 0.0386509 6.69624 -0.0978492 6.50968 0.0811595L3.5 2.96891L0.490321 0.0811595C0.303761 -0.0978492 -4.76837e-07 0.0386509 -4.76837e-07 0.301496V1.62328C-4.76837e-07 1.70688 0.0336499 1.7867 0.0931659 1.84362L3.30154 4.91959C3.41347 5.0268 3.58653 5.0268 3.69846 4.91959Z"
                                                fill="url(#paint0_linear_145_4817)"
                                              />
                                              <defs>
                                                <linearGradient
                                                  id="paint0_linear_145_4817"
                                                  x1="3.50001"
                                                  y1="-2.63166e-05"
                                                  x2="3.50001"
                                                  y2="4.99999"
                                                  gradientUnits="userSpaceOnUse"
                                                >
                                                  <stop stop-color="#0B2447" />
                                                  <stop
                                                    offset="0.311"
                                                    stop-color="#0D264C"
                                                  />
                                                  <stop
                                                    offset="0.6768"
                                                    stop-color="#122E5A"
                                                  />
                                                  <stop
                                                    offset="1"
                                                    stop-color="#19376D"
                                                  />
                                                </linearGradient>
                                              </defs>
                                            </svg>
                                          </div>
                                        </div>
                                        {dplandropdown &&
                                          such?._id == dietid && (
                                            <>
                                              <div className="deitpaldroplist">
                                                <div className="inner">
                                                  <div className="name">
                                                    New Quantity:
                                                  </div>
                                                  <input placeholder="0.00" />
                                                </div>
                                              </div>
                                              <div className="deitpaldroplist">
                                                <div className="inner">
                                                  <div className="name">
                                                    New Time:
                                                  </div>
                                                  <input placeholder="0.00" />
                                                </div>
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                ))}
          </>
        ) : (
          <>
            <div className={classNames.title}>Options</div>
            {allPerscribeItems?.length > 0 &&
              allPerscribeItems?.map((eachPerscribe, index) => (
                <div
                  key={eachPerscribe + index}
                  className={classNames.eachOption}
                  onClick={() => setoption(eachPerscribe)}
                >
                  {eachPerscribe}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="11.5"
                      fill="white"
                      stroke="#E9EBF3"
                    />
                    <path
                      d="M15.3003 12.2473L11.24 8.21392C11.1649 8.13939 11.0595 8.0968 10.9492 8.0968H9.20443C8.85747 8.0968 8.67729 8.47867 8.91358 8.7132L12.7254 12.4968L8.91358 16.2804C8.67729 16.5149 8.85747 16.8968 9.20443 16.8968H10.9492C11.0595 16.8968 11.1649 16.8545 11.24 16.7797L15.3003 12.7463C15.4418 12.6056 15.4418 12.388 15.3003 12.2473Z"
                      fill="url(#paint0_linear_61_2789)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_61_2789"
                        x1="8.80642"
                        y1="12.4968"
                        x2="15.4064"
                        y2="12.4968"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#0B2447" />
                        <stop offset="0.311" stop-color="#0D264C" />
                        <stop offset="0.6768" stop-color="#122E5A" />
                        <stop offset="1" stop-color="#19376D" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              ))}
          </>
        )}
      </div>

      <div className={classNames.rightside}>
        Prescription
        {rightsidedname != "Prescribe note" &&
        rightsidedname != "Prescribe diet plan" &&
        rightsidedname != "Prescribe medication" &&
        rightsidedname != "Prescribe investigation" &&
        rightsidedname != "Prescribe life style advice" ? (
          <>
            <div className={classNames.rightsubdiv}>
              {storetrement?.length > 0 &&
                storetrement?.map((eachPerscribe, index) => (
                  <div
                    key={eachPerscribe + index}
                    className={classNames.eachOptionright}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {eachPerscribe?.name}

                      <svg
                        onClick={() => {
                          additemback(eachPerscribe);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z"
                          fill="url(#paint0_linear_113_4176)"
                        />
                        <g opacity="0.2">
                          <path
                            opacity="0.2"
                            d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z"
                            fill="white"
                          />
                        </g>
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z"
                          fill="white"
                        />
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_113_4176"
                            x1="-9.46143e-05"
                            y1="9.01325"
                            x2="17.9761"
                            y2="9.01325"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0B2447" />
                            <stop offset="0.311" stop-color="#0D264C" />
                            <stop offset="0.6768" stop-color="#122E5A" />
                            <stop offset="1" stop-color="#19376D" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>

                    <div>
                      <textarea
                        value={eachPerscribe?.des}
                        onChange={(e) => chnagetreatment(e, index)}
                        style={{
                          width: "100%",
                          fontFamily: "Montserrat",
                          fontSize: "11px",
                          fontWeight: "400",
                          lineHeight: "13.41px",
                          padding: "1rem",
                          border: " 1px solid #E5E5E5",
                          borderRadius: "5px",
                          marginTop: "0.7rem",
                        }}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className={classNames.bottomlabel}>
              <div
                className={classNames.leftside}
                style={{
                  cursor: "not-allowed",
                }}
              >
                Push To Almari
                <br />
                <div
                  style={{
                    fontSize: "10px",
                  }}
                >
                  (Coming Soon)
                </div>
              </div>
              <div
                className={classNames.rightside}
                style={{
                  cursor: "pointer",
                }}
                onClick={savdmediciance}
              >
                Submit
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classNames.rightsubdiv}>
              <>
                {dietplan?.length > 0 &&
                  dietplan?.map((eachPerscribe, index) => (
                    <div
                      key={eachPerscribe + index}
                      className={classNames.eachOptionright}
                      style={{
                        display: "flex",
                      }}
                      onClick={() => additembackdiet(eachPerscribe)}
                    >
                      {eachPerscribe?.name}

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z"
                          fill="url(#paint0_linear_113_4176)"
                        />
                        <g opacity="0.2">
                          <path
                            opacity="0.2"
                            d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z"
                            fill="white"
                          />
                        </g>
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z"
                          fill="white"
                        />
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_113_4176"
                            x1="-9.46143e-05"
                            y1="9.01325"
                            x2="17.9761"
                            y2="9.01325"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0B2447" />
                            <stop offset="0.311" stop-color="#0D264C" />
                            <stop offset="0.6768" stop-color="#122E5A" />
                            <stop offset="1" stop-color="#19376D" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                  ))}

                {storetrement?.length > 0 &&
                  storetrement?.map((eachPerscribe, index) => (
                    <div
                      key={eachPerscribe + index}
                      className={classNames.eachOptionright}
                      style={{
                        display: "flex",
                      }}
                      onClick={() => additemback(eachPerscribe)}
                    >
                      {eachPerscribe?.name}

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z"
                          fill="url(#paint0_linear_113_4176)"
                        />
                        <g opacity="0.2">
                          <path
                            opacity="0.2"
                            d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z"
                            fill="white"
                          />
                        </g>
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z"
                          fill="white"
                        />
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_113_4176"
                            x1="-9.46143e-05"
                            y1="9.01325"
                            x2="17.9761"
                            y2="9.01325"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0B2447" />
                            <stop offset="0.311" stop-color="#0D264C" />
                            <stop offset="0.6768" stop-color="#122E5A" />
                            <stop offset="1" stop-color="#19376D" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                  ))}

                {lifestyleseleted?.length > 0 &&
                  lifestyleseleted?.map((eachPerscribe, index) => (
                    <div
                      key={eachPerscribe + index}
                      className={classNames.eachOptionright}
                      style={{
                        display: "flex",
                      }}
                      onClick={() => removelifestyle(eachPerscribe)}
                    >
                      {eachPerscribe?.name} | {eachPerscribe?.description}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z"
                          fill="url(#paint0_linear_113_4176)"
                        />
                        <g opacity="0.2">
                          <path
                            opacity="0.2"
                            d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z"
                            fill="white"
                          />
                        </g>
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z"
                          fill="white"
                        />
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_113_4176"
                            x1="-9.46143e-05"
                            y1="9.01325"
                            x2="17.9761"
                            y2="9.01325"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0B2447" />
                            <stop offset="0.311" stop-color="#0D264C" />
                            <stop offset="0.6768" stop-color="#122E5A" />
                            <stop offset="1" stop-color="#19376D" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                  ))}

                {investingaSelected?.length > 0 &&
                  investingaSelected?.map((eachPerscribe, index) => (
                    <div
                      key={eachPerscribe + index}
                      className={classNames.eachOptionright}
                      style={{
                        display: "flex",
                      }}
                      onClick={() => removeInvesting(eachPerscribe)}
                    >
                      {eachPerscribe?.name} |{eachPerscribe?.lab} |{" "}
                      {eachPerscribe?.description}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z"
                          fill="url(#paint0_linear_113_4176)"
                        />
                        <g opacity="0.2">
                          <path
                            opacity="0.2"
                            d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z"
                            fill="white"
                          />
                        </g>
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z"
                          fill="white"
                        />
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_113_4176"
                            x1="-9.46143e-05"
                            y1="9.01325"
                            x2="17.9761"
                            y2="9.01325"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0B2447" />
                            <stop offset="0.311" stop-color="#0D264C" />
                            <stop offset="0.6768" stop-color="#122E5A" />
                            <stop offset="1" stop-color="#19376D" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                  ))}

                {addnoteseleted?.length > 0 &&
                  addnoteseleted?.map((eachPerscribe, index) => (
                    <div
                      key={eachPerscribe + index}
                      className={classNames.eachOptionright}
                      style={{
                        display: "flex",
                      }}
                      onClick={() => removenote(eachPerscribe)}
                    >
                      {eachPerscribe?.description}

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z"
                          fill="url(#paint0_linear_113_4176)"
                        />
                        <g opacity="0.2">
                          <path
                            opacity="0.2"
                            d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z"
                            fill="white"
                          />
                        </g>
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z"
                          fill="white"
                        />
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_113_4176"
                            x1="-9.46143e-05"
                            y1="9.01325"
                            x2="17.9761"
                            y2="9.01325"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0B2447" />
                            <stop offset="0.311" stop-color="#0D264C" />
                            <stop offset="0.6768" stop-color="#122E5A" />
                            <stop offset="1" stop-color="#19376D" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                  ))}
                {storeinfoMedicance?.length > 0 &&
                  storeinfoMedicance?.map((eachPerscribe, index) => (
                    <div
                      key={eachPerscribe + index}
                      className={classNames.eachOptionright}
                      style={{
                        display: "flex",
                      }}
                      onClick={() => removemediance(eachPerscribe)}
                    >
                      {eachPerscribe?.name} |{eachPerscribe?.dosage} |{" "}
                      {eachPerscribe?.frequency} | {eachPerscribe?.lengthOfTime}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z"
                          fill="url(#paint0_linear_113_4176)"
                        />
                        <g opacity="0.2">
                          <path
                            opacity="0.2"
                            d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z"
                            fill="white"
                          />
                        </g>
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z"
                          fill="white"
                        />
                        <path
                          d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_113_4176"
                            x1="-9.46143e-05"
                            y1="9.01325"
                            x2="17.9761"
                            y2="9.01325"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0B2447" />
                            <stop offset="0.311" stop-color="#0D264C" />
                            <stop offset="0.6768" stop-color="#122E5A" />
                            <stop offset="1" stop-color="#19376D" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                  ))}
              </>
            </div>
            <div className={classNames.bottomlabel}>
              <div
                className={classNames.leftside}
                style={{
                  cursor: "not-allowed",
                }}
              >
                Push To Almari
                <br />
                <div
                  style={{
                    fontSize: "10px",
                  }}
                >
                  (Coming Soon)
                </div>
              </div>
              <div
                className={classNames.rightside}
                style={{
                  cursor: "pointer",
                }}
                onClick={savdmediciance}
              >
                Submit
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const PatientProfileForm = ({
  localRefresh,
  stateValue,
  sessionValue,
  selectedMenu,
  setSelectedMenu,
}) => {
  const [patientProfileForm, setPatientProfileForm] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");

  //functions
  async function getPatientProfileForms() {
    try {
      let response = await axios.get(
        `https://finacebackend.marketsverse.com/api/getuserpatient?clientid=${sessionValue?.clientid}`
      );
      if (response?.data?.data?.length > 0) {
        setPatientProfileForm(response?.data?.data);
      }
      console.log(response, "Get client profile forms response", stateValue);
    } catch (error) {
      console.log(error, "Get client profile forms response");
    }
  }

  //rendering
  useEffect(() => {
    getPatientProfileForms();
  }, [sessionValue?.clientid]);

  return (
    <div className={classNames.preEnrollmentForm}>
      {selectedMenu === "Client Profile Forms Questions"
        ? selectedPatient?.questions?.length > 0
          ? selectedPatient?.questions?.map((eachQuestion, index) => {
              return (
                <div
                  className={classNames.eachQuestion}
                  key={eachQuestion?.name + index}
                >
                  <div className={classNames.title}>{eachQuestion?.name}</div>
                  <div className={classNames.answer}>
                    {eachQuestion?.answer}
                  </div>
                </div>
              );
            })
          : ""
        : patientProfileForm.length > 0
        ? patientProfileForm.map((eachPatient, index) => {
            return (
              <div
                className={classNames.eachQuestion}
                key={eachPatient?.formname + index}
              >
                {index == 0 && (
                  <div
                    className={classNames.title}
                    style={{ marginBottom: "1.2rem" }}
                  >
                    Select client profile
                  </div>
                )}
                <div
                  className={classNames.answer}
                  onClick={() => {
                    setSelectedPatient(eachPatient);
                    setSelectedMenu("Client Profile Forms Questions");
                  }}
                >
                  {eachPatient?.formname}
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
};
