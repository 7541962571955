import axios from "axios";

export async function getData(URL) {
  let response = await axios.get(URL);
  //   console.log(response, "getData response");
  return response?.data;
}
export async function deleteData(URL) {
  let response = await axios.delete(URL);
  //   console.log(response, "getData response");
  return response?.data;
}
export async function updateData(URL, obj) {
  let response = await axios.put(URL, obj);
  //   console.log(response, "getData response");
  return response?.data;
}
