import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./users.module.scss";

//assets
import { ReactComponent as FilterIcon } from "../../../assets/images/filter.svg";
import { CopyBtn, CustomCheckbox } from "../../../components/Custom";
import { IoMdArrowDropdown } from "react-icons/io";
import { ReactComponent as SortArrows } from "../../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../context/Globalcontext";
import { useNetworkRequest } from "../../../hooks/networkRequest";

const CrmUsers = () => {
  const navigate = useNavigate();
  const { actionBtns, setActionBtns, globalSearchQuery, editingToggle, seteditingToggle } =
    useContext(GlobalContext);
  const { allValues: allLeads, allValuesLoading: allLeadsLoading } =
    useNetworkRequest({
      URL: "https://comms.globalxchange.io/gxb/apps/users/get?app_code=amma",
    });

  //page values
  const [filterOpen, setFilterOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [allLeadsLoading, setAllLeadsLoading] = useState(false);
  // const [allLeads, setAllLeads] = useState(tempLeads);
  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");

  // function onCloseSidebar() {
  //   if (sidebarOpen) {
  //     setSidebarOpen((prev) => !prev);
  //   }
  // }

  const filterDropdownRef = useRef(null);
  const selectedItemTable = useRef(null);

  const filterItems = [];

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    seteditingToggle(false)
  }, [])
  return (
    <>
    

          <div className={classNames.crmUsers}>
            <div className={classNames.allLeads}>
              <div className={classNames.nav}>
                <div className={classNames.filterBtn} ref={filterDropdownRef}>
                  <FilterIcon
                    onClick={() => {
                      setFilterOpen(!filterOpen);
                    }}
                  />
                  {filterOpen && (
                    <div className={classNames.dropdownContent}>
                      {filterItems?.map((eachItem, index) => {
                        return (
                          <div
                            className={classNames.eachFilterItem}
                            key={eachItem + index}
                          >
                            {eachItem}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div
                className={classNames.tableContainer}
                style={{ border: allLeads ? "" : "none" }}
              >
                <table style={{ minWidth: "100%" }}>
                  <thead>
                    <tr style={{ gridTemplateColumns: "min-content repeat(5, 1fr)" }}>

                      <th>
                        Name <SortArrows />
                      </th>
                      <th>
                        Email <SortArrows />
                      </th>
                      <th>
                        Phone Number <SortArrows />
                      </th>
                      <th>
                        Referral <SortArrows />
                      </th>
                      <th>
                        Organization <SortArrows />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allLeadsLoading ? (
                      <tr
                        key={"loading"}
                        style={{ gridTemplateColumns: "min-content repeat(5, 1fr)" }}
                      >

                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                      </tr>
                    ) : (
                      allLeads?.users?.length > 0 &&
                      allLeads?.users
                        ?.filter((row) => {
                          // // Convert the row object values to an array and check if any value matches the search query
                          // const values = Object?.values(row);
                          // const searchQuery = globalSearchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                          // return values?.some((value) => {
                          //   if (typeof value === "string") {
                          //     return value?.toLowerCase()?.includes(searchQuery);
                          //   }
                          //   return false;
                          // });
                          const searchQuery = globalSearchQuery?.toLowerCase();
                          return row?.name?.toLowerCase()?.includes(searchQuery);
                        })
                        ?.sort((a, b) => a?.first_name?.localeCompare(b?.first_name))
                        ?.map((row, index) => (
                          <tr
                            key={row?.brokerage_name + index}
                            style={{
                              gridTemplateColumns: "min-content repeat(5, 1fr)",
                              pointerEvents: sidebarOpen ? "none" : "",
                            }}
                          >
                            <td style={{ pointerEvents: "none" }}>
                              <CustomCheckbox
                                actionBtns={actionBtns}
                                setActionBtns={setActionBtns}
                                id={row?.user_employee_id}
                                name={row?.first_name + " " + row?.last_name}
                              />
                              {actionBtns?.includes(row?.user_employee_id) && (
                                <div
                                  className={classNames.dropdownContent}
                                  ref={selectedItemTable}
                                >
                                  {filterItems?.map((eachItem, index) => {
                                    return (
                                      <div
                                        className={classNames.eachFilterItem}
                                        key={eachItem?.title + index}
                                        style={{
                                          pointerEvents: eachItem?.clickable
                                            ? ""
                                            : "none",
                                          opacity: eachItem?.clickable ? "" : "0.5",
                                        }}
                                        onClick={() => {
                                          if (
                                            eachItem?.clickable &&
                                            eachItem?.function
                                          ) {
                                            eachItem?.function();
                                          }
                                          setActionBtns([]);
                                        }}
                                      >
                                        <span>
                                          {eachItem.icon && <eachItem.icon />}
                                          {eachItem?.title}
                                        </span>{" "}
                                        {eachItem?.subMenu && <IoMdArrowDropdown />}
                                        {eachItem?.subMenu?.length > 0 &&
                                          Array.isArray(eachItem?.subMenu) && (
                                            <div
                                              className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                            >
                                              {eachItem?.subMenu?.map(
                                                (eachItemSub, index) => {
                                                  return (
                                                    <div
                                                      className={
                                                        classNames.eachFilterItem
                                                      }
                                                      key={eachItemSub?.title + index}
                                                      style={{
                                                        pointerEvents:
                                                          eachItemSub?.clickable
                                                            ? ""
                                                            : "none",
                                                        opacity:
                                                          eachItemSub?.clickable
                                                            ? ""
                                                            : "0.5",
                                                      }}
                                                      onClick={() => {
                                                        navigate(eachItemSub?.link);
                                                      }}
                                                    >
                                                      {eachItemSub?.title}{" "}
                                                      {eachItemSub?.subMenu && (
                                                        <IoMdArrowDropdown />
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </td>
                            <td>
                              {row?.name ? row?.name : ""}

                            </td>
                            <td>
                              {row?.email ? row?.email : ""}

                            </td>
                            <td>
                              {row?.phone ? row?.phone : ""}

                            </td>
                            <td>
                              {row?.ref_affiliate ? row?.ref_affiliate : ""}

                            </td>
                            <td>
                              {row?.organization ? row?.organization : ""}

                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      
    </>
  );
};

export default CrmUsers;
