import React, { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./flow.scss";
import classNames from "./flow.module.scss";
import axios from "axios";
import { GlobalContext } from "../../../../context/Globalcontext";
import { EachCustomDropdown } from "../../../../components/Custom";
import { toastify } from "../../../../assets/functions/toastify";
import { ReactComponent as ActionBtnIcon } from "../../../../assets/images/icons/actionbtn.svg";
import { IoMdClose } from "react-icons/io";

const KanbanBoard = ({
  stateValue,
  setState,
  setLocalSidebar,
  selectedPipelineDetails,
  setSelectedPipelineDetails,
  setSelectedDrawerItem,
}) => {
  const [stages, setStages] = useState([
    { id: 1, title: "Stage 1", items: [] },
    { id: 2, title: "Stage 2", items: [] },
    { id: 3, title: "Stage 3", items: [] },
    { id: 4, title: "Stage 4", items: [] },
  ]);
  const { email, profileName } = useContext(GlobalContext);
  const [flowOnePipeline, setFlowOnePipeline] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [editingText, setEditingText] = useState("");
  const [showAddJobPopup, setShowAddJobPopup] = useState(false);
  const [localRefresh, setLocalRefresh] = useState(false);
  const [actionBtn, setActionBtn] = useState(false);

  // create job
  const [allClients, setAllClients] = useState([]);
  const [allStaffMembers, setAllStaffMembers] = useState([]);
  const [newJob, setNewJob] = useState({});

  //move jobs
  const [eachJobSettings, setEachJobSettings] = useState("");
  const [moveJobQuery, setMoveJobQuery] = useState("");

  //functions

  async function getFlowForOnePipeline(pipelineID) {
    try {
      let response = await axios.get(
        `https://finacebackend.marketsverse.com/api/getstage?pipelineid=${pipelineID}`
      );

      if (response?.data?.data?.length > 0) {
        setFlowOnePipeline(response?.data?.data);
      }

      console.log(response, "Get Flow For One Pipeline response");
    } catch (error) {
      console.log(error, "Get Flow For One Pipeline error");
    }
  }

  async function createJob() {
    // adding balance values

    newJob.pipelineid = stateValue?.data?.pipelineid;
    newJob.order = "1";
    newJob.teamusername =
      JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername ||
      "";
    newJob.tasks =
      Array.isArray(flowOnePipeline) &&
      Array.isArray(flowOnePipeline[0]?.stepdetails) &&
      Array.isArray(flowOnePipeline[0]?.stepdetails[0]?.TaskDetails)
        ? [
            {
              taskid:
                flowOnePipeline[0]?.stepdetails[0]?.TaskDetails[0]?.taskid,
            },
          ]
        : [];

    try {
      let response = await axios.post(
        "https://finacebackend.marketsverse.com/api/addjob",
        newJob
      );

      if (response?.data?.status) {
        setShowAddJobPopup(false);
        toastify(response?.data?.message, "success");
        setNewJob({});
      } else {
        toastify(response?.data?.message, "error");
      }

      console.log(response, "Create job successful");
    } catch (error) {
      console.log(error, "Create job error");
      toastify(error?.message, "error");
    }
  }

  async function getClientsForSelectedOrganization() {
    try {
      let response = await axios.get(
        `https://finacebackend.marketsverse.com/api/getallclientsforteam/${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      );

      if (response?.data?.data?.length > 0) {
        setAllClients(response?.data?.data);
      }

      console.log(response, "Get clients for selected organization response");
    } catch (error) {
      console.log(error, "Get clients for selected organization error");
    }
  }

  async function getClientsForAllStaffMembers() {
    try {
      let response = await axios.get(
        `https://finacebackend.marketsverse.com/api/getallplayersforteam/${
          JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
        }`
      );

      if (response?.data?.data?.length > 0) {
        setAllStaffMembers(response?.data?.data);
      }

      console.log(response, "Get clients for all staff members response");
    } catch (error) {
      console.log(error, "Get clients for all staff members error");
    }
  }

  function findNextTaskID(currentTaskID, allTasksUnderPipelineSelected) {
    // Implement the logic to find the next task ID based on the current task ID
    // This is a placeholder implementation; replace it with your actual logic
    const currentTaskIndex = allTasksUnderPipelineSelected.findIndex(
      (task) => task.taskid === currentTaskID
    );
    if (
      currentTaskIndex !== -1 &&
      currentTaskIndex + 1 < allTasksUnderPipelineSelected.length
    ) {
      return allTasksUnderPipelineSelected[currentTaskIndex + 1].taskid;
    }
    return null;
  }

  async function moveJobToNewTask(jobID, obj, allTasksUnderPipelineSelected) {
    try {
      let response = await axios.put(
        `https://finacebackend.marketsverse.com/api/updatetaskforjob?jobid=${jobID}`,
        obj
      );

      if (response?.data?.status) {
        toastify(response?.data?.message, "success");
      } else {
        toastify(response?.data?.message, "error");
      }

      if (response?.data?.message === "Automatic task updated") {
        // Find the next task ID (you need to implement the logic to get the next task ID)
        const nextTaskID = findNextTaskID(
          obj.nexttaskid,
          allTasksUnderPipelineSelected
        );

        // console.log(nextTaskID, "nextTaskID", jobID, response?.data?.message);
        // Call moveJobToNewTask again with the updated next task ID
        if (nextTaskID) {
          moveJobToNewTask(
            jobID,
            {
              nexttaskid: nextTaskID,
              currentstatus: "completed",
            },
            allTasksUnderPipelineSelected
          );
        }
      } else {
        toastify(response?.data?.message, "success");
        setEachJobSettings("");
        setMoveJobQuery("");
        setLocalRefresh((prev) => !prev);
      }
    } catch (error) {
      console.log(error, "Move Job To New Task error");
    }
  }

  function extractAndFlattenStepDetails(array) {
    return array.reduce((acc, obj) => {
      if (obj.TaskDetails && Array.isArray(obj.TaskDetails)) {
        acc.push(...obj.TaskDetails);
      }
      return acc;
    }, []);
  }

  //rendering

  useEffect(() => {
    if (stateValue?.data?.pipelineid) {
      getFlowForOnePipeline(stateValue?.data?.pipelineid);
    }

    if (JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername) {
      getClientsForSelectedOrganization();
      getClientsForAllStaffMembers();
    }
  }, [localRefresh]);

  //popup close

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowAddJobPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowAddJobPopup]);

  const eachJobPopup = useRef(null);
  const eachJobPopupActionBtn = useRef(null);

  const handleClickOutside = (event) => {
    if (eachJobPopup.current && !eachJobPopup.current.contains(event.target)) {
      setEachJobSettings(""); // Close the dropdown
    }
    if (
      eachJobPopupActionBtn.current &&
      !eachJobPopupActionBtn.current.contains(event.target)
    ) {
      setActionBtn(""); // Close the dropdown
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={classNames.kanbanBoard}
        onClick={(e) => {
          if (showAddJobPopup) {
            setShowAddJobPopup(false);
          }
        }}
      >
        {Array.isArray(flowOnePipeline) && flowOnePipeline?.length > 0
          ? flowOnePipeline?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.kanbanStage}
                  key={eachItem?.name + index}
                >
                  <div className={classNames.stageHeader}>
                    <h3>
                      <span>{eachItem?.name}</span>
                      <div className={classNames.actionBtnsContainer}>
                        {eachItem?._id === actionBtn ? (
                          <IoMdClose
                            onClick={() => {
                              setActionBtn("");
                            }}
                          />
                        ) : (
                          <ActionBtnIcon
                            onClick={() => {
                              setActionBtn(eachItem?._id);
                            }}
                          />
                        )}
                        {eachItem?._id === actionBtn && (
                          <div
                            className={classNames.jobDropdown}
                            ref={eachJobPopupActionBtn}
                          >
                            <div className={classNames.title}>
                              What do you want to do?
                            </div>
                            <div className={classNames.allOptions}>
                              <div>See stage profile</div>
                              <div>Delete stage</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </h3>
                    {index === 0 && (
                      <div
                        className={classNames.addBtn}
                        onClick={(e) => setShowAddJobPopup(true)}
                      >
                        <span style={{ fontSize: "15px" }}>+</span>&nbsp;New Job
                      </div>
                    )}
                    <div
                      className={classNames.addItemBtn}
                      onClick={() => {
                        setLocalSidebar("addnewstep");
                      }}
                    >
                      +
                    </div>
                  </div>
                  <div className={classNames.stageItems}>
                    {Array.isArray(eachItem?.stepdetails) &&
                    eachItem?.stepdetails?.length > 0
                      ? eachItem?.stepdetails?.map((eachStep, i) => {
                          const allTasksUnderPipeline =
                            extractAndFlattenStepDetails(eachItem?.stepdetails);
                          // console.log(
                          //   allTasksUnderPipeline,
                          //   "flattenedStepDetails"
                          // );
                          return (
                            <div
                              className={classNames.kanbanItem}
                              key={eachStep?.name + i}
                            >
                              <div className={classNames.title}>
                                <span>{eachStep?.name}</span>
                                <div className={classNames.actionBtnsContainer}>
                                  {eachStep?._id === actionBtn ? (
                                    <IoMdClose
                                      onClick={() => {
                                        setActionBtn("");
                                      }}
                                    />
                                  ) : (
                                    <ActionBtnIcon
                                      onClick={() => {
                                        setActionBtn(eachStep?._id);
                                      }}
                                    />
                                  )}
                                  {eachStep?._id === actionBtn && (
                                    <div
                                      className={classNames.jobDropdown}
                                      ref={eachJobPopupActionBtn}
                                    >
                                      <div className={classNames.title}>
                                        What do you want to do?
                                      </div>
                                      <div className={classNames.allOptions}>
                                        <div>See step profile</div>
                                        <div>Delete step</div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {Array.isArray(eachStep.TaskDetails) &&
                              eachStep.TaskDetails?.length > 0
                                ? eachStep.TaskDetails?.map((eachTask, ii) => {
                                    return (
                                      <div
                                        className={classNames.subItem}
                                        key={eachTask?._id}
                                      >
                                        <div>
                                          <span>{eachTask?.name}</span>
                                          <div
                                            className={
                                              classNames.actionBtnsContainer
                                            }
                                          >
                                            {eachTask?._id === actionBtn ? (
                                              <IoMdClose
                                                onClick={() => {
                                                  setActionBtn("");
                                                }}
                                              />
                                            ) : (
                                              <ActionBtnIcon
                                                onClick={() => {
                                                  setActionBtn(eachTask?._id);
                                                }}
                                              />
                                            )}
                                            {eachTask?._id === actionBtn && (
                                              <div
                                                className={
                                                  classNames.jobDropdown
                                                }
                                                ref={eachJobPopupActionBtn}
                                              >
                                                <div
                                                  className={classNames.title}
                                                >
                                                  What do you want to do?
                                                </div>
                                                <div
                                                  className={
                                                    classNames.allOptions
                                                  }
                                                >
                                                  <div
                                                    onClick={() => {
                                                      setLocalSidebar(
                                                        "TaskProfileDrawer"
                                                      );
                                                      setSelectedDrawerItem(
                                                        eachTask
                                                      );
                                                    }}
                                                  >
                                                    See task profile
                                                  </div>
                                                  <div>Delete task</div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        {Array.isArray(eachTask?.JobDetails) &&
                                          eachTask?.JobDetails?.length > 0 && (
                                            <div
                                              className={
                                                classNames.miniClientBtns
                                              }
                                            >
                                              {eachTask?.JobDetails?.map(
                                                (eachJob, index) => {
                                                  return (
                                                    <div
                                                      className={
                                                        classNames.eachJob
                                                      }
                                                      onClick={() => {
                                                        if (
                                                          eachJobSettings?.jobid !==
                                                          eachJob?.jobid
                                                        ) {
                                                          console.log(
                                                            "This shouln't work"
                                                          );
                                                          setEachJobSettings({
                                                            step: "1",
                                                            ...eachJob,
                                                          });
                                                        }
                                                      }}
                                                    >
                                                      {eachJob?.ClientDetails
                                                        ?.length > 0 &&
                                                      eachJob?.ClientDetails[0]
                                                        ?.name
                                                        ? eachJob?.ClientDetails[0]?.name
                                                            ?.charAt(0)
                                                            ?.toUpperCase()
                                                        : ""}
                                                      {eachJobSettings?.step &&
                                                        eachJobSettings?._id ===
                                                          eachJob?._id && (
                                                          <div
                                                            ref={eachJobPopup}
                                                            className={
                                                              classNames.jobDropdown
                                                            }
                                                          >
                                                            {eachJobSettings?.step ===
                                                            "1" ? (
                                                              <>
                                                                <div
                                                                  className={
                                                                    classNames.title
                                                                  }
                                                                >
                                                                  What do you
                                                                  want to do?
                                                                </div>
                                                                <div
                                                                  className={
                                                                    classNames.allOptions
                                                                  }
                                                                >
                                                                  <div
                                                                    onClick={() => {
                                                                      setLocalSidebar(
                                                                        "eachJobDetails"
                                                                      );
                                                                      setSelectedPipelineDetails(
                                                                        eachJob
                                                                      );
                                                                      setEachJobSettings(
                                                                        ""
                                                                      );
                                                                    }}
                                                                  >
                                                                    See job data
                                                                  </div>
                                                                  <div
                                                                    onClick={() => {
                                                                      console.log(
                                                                        "move job working"
                                                                      );
                                                                      setEachJobSettings(
                                                                        {
                                                                          step: "Move job",
                                                                          ...eachJob,
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    Move job
                                                                  </div>
                                                                  <div>
                                                                    Edit job
                                                                  </div>
                                                                  <div>
                                                                    Delete job
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ) : eachJobSettings?.step ===
                                                              "Move job" ? (
                                                              <>
                                                                <div
                                                                  className={
                                                                    classNames.title
                                                                  }
                                                                >
                                                                  Which task do
                                                                  you want to
                                                                  move it to?
                                                                </div>
                                                                <input
                                                                  className={
                                                                    classNames.searchDiv
                                                                  }
                                                                  type="text"
                                                                  placeholder="Search..."
                                                                  onChange={(
                                                                    event
                                                                  ) => {
                                                                    setMoveJobQuery(
                                                                      event
                                                                        ?.target
                                                                        ?.value
                                                                    );
                                                                  }}
                                                                />
                                                                <div
                                                                  className={
                                                                    classNames.allOptions
                                                                  }
                                                                >
                                                                  {Array.isArray(
                                                                    allTasksUnderPipeline
                                                                  ) &&
                                                                    allTasksUnderPipeline?.length >
                                                                      0 &&
                                                                    allTasksUnderPipeline
                                                                      ?.filter(
                                                                        (
                                                                          eachQuery
                                                                        ) => {
                                                                          return eachQuery?.name
                                                                            ?.toLowerCase()
                                                                            ?.includes(
                                                                              moveJobQuery?.toLowerCase()
                                                                            );
                                                                        }
                                                                      )
                                                                      ?.map(
                                                                        (
                                                                          eachTask,
                                                                          index
                                                                        ) => {
                                                                          return (
                                                                            <div
                                                                              key={
                                                                                eachTask?.name +
                                                                                index
                                                                              }
                                                                              onClick={() => {
                                                                                // setAllTasksUnderPipelineSelected(
                                                                                //   allTasksUnderPipeline
                                                                                // );
                                                                                moveJobToNewTask(
                                                                                  eachJobSettings?.jobid,
                                                                                  {
                                                                                    nexttaskid:
                                                                                      eachTask?.taskid,
                                                                                    currentstatus:
                                                                                      "completed",
                                                                                  },
                                                                                  allTasksUnderPipeline
                                                                                );
                                                                                console.log(
                                                                                  eachTask,
                                                                                  eachJobSettings,
                                                                                  "Moving task",
                                                                                  eachJobSettings?.jobid,
                                                                                  {
                                                                                    nexttaskid:
                                                                                      eachTask?.taskid,
                                                                                    currentstatus:
                                                                                      "completed",
                                                                                  }
                                                                                );
                                                                              }}
                                                                            >
                                                                              {
                                                                                eachTask?.name
                                                                              }
                                                                            </div>
                                                                          );
                                                                        }
                                                                      )}
                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </div>
                                                        )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    );
                                  })
                                : ""}
                              <div className={classNames.subAddBtns}>
                                {/* <div
                                  onClick={() => {
                                    // setState(false);
                                    setLocalSidebar("addnewautomatedtask");
                                  }}
                                >
                                  <span>+</span> Automated Task
                                </div>
                                <div
                                  onClick={() => {
                                    // setState(false);
                                    setLocalSidebar("addnewmanualtasks");
                                  }}
                                >
                                  <span>+</span> Manual Task
                                </div> */}
                                <div
                                  onClick={() => {
                                    // setState(false);
                                    setLocalSidebar("addnewtaskform");
                                  }}
                                >
                                  <span>+</span> Add Task
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              );
            })
          : ""}
      </div>
      {showAddJobPopup && (
        <div className={classNames.jobPopup} ref={popupRef}>
          <div>
            <div className={classNames.lable}>Assigned By</div>
            {/* <div className={classNames.inputCard}>
              <div>{profileName}</div>
              <div>{email}</div>
            </div> */}
            <EachCustomDropdown
              dropdown={allStaffMembers}
              title="Select Person"
              name="assignedby"
              stateValue={newJob}
              setState={setNewJob}
              type="objUserDetails"
              mapVal={{
                name: "name",
              }}
              stateVal={"userid"}
            />
            <div className={classNames.lable} style={{ paddingTop: "12px" }}>
              Assigned To
            </div>
            <EachCustomDropdown
              dropdown={allStaffMembers}
              title="Select Person"
              name="assignedto"
              stateValue={newJob}
              setState={setNewJob}
              type="objUserDetails"
              mapVal={{
                name: "name",
              }}
              stateVal={"userid"}
            />

            {/* <div className={classNames.inputCard} style={{ height: "47px" }}>
              
              <div>
                <input
                  type="text"
                  placeholder="Select person"
                  style={{ width: "100%", border: "none" }}
                  onChange={(event) => {
                    setNewJob((prev) => {
                      return { ...prev, assignedto: event?.target?.value };
                    });
                  }}
                />
              </div>
            </div> */}
            <div className={classNames.lable} style={{ paddingTop: "12px" }}>
              Select client
            </div>

            {/* <div></div> */}
            {/* <div>
                <input
                  type="text"
                  placeholder="Select person"
                  style={{ width: "100%", border: "none" }}
                  onChange={(event) => {
                    setNewJob((prev) => {
                      return { ...prev, client: event?.target?.value };
                    });
                  }}
                />
              </div> */}
            <EachCustomDropdown
              dropdown={allClients}
              title="Select Person"
              name="clientid"
              stateValue={newJob}
              setState={setNewJob}
              type="objUserDetails"
              mapVal={{
                name: "name",
              }}
              stateVal={"userid"}
            />

            <div className={classNames.submitBtn} onClick={createJob}>
              Submit
            </div>
          </div>
          <div>
            <div className={classNames.lable}>Description</div>
            <div
              className={classNames.inputCard}
              style={{ height: "251px", justifyContent: "flex-start" }}
            >
              <div></div>
              <div>
                <textarea
                  style={{
                    width: "100%",
                    height: "210px",
                    border: "none",
                    outline: "none",
                  }}
                  name=""
                  id=""
                  placeholder="Enter any notes"
                  onChange={(event) => {
                    setNewJob((prev) => {
                      return { ...prev, description: event?.target?.value };
                    });
                  }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default KanbanBoard;

//  <DragDropContext onDragEnd={onDragEnd}>
//    <div className="kanban-board">
//      {stages.map((stage) => (
//        <div key={stage.id} className="kanban-stage">
//          <div className="stage-header">
//            <h3>{stage.title}</h3>
//            <button
//              className="add-item-btn"
//              onClick={() => addItem(stage.id, "New Item")}
//            >
//              +
//            </button>
//          </div>
//          <Droppable droppableId={stage.id.toString()}>
//            {(provided) => (
//              <div
//                className="stage-items"
//                {...provided.droppableProps}
//                ref={provided.innerRef}
//              >
//                {stage.items.map((item, index) => (
//                  <Draggable
//                    key={item.id}
//                    draggableId={item.id.toString()}
//                    index={index}
//                  >
//                    {(provided) => (
//                      <div
//                        className="kanban-item"
//                        {...provided.dragHandleProps}
//                        {...provided.draggableProps}
//                        ref={provided.innerRef}
//                        onClick={() => handleItemClick(item)}
//                      >
//                        {editingItem === item ? (
//                          <>
//                            <textarea
//                              placeholder="Enter card value.."
//                              value={
//                                editingText === "New Item" ? "" : editingText
//                              }
//                              onChange={(e) => setEditingText(e.target.value)}
//                            ></textarea>
//                            <div className="edit-buttons">
//                              <button onClick={handleSaveEdit}>Save</button>
//                              <button onClick={handleCancelEdit}>Cancel</button>
//                            </div>
//                          </>
//                        ) : (
//                          <textarea
//                            className="contentTextArea"
//                            placeholder="Enter card value.."
//                            value={editingText}
//                            style={{ pointerEvents: "none" }}
//                          ></textarea>
//                        )}
//                      </div>
//                    )}
//                  </Draggable>
//                ))}
//                {provided.placeholder}
//              </div>
//            )}
//          </Droppable>
//        </div>
//      ))}
//    </div>
//  </DragDropContext>;
