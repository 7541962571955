import React, { useEffect, useRef, useState } from "react";
import classNames from "./templates.module.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import { IoMdArrowDropleft } from "react-icons/io";

const Templates = () => {
  const allTabs = [
    "Proposals & ELs",
    "Jobs",
    "Client-facing job statuses",
    "Tasks",
    "Organizers",
    "Chats",
    "Emails",
    "SMS",
    "Invoices",
    "Recurring Invoices",
    "Signatures",
  ];

  const allSteps = [
    "General",
    "Introduction",
    "Terms",
    "Services & Invoices",
    "Payment",
  ];

  const [selectedTab, setSelectedTab] = useState("Proposals & ELs");
  const [editorOpen, setEditorOpen] = useState({});
  const [selectedStep, setSelectedStep] = useState("General");
  const [editorContent, setEditorContent] = useState(
    `<h3>Hi, [ACCOUNT_NAME]</h3><p><br></p><p> I’m pleased to be writing to present a proposal for providing Accounting &amp; Tax Services for [ACCOUNT_NAME]</p><p><br></p><p>As skilled accountants, we are pleased to introduce Simpson Tax, a distinguished leader in the field of accounting and taxation services. With a commitment to excellence, precision, and unwavering professionalism, we provide a comprehensive range of financial solutions tailored to meet the unique needs of individuals and businesses alike.</p>`
  );

  return (
    <div className={classNames.container}>
      {editorOpen?.isOpen ? (
        <>
          <div className={classNames.titleWrapper}>
            <div
              className={classNames.title}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditorOpen({});
              }}
            >
              <IoMdArrowDropleft />
              Introduction
            </div>
          </div>
          <div
            className={`${classNames.childContainer} ${classNames.editorContainer}`}
          >
            <div className={classNames.header}>
              <div>
                <div className={classNames.stepContainer}>
                  {allSteps?.map((eachStep, index) => {
                    return (
                      <div
                        key={eachStep + index}
                        className={
                          eachStep === selectedStep && classNames.selectedStep
                        }
                        onClick={() => setSelectedStep(eachStep)}
                      >
                        <div>{index + 1}</div>
                        <div>{eachStep}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <div
                  className={classNames.submitBtn}
                  onClick={() => console.log(editorContent, "editorContent")}
                >
                  Next
                </div>
              </div>
            </div>
            <div className={classNames.textEditorContainer}>
              <TextEditor
                editorContent={editorContent}
                setEditorContent={setEditorContent}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classNames.titleWrapper}>
            <div className={classNames.title}>Firm Templates</div>
          </div>
          <div className={classNames.allTabs}>
            {allTabs?.map((eachTab, index) => {
              return (
                <div
                  className={eachTab === selectedTab && classNames.selectedTab}
                  key={eachTab + index}
                  onClick={() => setSelectedTab(eachTab)}
                >
                  {eachTab}
                </div>
              );
            })}
          </div>
          <div className={classNames.childContainer}>
            {/* {selectedTab === "Proposals & ELs" ? ( */}
            <ProposalsAndElf setEditorOpen={setEditorOpen} />
            {/* ) : (
              ""
            )} */}
          </div>
        </>
      )}
    </div>
  );
};

export default Templates;

const ProposalsAndElf = ({ setEditorOpen }) => {
  const [selectedTab, setSelectedTab] = useState("");

  return (
    <div className={classNames.proposalsAndElf}>
      <div className={classNames.btnsContainer}>
        {selectedTab === "library" ? (
          <span
            className={classNames.backBtn}
            onClick={() => {
              setSelectedTab("");
            }}
          >
            <IoMdArrowDropleft />
            Library
          </span>
        ) : (
          <>
            <div className={classNames.selecteBtn}>Create Template</div>
            <div onClick={() => setSelectedTab("library")}>
              Copy from library
            </div>
          </>
        )}
      </div>

      {selectedTab === "library" ? (
        <table className={classNames.allTemplatesTable}>
          <thead>
            <th>Template Name</th>
            <th>Proposal Name</th>
            <th></th>
          </thead>
          <tbody>
            <tr>
              <td>Tax return engagement letter (3) (2)</td>
              <td>Tax return engagement letter</td>
              <td>
                <div className={classNames.btnsContainer}>
                  <div onClick={() => setEditorOpen({ isOpen: true })}>
                    Open
                  </div>
                  <div>Add</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Tax return engagement letter (3)</td>
              <td>Tax return engagement letter</td>
              <td>
                <div className={classNames.btnsContainer}>
                  <div onClick={() => setEditorOpen({ isOpen: true })}>
                    Open
                  </div>
                  <div>Add</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Introduction</td>
              <td>Introduction</td>
              <td>
                <div className={classNames.btnsContainer}>
                  <div onClick={() => setEditorOpen({ isOpen: true })}>
                    Open
                  </div>
                  <div>Add</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <table className={classNames.allTemplatesTable}>
          <thead>
            <th>Template Name</th>
            <th>Proposal Name</th>
            <th>Used In Pipelines</th>
          </thead>
          <tbody>
            <tr>
              <td>Tax return engagement letter (3) (2)</td>
              <td>Tax return engagement letter</td>
              <td></td>
            </tr>
            <tr>
              <td>Tax return engagement letter (3)</td>
              <td>Tax return engagement letter</td>
              <td></td>
            </tr>
            <tr>
              <td>Introduction</td>
              <td>Introduction</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

const TextEditor = ({ editorContent, setEditorContent }) => {
  const handleChange = (content) => {
    setEditorContent(content);
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      ["link", "image", "video"],
      ["clean"],
      [{ align: [] }],
    ],
  };

  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "list",
    "bullet",
    "indent",
    "direction",
    "size",
    "link",
    "image",
    "video",
    "align",
  ];

  return (
    <ReactQuill
      value={editorContent}
      onChange={handleChange}
      modules={modules}
      formats={formats}
      theme="snow"
    />
  );
};
