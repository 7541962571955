import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./app.scss";
import GlobalContextProvider from "./context/Globalcontext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalContexWalletProvider } from "./pages/WalletScan/globalContext";
import ContextWalletProvider from "./pages/WalletScan/globalComponents/Context/Context";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
   
      <GlobalContextProvider>
        <GlobalContexWalletProvider>
          <ContextWalletProvider>
            <BrowserRouter>
              <App />
              <ToastContainer />
            </BrowserRouter>
          </ContextWalletProvider>
        </GlobalContexWalletProvider>
      </GlobalContextProvider>

  </QueryClientProvider>
);
