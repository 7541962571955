import axios from "axios";

export async function createOrganization(obj) {
  let response = await axios.post(
    `https://finacebackend.marketsverse.com/api/createteam`,
    obj
  );

  return response?.data;
}
